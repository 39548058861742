import { useState, ReactElement, FC, useEffect } from 'react'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import TroubleshooterSearch from './TroubleshooterSearch'
import { SearchResults } from 'src/modules/SearchResults'
import { SearchReportResult } from 'src/common/interfaces/SearchResults/SearchReportResult'
import { ITroubleshooterSearchSelection } from 'src/modules/troubleshooterReport/models/ITroubleshooterSearchSelection'
import { useOktaAuth } from '@okta/okta-react'
import { getReports } from 'src/modules/troubleshooterReport/service/TroubleshooterService'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner/'
import { ApiResponse } from 'src/modules/troubleshooterReport/models/ApiResponse'
import { WarehouseEnum } from 'src/common/enums/Enum'
import { useParams } from 'react-router'
import Cookies from 'universal-cookie'

type ClassKey = 'cell' | 'root' | 'search' | 'divStyle' | 'loaderStyle'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    search: {
      margin: theme.spacing(ThemeConfig.spacing.small),
    },
    divStyle: {
      display: 'flex',
      flexDirection: 'column',
    },
    loaderStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
type PropsType = WithStyles<ClassKey>

const getLocationFromURLorCookie = (passedLocation: string): WarehouseEnum => {
  const cookies = new Cookies()
  const cookieVestiging = cookies.get('las-vestiging')
  const storedLocation = passedLocation ?? cookieVestiging
  switch (storedLocation) {
    case 'Aalsmeer':
      return WarehouseEnum.Aalsmeer
    case 'Naaldwijk':
      return WarehouseEnum.Naaldwijk
    case 'Rijnsburg':
      return WarehouseEnum.Rijnsburg
    case 'Eelde':
      return WarehouseEnum.Eelde
    default:
      return WarehouseEnum.Aalsmeer
  }
}

const Report: FC<PropsType> = (props: PropsType): ReactElement => {
  const { t } = useTranslation()
  const [searchResult, setSearchResult] = useState<SearchReportResult>({
    batches: [],
  })
  const [printableVersion, setPrintableVersion] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [noresult, setNoResult] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { passedLocation }: any = useParams()
  const { passedOrderNumber }: any = useParams()
  const { passedLotNum }: any = useParams()
  const [searchSelection, setSearchSelection] =
    useState<ITroubleshooterSearchSelection>({
      location: getLocationFromURLorCookie(passedLocation),
      auctionDeliveryDate: '',
      orderNumber: passedOrderNumber,
      lotNum: passedLotNum,
      lpnSupply: '',
      clock: undefined,
      transaction: undefined,
    })
  const { authState } = useOktaAuth()

  const { classes } = props

  useEffect(() => {
    const refresh = async () => {
      await refreshSearch()
    }
    if (searchSelection.lotNum !== undefined && searchSelection.lotNum !== '') {
      refresh()
      window.history.pushState({}, document.title, '/' + 'troubleshooterreport')
    }
    if (
      searchSelection.orderNumber !== undefined &&
      searchSelection.orderNumber !== ''
    ) {
      refresh()
    }
  }, [])
  useEffect(() => {
    if (printableVersion) {
      window.print()
      setPrintableVersion(false)
    }
  }, [printableVersion])

  const onSearch = async (selected: ITroubleshooterSearchSelection) => {
    setSearchSelection(selected)
    await refreshSearch(selected)
    setPrintableVersion(false)
  }

  const onClear = () => {
    setSearchResult({ batches: [] })
    setPrintableVersion(false)
    setSearchSelection({
      location: WarehouseEnum.Aalsmeer,
      auctionDeliveryDate: '',
      orderNumber: '',
      lotNum: '',
      lpnSupply: '',
      clock: undefined,
      transaction: undefined,
    })
  }

  const onPrint = (requestPrint: boolean) => {
    setPrinterFriendly()
  }

  const setPrinterFriendly = () => {
    setPrintableVersion(true)
  }
  const refreshSearch = async (selected?: ITroubleshooterSearchSelection) => {
    setErrorMessage('')
    setIsLoading(true)
    setNoResult(false)
    setSearchResult({ batches: [] })
    try {
      const searchResultsResponse: ApiResponse<SearchReportResult> =
        await getReports(
          selected ?? searchSelection,
          authState.accessToken?.accessToken
        )
      //console.log(searchResultsResponse)
      if (
        searchResultsResponse.httpStatus >= 200 &&
        searchResultsResponse.httpStatus < 400 &&
        searchResultsResponse.body
      ) {
        const searchResults = searchResultsResponse.body
        //console.log(searchResults)
        setSearchResult(searchResults)
      } else if (searchResultsResponse.httpStatus === 404) {
        setNoResult(true)
      } else {
        setErrorMessage(
          `Er is een fout opgetreden - ${searchResultsResponse.httpStatus}`
        )
      }
    } catch (error: unknown) {
      const { message } = error as Error
    }
    setIsLoading(false)
  }

  const getReportSearchSelectionAsString = () => {
    return `${searchSelection.location ?? ''}_${
      searchSelection.auctionDeliveryDate ?? ''
    }_${searchSelection.clock ?? ''}_${searchSelection.deliveryNote ?? ''}_${
      searchSelection.lotNum ?? ''
    }_${searchSelection.lpnSupply ?? ''}_${searchSelection.orderNumber ?? ''}`
  }

  return (
    <div className={classes.divStyle}>
      <h2>{t('troubleshooterreport')}</h2>
      <TroubleshooterSearch
        onClickSelect={onSearch}
        onClickWissen={onClear}
        onClickPrint={onPrint}
        isPrinting={printableVersion}
        searchResult={searchResult.batches}
        excelOptions={{
          fileName: getReportSearchSelectionAsString(),
          useObjectKeysAsHeader: true,
          autofitColumns: true,
          workSheetName: 'batches',
        }}
      />
      {isLoading ? (
        <div className={classes.loaderStyle}>
          <TailSpin color='#3a4d41' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      {searchResult !== null &&
        searchResult.batches !== undefined &&
        searchResult.batches.length > 0 && (
          <SearchResults
            batches={searchResult.batches}
            printerFriendly={printableVersion}
            Refresh={refreshSearch}
          />
        )}
      {noresult && (
        <div>
          <h2>{t('noResults')}</h2>
        </div>
      )}
      {errorMessage && (
        <div>
          <h2>{errorMessage}</h2>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(Report)
