import { useState, useContext, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ThemeConfig } from 'src/common/config'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import SearchResultsTable from './SearchResultsTable'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { DialogContext } from 'src/modules/context/Context'
import { Ticket } from 'src/common/interfaces/SearchResults/Ticket'
import { Order } from 'src/common/interfaces/SearchResults/Order'
import { OrderPick } from 'src/common/interfaces/SearchResults/OrderPick'
import { CreateOpenTicketClick } from './CreateOpenTicketClick'
import { useOktaAuth } from '@okta/okta-react'
import EditPriorityDialog from '../troubleshooterReport/views/troubleshooter-list/EditPriorityDialog'
import AddCommentToBatchDialog from '../troubleshooterReport/views/troubleshooter-list/AddCommentToBatchDialog'
import { useAccountStore } from 'src/common/providers/AccountStore'

type Props = {
  batches: Batch[]
  printerFriendly: boolean
  Refresh: () => void
}

const useStyles = makeStyles({
  root: {
    flexGrow: ThemeConfig.spacing.xsmall,
  },
  title: {
    color: '#000000de',
    flex: 1,
  },
  container: {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  buttonContainerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: '20px',
  },
  grid: {
    display: 'flex',
    alignItems: 'flex-start',
  },
})

const ConvertTicketId = (ticketId: string): number => parseInt(ticketId, 10)

function SearchResults(props: Props): JSX.Element {
  const { orderId }: any = useParams()
  const { t } = useTranslation()
  const { authState } = useOktaAuth()
  const { isRoleApplicatieBeheer, isRoleCoordinator } = useAccountStore()
  const classes = useStyles()
  const dialogContext = useContext(DialogContext)
  const [addCommentDialogOpen, setAddCommentDialogOpen] = useState(false)
  const [batchComment, setBatchComment] = useState<string>('')
  const [lotNumber, setLotNumber] = useState<string>('')
  const [popupEnabled, setPopupEnabled] = useState(false)
  const [priorityChangeAllowed, setPriorityChangeAllowed] = useState(
    isRoleApplicatieBeheer || isRoleCoordinator
  )
  const [editPriorityTicketId, setEditPriorityTicketId] = useState<number>(0)
  const [priority, setPriority] = useState<number>(0)

  const gotoItemDetails: CreateOpenTicketClick = (
    warehouseId: number,
    orderdata: string,
    order: Order,
    orderPick?: OrderPick,
    ticket?: Ticket,
    batch?: Batch
  ) => {
    const newTicketUrl = new URL(
      `https://${window.location.hostname}/v2/new-ticket`
    )
    const step = ticket?.state !== undefined ? Number(ticket.state) : 0
    newTicketUrl.searchParams.set(
      'ticketNumber',
      ticket?.LasTicketId?.toString() ?? '0'
    )
    if (ticket !== undefined && ticket?.LasTicketId > 0) {
      newTicketUrl.searchParams.set('ticketStatus', ticket.state.toString())
    }
    if (batch) {
      const searchableLotnumber = batch.oliOrderIndicatie
        ? batch.oliOrderBatchReference
        : batch.lotNumber
      if (searchableLotnumber !== '') {
        newTicketUrl.searchParams.set('batchNumber', searchableLotnumber)
      }
    }
    if (order.orderNumber) {
      newTicketUrl.searchParams.set('orderNumber', order.orderNumber)
    }
    if (orderPick?.orderPickId) {
      newTicketUrl.searchParams.set('orderPickNumber', orderPick.orderPickId)
    }
    if (step) {
      newTicketUrl.searchParams.set('step', String(step - 1))
    }
    newTicketUrl.searchParams.set(
      'callback',
      `https://${window.location.hostname}/troubleshooterreport`
    )
    window.open(newTicketUrl, '_blank')
  }

  const showPriorityDialog = (x: Ticket | undefined) => {
    if (priorityChangeAllowed) {
      const selectedLasTicketId = x?.LasTicketId != null ? x.LasTicketId : 0
      const selectedPriority = x?.priority != null ? x.priority : 0
      setPriority(selectedPriority)
      setEditPriorityTicketId(selectedLasTicketId)
      setPopupEnabled(true)
    }
  }

  const handleEditPriorityClose = () => {
    setEditPriorityTicketId(0)
    setPriority(0)
    setPopupEnabled(false)
    props.Refresh()
  }

  const openAddCommentToBatchDialog = (lotNumber: string, comment: string) => {
    setLotNumber(lotNumber)
    setBatchComment(comment)
    setAddCommentDialogOpen(true)
  }

  const handleAddCommentClose = () => {
    setLotNumber('')
    setBatchComment('')
    setAddCommentDialogOpen(false)
    props.Refresh()
  }

  /*
  Effects
  */
  useEffect(() => {
    dialogContext.setDialogOpen(!!orderId)
  }, [orderId])

  return (
    <div className={classes.root}>
      <h2>{t('searchResults')}</h2>
      {props.batches.length > 0 &&
        props.batches.map(ticketbatch => (
          <SearchResultsTable
            key={ticketbatch.lotNumber}
            batch={ticketbatch}
            onClickItem={(warehouseId, orderdata, orderId, orderPick, ticket) =>
              gotoItemDetails(
                warehouseId,
                orderdata,
                orderId,
                orderPick,
                ticket,
                ticketbatch
              )
            }
            printableVersion={props.printerFriendly}
            onClickPriority={x => showPriorityDialog(x)}
            onClickAddComment={(lotNum: string, comment: string) =>
              openAddCommentToBatchDialog(lotNum, comment)
            }
            priorityChangeAllowed={priorityChangeAllowed}
          />
        ))}
      <AddCommentToBatchDialog
        comment={batchComment}
        lotNumber={lotNumber}
        handleAddCommentClose={handleAddCommentClose}
        dialogOpen={addCommentDialogOpen}
      ></AddCommentToBatchDialog>

      <EditPriorityDialog
        priority={priority}
        lasTicketId={editPriorityTicketId}
        handleEditPriorityClose={handleEditPriorityClose}
        dialogOpen={popupEnabled}
      ></EditPriorityDialog>
    </div>
  )
}

export default SearchResults
