import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import { useTranslation } from 'react-i18next'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import { SearchResultItem } from '.'
import { CreateOpenTicketClick } from './CreateOpenTicketClick'
import { ITicketReportViewModel } from '../TicketReport/models/ITicketReportViewModel'
import { useHistory } from 'react-router'
import { CreateAddbatchCommentClick } from './CreateAddbatchCommentClick'
import { Ticket } from 'src/common/interfaces/SearchResults/Ticket'

type TicketView = (ticket?: Ticket) => void

type Props = {
  batch: Batch
  onClickItem: CreateOpenTicketClick
  onClickPriority: TicketView
  onClickAddComment: CreateAddbatchCommentClick
  printableVersion: boolean
  priorityChangeAllowed: boolean
}

const StyledTableCellA = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#3A4D41',
      color: theme.palette.common.white,
      padding: 5,
      lineHeight: 1.5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
  })
)(TableCell)

export const StyledTableCellB = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#0CC988',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      paddingBottom: 0,
      fontSize: 12,
      verticalAlign: 'top',
      border: 0,
    },
  })
)(TableCell)

export const StyledTableCellC = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#3A4D41',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
      horizontalAlign: 'right',
      maxWidth: '8%',
    },
    body: {
      padding: 5,
      paddingBottom: 0,
      fontSize: 12,
      verticalAlign: 'top',
      border: 0,
      horizontalAlign: 'right',
      maxWidth: '8%',
    },
  })
)(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    fsTablePaginate: 'paginate',
  },
  thead: {
    display: 'table-row-group',
  },
  alignRight: {
    textAlign: 'right',
    float: 'right',
  },
})

function SearchResultsTable(props: Props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.thead}>
            <TableRow>
              <StyledTableCellA>
                <Tooltip title='Partij identificatie'>
                  <span>{t('lotNumber')}</span>
                </Tooltip>
                <br />
                <Tooltip title='Ladingdrager identificatie'>
                  <span>{t('loadcarrierId')}.</span>
                </Tooltip>
              </StyledTableCellA>
              <StyledTableCellA>
                {t('supplyLocation')}
                <br /> {t('loadcarrierType')}
              </StyledTableCellA>
              <StyledTableCellA>
                Productcode <br /> {t('layerAmount')}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('productDescription')} <br /> {t('comment')}{' '}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('partyAmount')} / {t('barrelCode')} / {t('amountPerUnit')}
              </StyledTableCellA>
              <StyledTableCellC></StyledTableCellC>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={props.batch.lotNumber}>
              <StyledTableCellA>
                {props.batch.oliOrderIndicatie
                  ? `${props.batch.oliOrderBatchReference} / ${props.batch.lotNumber}`
                  : props.batch.lotNumber}
                {console.log(props.batch.lotNumber)}
                <br /> {props.batch.lpn}
              </StyledTableCellA>
              <StyledTableCellA>
                {props.batch.storageLocation} <br /> {props.batch.lpnType}
              </StyledTableCellA>
              <StyledTableCellA>
                {props.batch.sku} <br /> {props.batch.platesAmount}
              </StyledTableCellA>
              <StyledTableCellA>
                {props.batch.itemDescription} <br />{' '}
                <b>{props.batch.batchComment}</b>
              </StyledTableCellA>
              <StyledTableCellA>
                {props.batch.lotQuantity} / {props.batch.packaging} /&nbsp;
                {props.batch.content} &nbsp;
              </StyledTableCellA>
              <StyledTableCellC>
                <span className='alignRight'>
                  <AnnouncementIcon
                    cursor='pointer'
                    onClick={() =>
                      props.onClickAddComment(
                        props.batch.lotNumber,
                        props.batch.batchComment
                      )
                    }
                  />
                </span>
              </StyledTableCellC>
            </TableRow>
          </TableBody>
        </Table>

        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.thead}>
            <TableRow>
              <StyledTableCellB>&nbsp;</StyledTableCellB>
              <StyledTableCellB>
                Code <br />
                <Tooltip title={t('seekerName') || ''}>
                  <span>{t('seeker')}</span>
                </Tooltip>
                <br /> {t('comments')}
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('operatorName') || ''}>
                  <span>{t('operator')}</span>
                </Tooltip>
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('orderNumber') || ''}>
                  <span>{t('orderNo')}</span>
                </Tooltip>
                <br />
                {t('orderDate')}
                <br />
                {t('orderTime')}
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('clockNumber') || ''}>
                  <span>{t('clockNumberShort')}</span>
                </Tooltip>
                <br />
                <Tooltip title={t('clockTransactionNumber') || ''}>
                  <span>Trn</span>
                </Tooltip>
                <br />
                <Tooltip title={t('amountOfUnitsBought') || ''}>
                  <span>{t('amount')}</span>
                </Tooltip>
              </StyledTableCellB>
              <StyledTableCellB>
                {t('buyersCard')}&nbsp;/&nbsp;
                <Tooltip title={t('customerNumberGlnEan') || ''}>
                  <span>EAN</span>
                </Tooltip>
                &nbsp;/&nbsp;
                <Tooltip title={t('customerNumberRfh') || ''}>
                  <span>CKN</span>
                </Tooltip>
                <br />
                {t('customerName')}
                <br />
                <Tooltip title={t('deliveryAgreementReference') || ''}>
                  <span>AAR</span>
                </Tooltip>
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('staffNumber') || ''}>
                  <span>Picker</span>
                </Tooltip>
                <br />
                <Tooltip title={t('pickDate') || ''}>
                  <span>Pick {t('date')}</span>
                </Tooltip>
                <br />
                <Tooltip title={t('pickTime') || ''}>
                  <span>Pick {t('time')}</span>
                </Tooltip>
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('supplyLocation') || ''}>
                  <span>Pick loc.</span>
                </Tooltip>
                <br />
                <Tooltip title={t('amountOfUnitsPicked') || ''}>
                  <span>{t('amount')}</span>
                </Tooltip>
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('customerLocationTrack') || ''}>
                  <span>{t('track')}</span>
                </Tooltip>
                <br />
                <Tooltip
                  title={t('loadcarrierNumberorLetterIdentification') || ''}
                >
                  <span>LPN</span>
                </Tooltip>
                <br />
                <span>{t('layerAmount')}</span>
              </StyledTableCellB>
              <StyledTableCellB>{t('pickUpTime')}</StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('driversStaffNumber') || ''}>
                  <span>{t('deliveryDriver')}</span>
                </Tooltip>
                <br /> {t('deliveryDate')} <br /> {t('deliveryTime')}
              </StyledTableCellB>
              <StyledTableCellB>
                <Tooltip title={t('customerDeliveryPlace') || ''}>
                  <span>Location</span>
                </Tooltip>
                <br />
                <br />
                {t('lateshipmentdatetime')}
              </StyledTableCellB>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.batch.orderRows.map(o => (
              <>
                {o.tickets == null || o.tickets.length === 0 ? (
                  <SearchResultItem
                    order={o.order}
                    customer={o.customer}
                    orderPick={o.orderpick}
                    delivery={o.delivery}
                    platesAmount={props.batch.platesAmount}
                    onClickItem={props.onClickItem}
                    printableVersion={props.printableVersion}
                    warehouseId={props.batch.warehouseId}
                    orderdata={o.orderdata}
                    batch={props.batch}
                    onClickPriority={props.onClickPriority}
                    priorityChangeAllowed={props.priorityChangeAllowed}
                  />
                ) : (
                  o.tickets.map(ti => (
                    <SearchResultItem
                      ticket={ti}
                      order={o.order}
                      customer={o.customer}
                      orderPick={o.orderpick}
                      delivery={o.delivery}
                      platesAmount={props.batch.platesAmount}
                      onClickItem={props.onClickItem}
                      printableVersion={props.printableVersion}
                      warehouseId={props.batch.warehouseId}
                      orderdata={o.orderdata}
                      batch={props.batch}
                      onClickPriority={props.onClickPriority}
                      priorityChangeAllowed={props.priorityChangeAllowed}
                    />
                  ))
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br />
    </>
  )
}

export default SearchResultsTable
