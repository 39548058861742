import { History } from 'history'
import { FC } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { SecureRoute, Security } from '@okta/okta-react'
import { oktaAuth } from '../utils/auth'
import { HomePage } from 'src/modules/general/views/homepage'
import Report from 'src/modules/troubleshooterReport/views/troubleshooter-list/components/Report'
import ErrorCodeList from 'src/modules/errorCodes/views/ErrorCodeList'
import ErrorCodeDetail from 'src/modules/errorCodes/views/ErrorCodeDetail'
import { ActiviteitList } from 'src/modules/activiteiten/views/activiteit-list'
import { ActiviteitDetail } from 'src/modules/activiteiten/views/activiteit-detail'
import { KlachtCodeList } from 'src/modules/klachtcodes/views/klachtcode-list'
import { KlachtCodeDetail } from 'src/modules/klachtcodes/views/klachtcode-detail'
import { LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js/lib/types'
import { Login } from 'src/modules/general/views/okta/Login'
import { Logout } from 'src/modules/general/views/okta/Logout'
import { Callback } from 'src/modules/general/views/okta/Callback'
import { NoPermission } from 'src/modules/general/views/okta/NoPermission'
import SeekerList from 'src/modules/seekers/views/SeekerList'
import TroubleCauserList from 'src/modules/troubleCauser/views/TroubleCauserList'
import TicketReport from 'src/modules/TicketReport/views/TicketReport'
import TroubleCauserDetail from 'src/modules/troubleCauser/views/TroubleCauserDetail'
import SeekerDetail from 'src/modules/seekers/views/SeekerDetail'

export const restoreOriginalUri =
  (history: History) =>
  (_oktaAuth: OktaAuth, originalUri = '/'): void => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
export const onAuthRequired = (history: History) => (): void => {
  history.push('/login')
}

export const Routes: FC = () => {
  const history = useHistory()

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri(history)}
      onAuthRequired={onAuthRequired(history)}
    >
      <Switch>
        <SecureRoute path='/troubleshooterreport' exact component={Report} />
        <SecureRoute path='/ticketreport' exact component={TicketReport} />
        <SecureRoute path='/errorcodes' exact component={ErrorCodeList} />
        <SecureRoute path='/errorcodes/:id' exact component={ErrorCodeDetail} />
        <SecureRoute path='/activiteiten' exact component={ActiviteitList} />
        <SecureRoute
          path='/activiteiten/:id'
          exact
          component={ActiviteitDetail}
        />
        <SecureRoute path='/klachtcodes' exact component={KlachtCodeList} />
        <SecureRoute
          path='/troublecausers'
          exact
          component={TroubleCauserList}
        />
        <SecureRoute
          path='/troublecausers/:id'
          exact
          component={TroubleCauserDetail}
        />
        <SecureRoute
          path='/klachtcodes/:id'
          exact
          component={KlachtCodeDetail}
        />

        <SecureRoute
          path='/order/:orderId/orderpick/:orderPickId/ticket/:ticketId'
          exact
          component={Report}
        />
        <SecureRoute
          path='/order/:orderId/ticket/:ticketId'
          exact
          component={Report}
        />

        <SecureRoute
          path='/troubleshooterreport/ordernumber/:passedOrderNumber'
          exact
          component={Report}
        />

        <SecureRoute
          path='/troubleshooterreport/:passedLocation/ordernumber/:passedOrderNumber'
          exact
          component={Report}
        />

        <SecureRoute
          path='/ticketreportdetail/order/:orderId/ticket/:ticketId'
          exact
          component={TicketReport}
        />
        <SecureRoute path='/' exact component={HomePage} />
        <SecureRoute path='/seekers' exact component={SeekerList} />

        <SecureRoute path='/seekers/:seekerId' exact component={SeekerDetail} />

        <Route path='/login' component={Login} />
        <Route path='/logout' component={Logout} />
        <Route path='/callback' component={Callback} />
        <Route path='/no-permission' component={NoPermission} />
        {/*
        <Route component={NotFound} />
        */}
      </Switch>
    </Security>
  )
}
