import { Grid } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormMode } from 'src/common/enums'

interface IProps {
  mode: FormMode
}

type PropsType = IProps

export const SeekerDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register } = useFormContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} sm={3} md={3}>
        <TextField
          id='id'
          name='id'
          type='text'
          placeholder=''
          label='Medewerkernummer'
          inputRef={register}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField
          color='primary'
          type='string'
          id='name'
          name='name'
          placeholder='Zoekernaam'
          label='Zoekernaam'
          inputRef={register}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        <TextField
          color='primary'
          type='checkbox'
          id='isExpired'
          name='isExpired'
          placeholder='Vervallen'
          inputRef={register}
          label='Vervallen'
          required={false}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
