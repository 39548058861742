import { Grid } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormMode } from 'src/common/enums'

interface IProps {
  mode: FormMode
}
type PropsType = IProps

export const KlachtCodeDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { mode } = props
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register } = useFormContext()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            onChange={event =>
              isNaN(Number(event.target.value))
                ? (event.target.value = '')
                : event.target.value
            }
            id='klachtCode1'
            name='klachtCode1'
            type='tel'
            inputProps={{ maxLength: 3 }}
            placeholder='Klachtcode'
            inputRef={register}
            label='Klachtcode'
            required={true}
            disabled={mode !== FormMode.add}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            color='primary'
            type='string'
            id='klachtOmschrijving'
            name='klachtOmschrijving'
            inputProps={{ maxLength: 128 }}
            inputRef={register}
            placeholder='Omschrijving'
            label='Klachtomschrijving'
            required={true}
            disabled={mode === FormMode.view}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            onChange={event =>
              isNaN(Number(event.target.value)) ||
              Number(event.target.value) === 0
                ? (event.target.value = '')
                : event.target.value
            }
            color='primary'
            type='tel'
            id='prioriteit'
            name='prioriteit'
            inputProps={{ maxLength: 2 }}
            maxValue={99}
            minValue={1}
            inputRef={register}
            placeholder='Prioriteit'
            label='Prioriteit'
            required={true}
            disabled={mode === FormMode.view}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}
