import * as request from 'superagent'
import { Config } from 'src/common/config'
import { ISolutionType } from '../models/ISolutionType'

const backendUrl = Config.api.host
const solutionTypeRoute = 'SolutionType'

export const getAllSolutionTypes = async (
  accesToken: string
): Promise<ISolutionType[]> => {
  try {
    const response = await request
      .get(`${backendUrl}/${solutionTypeRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}
