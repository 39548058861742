export class SearchDeliveryNoteHelper {
  private constructor() {
    //Do Nothing
  }

  public static Create(): SearchDeliveryNoteHelper {
    return new SearchDeliveryNoteHelper()
  }

  public getDeliveryNoteWithPrefixAndChecksum(
    barcodeNumber: string,
    prefix = -1
  ): string {
    let barcodeNumberArray = Array.from(barcodeNumber).map(Number)
    if (prefix >= 0) {
      barcodeNumberArray = this.prefixNumber(barcodeNumberArray, prefix)
    }
    return this.getBarcodeWithChecksumForBarcode(barcodeNumberArray)
  }

  public prefixNumber(barcodeNumberArray: number[], prefix = 2): number[] {
    barcodeNumberArray.unshift(prefix)
    return barcodeNumberArray
  }

  private getBarcodeWithChecksumForBarcode(
    barcodeNumberArray: number[]
  ): string {
    const barcodeArrayResult = Array.from(barcodeNumberArray)
    const checksum: number = this.calculateChecksum(barcodeNumberArray)
    barcodeArrayResult.push(checksum)
    return barcodeArrayResult.join('')
  }

  /**
   * Zie https://www.gs1.org/services/how-calculate-check-digit-manually
   * @param barcodeNumberArray barcodenummer die een checksum moet krijgen
   * @returns de checksum
   */
  private calculateChecksum(barcodeNumberArray: number[]): number {
    if (barcodeNumberArray.length <= 0) {
      return 0
    }
    const reversedArray: number[] = barcodeNumberArray.reverse()
    const arrayLength = reversedArray.length
    let checksum = 0
    for (let index = 0; index < arrayLength; index++) {
      const element = reversedArray[index]
      if (index % 2 === 0) {
        checksum += element * 3
      } else {
        checksum += element
      }
    }
    checksum = checksum % 10
    return checksum === 0 ? 0 : 10 - checksum
  }
}
