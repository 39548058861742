import * as request from 'superagent'
import { Config } from 'src/common/config'
import { IErrorCauseReport } from '../models/IErrorCauseReport'
import { IErrorObserverReport } from '../models/IErrorObserverReport'

const backendUrl = Config.api.host
const errorCausesRoute = 'FoutVeroorzakerActiviteit'
const errorObserverRoute = 'FoutWaarnemer'

export const getErrorCauses = async (
  accesToken: string
): Promise<IErrorCauseReport[]> => {
  try {
    const response = await request
      .get(`${backendUrl}/${errorCausesRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const getErrorObservers = async (
  accesToken: string
): Promise<IErrorObserverReport[]> => {
  try {
    const response = await request
      .get(`${backendUrl}/${errorObserverRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}
