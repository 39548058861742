import { FC, ReactElement } from 'react'
import { Theme, TableRow } from '@mui/material'
import { Button } from '@rfh/ui'
import { IKlachtCode } from '../../../models/IKlachtCode'
import createStyles from '@mui/styles/createStyles'
import withStyles, { WithStyles } from '@mui/styles/withStyles'

interface IProps {
  klachtCode: IKlachtCode
  classType: string
  removeItem: (id: number) => void
  gotoKlachtCode: (id: number) => void
}

type ClassKey = 'root' | 'cell' | 'button'
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cell: {
      textAlign: 'left',
      padding: '2px',
    },
    button: {
      float: 'right',
      padding: '4px',
      marginRight: '10px',
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const KlachtCodeListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const klachtCode = props.klachtCode
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={klachtCode.klachtCode1}>
      <td className={classType}>{klachtCode.klachtCode1}</td>
      <td className={classType}>{klachtCode.klachtOmschrijving}</td>
      <td className={classType}>{klachtCode.prioriteit}</td>
      <td className={classes.cell}>
        <Button
          variant='contained'
          type='submit'
          onClick={(): void => props.gotoKlachtCode(klachtCode.klachtCode1)}
          className={classes.button}
        >
          Detail
        </Button>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(KlachtCodeListItem)
