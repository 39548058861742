import * as request from 'superagent'
import { Config } from 'src/common/config'
import { IUpdateBatchCommentRequest } from '../model/IUpdateBatchCommentRequest'

const backendUrl = Config.api.host
const batchRoute = 'Batch'

export const updateBatchComment = async (
  updateBatchRequest: IUpdateBatchCommentRequest,
  accessToken: string
): Promise<void> => {
  const putUri = `${backendUrl}/${batchRoute}`

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(updateBatchRequest)
    return response.body
  } catch (error: any) {
    return error.message
  }
}
