import * as request from 'superagent'
import {
  addLocalItem,
  addOfflineEvent,
  getLocalItem,
  removeLocalItem,
  setLocalItem,
  updateLocalItem,
} from '../../../common/utils/managers/OfflineManager'
import { Config } from '../../../common/config/Config'
import { ITicket } from '../models/ITicket'

const backendUrl = Config.api.host //http://localhost:4000'
const TicketRoute = 'Ticket'
const localStorageKey = 'ticket'

const isOnline = () => true

export const getTicketDetail = async (
  id: number,
  accessToken: string
): Promise<ITicket> => {
  if (!isOnline()) {
    console.log('is not online ticket')
    return getLocalItem<ITicket>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${TicketRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    setLocalItem<ITicket[]>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const addTicket = async (
  item: ITicket,
  accessToken: string
): Promise<ITicket> => {
  if (!isOnline()) {
    addOfflineEvent('addTicket', item)
    return addLocalItem<ITicket>(localStorageKey, item as ITicket)
  }

  const postUri = `${backendUrl}/${TicketRoute}`
  console.log(item)
  try {
    const response = await request
      .post(postUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    addLocalItem<ITicket>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateTicketPriority = async (
  id: number,
  priority: number,
  accessToken: string
): Promise<void> => {
  try {
    await getTicketDetail(id, accessToken).then(result => {
      const submdate = new Date(String(result.submitteddatetime))
      result.submitteddatetime = submdate
      setAndUpdateTicketDetail(result, priority, accessToken)
    })
  } catch (error) {
    console.log(error)
  }
  return
}

const setAndUpdateTicketDetail = async (
  ticket: ITicket,
  priority: number,
  accessToken: string
): Promise<boolean> => {
  try {
    if (priority > 0) {
      ticket.priority = priority
      const res = await updateTicket(ticket, accessToken)
      return true
    }
  } catch (error) {
    console.log(error)
  }
  return false
}

export const updateTicket = async (
  item: ITicket,
  accessToken: string
): Promise<ITicket> => {
  if (!isOnline()) {
    addOfflineEvent('updateTicket', item)
    return updateLocalItem<ITicket>(
      localStorageKey,
      item as ITicket,
      item.LasTicketId
    )
  }

  const putUri = `${backendUrl}/${TicketRoute}`
  console.log(item)
  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    updateLocalItem<ITicket>(localStorageKey, response.body, item.LasTicketId)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const removeTicket = async (
  id: number,
  accessToken: string
): Promise<ITicket[]> => {
  if (!isOnline()) {
    addOfflineEvent('removeTicket', id)
    return removeLocalItem<ITicket[]>(localStorageKey, id) as ITicket[]
  }

  try {
    const response = await request
      .del(`${backendUrl}/${TicketRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    removeLocalItem<ITicket[]>(localStorageKey, id)
    return response.body
  } catch (error: any) {
    return error.message
  }
}
