import { FlowerIcon, FulfillmentIcon } from '@rfh/ui/shared/floriday-icons'

import {
  Card,
  CardActionArea,
  CardHeader,
  Container,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ThemeConfig } from 'src/common/config'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { useOktaAuthEffect } from 'src/common/hooks/useOktaAuthEffect'
import { observer } from 'mobx-react'

type ClassKey = 'content' | 'root'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    content: {
      padding: theme.spacing(ThemeConfig.spacing.small),
      minHeight: 'calc(100vh - 64px - 8px)', // 64px: appbar, 8px: rainbow
      [theme.breakpoints.only('xs')]: {
        minHeight: 'calc(100vh - 64px)',
      },
    },
  })

const HomePage: FC<PropsType> = observer((props: PropsType) => {
  useOktaAuthEffect()
  const { classes } = props
  const { t } = useTranslation()
  const history = useHistory()
  const { isAuthenticated } = useAccountStore()

  const onClickTroubleShooterHandler = (): void => {
    //TODO: op homepage geen onderscheid tussen inzien/onderhouden, dit moet geregeld worden binnen troubleshooter component zelf
    if (isAuthenticated) {
      history.push('/troubleshooterreport')
    } else {
      history.push('/no-permission')
    }
  }

  const onClickTicketReportHandler = (): void => {
    if (isAuthenticated) {
      history.push('ticketreport')
    } else {
      history.push('/no-permission')
    }
  }

  const onClickErrorCodesHandler = (): void => {
    if (isAuthenticated) {
      history.push('/errorCodes')
    } else {
      history.push('/no-permission')
    }
  }

  //onClickActiviteitenHandler
  const onClickActiviteitenHandler = (): void => {
    if (isAuthenticated) {
      history.push('/activiteiten')
    } else {
      history.push('/no-permission')
    }
  }

  const onClickKlachtCodesHandler = (): void => {
    if (isAuthenticated) {
      history.push('/klachtcodes')
    } else {
      history.push('/no-permission')
    }
  }

  const onClickSeekersHandler = (): void => {
    if (isAuthenticated) {
      history.push('/seekers')
    } else {
      history.push('/no-permission')
    }
  }

  const onClickTroubleCauserHandler = (): void => {
    if (isAuthenticated) {
      history.push('/troublecausers')
    } else {
      history.push('/no-permission')
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='lg' className={classes.content}>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h1'>
              {t('applicationName')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardActionArea onClick={onClickTroubleShooterHandler}>
                <CardHeader avatar={<FulfillmentIcon />} title={t('tsr')} />
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <a
                  href='v2/ticketreport'
                  target='_self'
                  rel='noopener noreferrer'
                >
                  <CardHeader
                    avatar={<FulfillmentIcon />}
                    title={t('ticketreport')}
                  />
                </a>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <a
                  href='v2/customer-overview'
                  target='_self'
                  rel='noopener noreferrer'
                >
                  <CardHeader
                    avatar={<FulfillmentIcon />}
                    title={t('customerOverview')}
                  />
                </a>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardActionArea onClick={onClickActiviteitenHandler}>
                <CardHeader
                  avatar={<FlowerIcon />}
                  title='Activiteiten onderhouden'
                />
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea onClick={onClickKlachtCodesHandler}>
                <CardHeader
                  avatar={<FlowerIcon />}
                  title='Klachtcodes onderhouden'
                />
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea onClick={onClickErrorCodesHandler}>
                <CardHeader
                  avatar={<FlowerIcon />}
                  title='foutcodes onderhouden'
                />
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea onClick={onClickSeekersHandler}>
                <CardHeader
                  avatar={<FulfillmentIcon />}
                  title='Zoekers onderhouden'
                />
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea onClick={onClickTroubleCauserHandler}>
                <CardHeader
                  avatar={<FulfillmentIcon />}
                  title='Foutveroorzakers onderhouden'
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
})

export default withStyles(styles)(HomePage)
