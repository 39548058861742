import { FC, ReactElement } from 'react'
import { Theme, TableRow } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@rfh/ui'
import { ITroubleCauserListItemModel } from '../models/ITroubleCauserListItemModel'

interface IProps {
  troubleCauserListItem: ITroubleCauserListItemModel
  classType: string
  goToTroubleCauser: (id: number) => void
}

type ClassKey = 'root' | 'cell' | 'button'
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cell: {
      textAlign: 'left',
      padding: '2px',
    },
    button: {
      float: 'right',
      padding: '4px',
      marginRight: '10px',
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const TroubleCauserListItem: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const troubleCauser = props.troubleCauserListItem
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={troubleCauser.id}>
      <td className={classType}>{troubleCauser.errorcodecode}</td>
      <td className={classType}>{troubleCauser.errorcodedescription}</td>
      <td className={classType}>{troubleCauser.activityobserverdescription}</td>
      <td className={classType}>{troubleCauser.activitycauserdescription}</td>
      <td className={classType}>
        {troubleCauser.activitycontrollerdescription}
      </td>
      <td className={classes.cell}>
        <Button
          variant='contained'
          type='submit'
          onClick={(): void => props.goToTroubleCauser(troubleCauser.id)}
          className={classes.button}
        >
          Detail
        </Button>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(TroubleCauserListItem)
