import { useState, ReactElement, FC, useEffect } from 'react'
import { Table, Theme, TableRow } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { useOktaAuth } from '@okta/okta-react'
import { Button } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField } from '@rfh/ui'
import { ISeeker } from '../models/ISeeker'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { getSeekers } from '../service/seekerService'
import SeekerListItem from '../components/SeekerListItem'

type ClassKey = 'cell' | 'root' | 'table' | 'button'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
  })
type PropsType = WithStyles<ClassKey>

const SeekerList: FC<PropsType> = (props: PropsType): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const { authState } = useOktaAuth()
  const [selectedName, setSelectedName] = useState<string>()
  const [seekers, setSeekers] = useState<ISeeker[]>([])

  const { classes } = props

  useEffect(() => {
    if (authState.isAuthenticated) {
      console.log(authState)
    }
    fetchData()
  }, [authState])

  const fetchData = async (): Promise<void> => {
    try {
      console.log('zoek data')
      const searchName: string = selectedName !== undefined ? selectedName : ' '
      console.log('name' + searchName)

      const result = await getSeekers(
        authState.accessToken?.accessToken,
        searchName,
        true
      )
      console.log('result:')
      console.log(result)
      console.log({ result })
      setSeekers(result)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNameChange = (name: string) => {
    setSelectedName(name)
  }

  const zoekIfEnter = (e: string) => {
    if (e === 'Enter') {
      fetchData()
    }
  }

  const goToSeeker = (seekerId: number): void => {
    history.push(`${location.pathname}/${seekerId}`)
  }

  return (
    <div className={classes.root}>
      <h2>Onderhouden Zoekers</h2>
      <Table className={classes.table}>
        <thead></thead>
        <tbody>
          <tr>
            <td className={classes.cell}>Zoekernaam</td>
          </tr>
          <tr onKeyUp={e => zoekIfEnter(e.key)}>
            <td className={classes.cell}>
              <TextField
                type='text'
                inputProps={{ maxLength: 128 }}
                value={selectedName}
                onChange={e => handleNameChange(e.target.value)}
              />
            </td>
            <td className={classes.cell}>
              <Button
                variant='contained'
                type='submit'
                onClick={fetchData}
                className={classes.button}
              >
                Zoeken
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.cell}>Medewerkernummer</th>
            <th className={classes.cell}>Zoekernaam</th>
            <th className={classes.cell}>Vervallen</th>
          </tr>
        </thead>
        <tbody>
          {seekers !== null &&
            seekers.length &&
            Array.isArray(seekers) &&
            seekers?.map((seeker: ISeeker, index: number) => (
              <SeekerListItem
                key={index}
                seeker={seeker}
                classType={classes.cell}
                goToSeeker={goToSeeker}
              ></SeekerListItem>
            ))}
          <TableRow>
            <Button
              variant='contained'
              className='btn btn-primary'
              onClick={() => goToSeeker(0)}
            >
              Toevoegen
            </Button>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

export default withStyles(styles)(SeekerList)
