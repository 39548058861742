import { Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormMode } from 'src/common/enums'
import { ErrorMeaningEnum } from 'src/common/enums/Enum'
import { Errormeanings } from './../../../common/enums/Enum'

interface IErrorCodeDetailFieldsProps {
  mode: FormMode
}
type PropsType = IErrorCodeDetailFieldsProps

export const ErrorCodeDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { mode } = props
  const { control } = useFormContext()
  const [selectedFoutBetekenis, setSelectedFoutBetekenis] =
    useState<ErrorMeaningEnum>()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            onChange={event =>
              isNaN(Number(event.target.value))
                ? (event.target.value = '')
                : event.target.value
            }
            inputProps={{ maxLength: 3 }}
            type='string'
            id='foutCode1'
            name='foutCode1'
            placeholder='Code'
            inputRef={control.register}
            label='Foutcode'
            required={mode === FormMode.add}
            disabled={mode !== FormMode.add}
            fullWidth
          />
        </Grid>
        <Grid item xs={5} sm={5} md={5}>
          <TextField
            inputProps={{ maxLength: 128 }}
            color='primary'
            type='string'
            id='foutOmschrijving'
            name='foutOmschrijving'
            inputRef={control.register}
            placeholder='Omschrijving'
            label='Foutomschrijving'
            disabled={mode === FormMode.view}
            required={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <InputLabel required sx={{ fontWeight: 'bold', minWidth: '225px' }}>
            <span style={{ fontWeight: 'bold' }}>Betekenis</span>
          </InputLabel>
          <Controller
            as={
              <Select
                name='foutBetekenis'
                value={selectedFoutBetekenis}
                displayEmpty
                sx={{
                  border: 1,
                  borderColor: '#cccccc',
                  minWidth: '225px',
                }}
              >
                <MenuItem value='' sx={{ textTransform: 'none' }} disabled>
                  Maak een keuze
                </MenuItem>
                {Errormeanings.map((key, index) => (
                  <MenuItem
                    key={key + '+' + index}
                    value={key}
                    sx={{ textTransform: 'none' }}
                  >
                    {ErrorMeaningEnum[key]}
                  </MenuItem>
                ))}
              </Select>
            }
            id='foutBetekenis'
            name='foutBetekenis'
            control={control}
            defaultValue=''
            variant='filled'
            required
          ></Controller>
        </Grid>
      </Grid>
    </>
  )
}
