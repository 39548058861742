import { Box, colors, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container } from '@rfh/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
// import { KlachtCodeDetailFields } from 'src/common/components/KlachtCodeDetailFields'
import { FormMode } from 'src/common/enums'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IActiviteit } from '../../models/IActiviteit'
import { Backdrop } from '@mui/material'
import {
  addActiviteit,
  getActiviteitById,
  removeActiviteit,
  updateActiviteit,
} from '../../service/activiteitService'
import { ThemeConfig } from 'src/common/config'
import { ActiviteitDetailFields } from '../activiteit-list/components/ActiviteitDetailFields'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import {
  ActivityOrganisationIdentifierEnum,
  ActivityPerformerEnum,
} from 'src/common/enums/Enum'
import { TailSpin } from 'react-loader-spinner'

interface RouteParams {
  id: string
}

type ClassKey = 'root' | 'autocomplete' | 'error'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: theme.spacing(ThemeConfig.spacing.xsmall),
      marginTop: theme.spacing(ThemeConfig.spacing.small),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    autocomplete: {
      background: colors.common.white,
      border: 'none',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
    },
    error: {
      color: 'red',
    },
  })

const ActiviteitDetail: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const params = useParams<RouteParams>()
    const confirm = useConfirmation()
    const { t } = useTranslation<string>()
    const { id } = params
    const { authState } = useOktaAuth()
    const [detailMode, setMode] = useState(FormMode.edit)
    const [errorMessage, setErrorMessage] = useState('')
    const [activityCode, setActivityCode] = useState<string>('')
    const [activityDescription, setActivityDescription] = useState<string>('')
    const [activityOrganisationIdentifier, setActivityOrganisationIdentifier] =
      useState<ActivityOrganisationIdentifierEnum | null>(null)
    const [activityPerformer, setActivityPerformer] =
      useState<ActivityPerformerEnum | null>(null)
    const [isLoading, setIsloading] = useState<boolean>(false)

    useEffect(() => {
      async function setActivityDetail() {
        setIsloading(true)
        console.log('useEffect detail')
        console.log('detail fetch ' + id)
        if (!isRoleApplicatieBeheer) {
          setMode(FormMode.view)
        } else if (id === '0') {
          setMode(FormMode.add)
        }
        if (Number.parseInt(id, 10) > 0) {
          await fetchData(Number.parseInt(id, 10))
        }
        setIsloading(false)
      }
      setActivityDetail()
    }, [authState])

    const fetchData = async (ActiviteitId: number): Promise<void> => {
      try {
        console.log('fetchdata :' + ActiviteitId)
        const result = await getActiviteitById(
          authState.accessToken?.accessToken,
          ActiviteitId
        )
        setSelectedActivityState(result)
        setIsloading(false)
      } catch (error) {
        console.log(error)
      }
    }

    const setSelectedActivityState = (activity: IActiviteit): void => {
      setActivityCode(activity.code)
      setActivityDescription(activity.omschrijving)
      setActivityOrganisationIdentifier(activity.organisatiekenmerk)
      setActivityPerformer(activity.uitvoerder)
    }

    const { classes } = props

    const removeItem = async (): Promise<void> => {
      const removeData = async (id: number): Promise<void> => {
        try {
          console.log(id)
          await removeActiviteit(id, authState.accessToken?.accessToken).then(
            value => {
              console.log(value) // Success!/Error. NB deze werkwijze leidt tot verschillen tussen browsers (FF vs chrome)
              if (value === null) {
                history.goBack()
              } else {
                setErrorMessage('Verwijderen is mislukt: ' + value)
              }
            },
            reason => {
              console.error(reason) // Error! Dit lijkt niets te doen?
            }
          )
        } catch (error) {
          console.log(error)
        }
      }

      if (id) {
        try {
          await confirm({
            variant: 'danger',
            catchOnCancel: true,
            title: 'Weet u zeker dat u dit wilt verwijderen?',
            description: 'Deze actie kan niet ongedaan gemaakt worden!',
            okbuttontext: 'Ok',
            cancelbuttontext: 'Annuleer',
          })
        } catch (error) {
          if (error) {
            console.log(error)
          }
          return
        }
        await removeData(parseInt(id, 10))
      }
    }

    const updateData = async (activiteit: IActiviteit): Promise<void> => {
      try {
        const value = await updateActiviteit(
          activiteit,
          authState.accessToken?.accessToken
        )
        console.log(value)
        if (value === null) {
          history.goBack()
        } else {
          setErrorMessage('Update is ongeldig : ' + value)
        }
      } catch (error) {
        setErrorMessage('update ongeldig')
        console.log(error)
      }
    }

    const addData = async (activiteit: IActiviteit) => {
      try {
        const value = await addActiviteit(
          activiteit,
          authState.accessToken?.accessToken
        )
        console.log(value)
        if (value === null) {
          history.goBack()
        } else {
          if (value.code !== undefined) {
            history.goBack()
          } else {
            setErrorMessage('Insert is ongeldig: ' + value)
          }
        }
      } catch (error) {
        setErrorMessage('insert ongeldig')
        console.log(error)
      }
    }

    const handleSave = (): void => {
      const activity: IActiviteit = {
        id: Number.parseInt(id, 10),
        code: activityCode,
        omschrijving: activityDescription,
        organisatiekenmerk: activityOrganisationIdentifier ?? 0,
        uitvoerder: activityPerformer ?? 0,
      }
      console.log('saving item: ' + JSON.stringify(activity))
      if (
        activity.code === '' ||
        activity.omschrijving === '' ||
        activity.organisatiekenmerk === 0 ||
        activity.uitvoerder === 0
      ) {
        setErrorMessage(
          'Activiteit code, omschrijving, en organisatiekenmerk en uitvoerder moeten ingevuld zijn'
        )
        return
      }
      if (id === '0') {
        addData(activity)
      } else {
        updateData(activity)
      }
    }

    const handleActivityCodeChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setActivityCode(e.target.value)
    }

    const handleActivityDescriptionChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setActivityDescription(e.target.value)
    }

    const handleActivityOrganisationIdentifierChange = (
      organisationIdentifier: ActivityOrganisationIdentifierEnum | null
    ): void => {
      if (organisationIdentifier) {
        setActivityOrganisationIdentifier(organisationIdentifier)
      }
    }

    const handleActivityPerformerChange = (
      activityPerformer: ActivityPerformerEnum | null
    ): void => {
      if (activityPerformer) {
        setActivityPerformer(activityPerformer)
      }
    }

    return (
      <Container>
        <Typography variant='h2' gutterBottom>
          Activiteit Details
        </Typography>
        <div className={classes.error}> {errorMessage}</div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <TailSpin color='#3a4d41' height={100} width={100} />
        </Backdrop>
        <ActiviteitDetailFields
          mode={detailMode}
          classes={classes}
          activityCode={activityCode}
          activityDescription={activityDescription}
          activityOrganisationIdentifier={activityOrganisationIdentifier}
          activityPerformer={activityPerformer}
          handleActivityCodeChange={handleActivityCodeChange}
          handleActivityDescriptionChange={handleActivityDescriptionChange}
          handleActivityOrganisationIdentifierChange={
            handleActivityOrganisationIdentifierChange
          }
          handleActivityPerformerChange={handleActivityPerformerChange}
        />
        <Box mt={3}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            xs={3}
            spacing={1}
            className={classes.root}
          >
            <Grid item xs={12}>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                disabled={detailMode === FormMode.view}
                onClick={handleSave}
              >
                Opslaan
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                disabled={detailMode === FormMode.view}
                fullWidth
                onClick={removeItem}
              >
                Verwijder
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
)

export default withStyles(styles)(ActiviteitDetail)
