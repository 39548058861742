import { FC, ReactElement } from 'react'
import { Theme, TableRow, Button } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { IActiviteit } from '../../../models/IActiviteit'
import {
  ActivityOrganisationIdentifierEnum,
  ActivityPerformerEnum,
} from 'src/common/enums/Enum'

interface IProps {
  activiteit: IActiviteit
  allActivities: IActiviteit[]
  classType: string
  removeItem: (id: string) => void
  gotoActiviteit: (id: number) => void
}

type ClassKey = 'root' | 'abrcell' | 'cell' | 'button'
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    abrcell: {
      background: 'linear-gradient(90deg, #ff7200 25%, #ecebeb 85%)',
      border: 0,
      borderRadius: 9,
      textAlign: 'left',
      paddingLeft: 10,
      '&:hover': {
        background: 'linear-gradient(90deg, #0cc988 25%, #ecebeb 85%)',
      },
    },
    cell: {
      textAlign: 'left',
      padding: '2px',
    },
    button: {
      float: 'right',
      padding: '4px',
      marginRight: '10px',
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const ActiviteitListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const activiteit = props.activiteit
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={activiteit.code}>
      <td className={classType}>{activiteit.code}</td>
      <td className={classType}>{activiteit.omschrijving}</td>
      <td className={classType}>
        {ActivityOrganisationIdentifierEnum[activiteit.organisatiekenmerk]}
      </td>
      <td className={classType}>
        {ActivityPerformerEnum[activiteit.uitvoerder]}
      </td>
      <td className={classes.cell}>
        <Button
          variant='contained'
          type='submit'
          onClick={(): void => props.gotoActiviteit(activiteit.id)}
          className={classes.button}
        >
          Detail
        </Button>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(ActiviteitListItem)
