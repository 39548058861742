import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { useOktaAuth } from '@okta/okta-react'
import {
  Grid,
  Theme,
  TextField as MuiTextField,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Container } from '@rfh/ui'
import { observer } from 'mobx-react'
import { updateTicketPriority } from 'src/modules/tickets/service/TicketService'
type MyFnType = () => void

type ClassKey =
  | 'bar'
  | 'title'
  | 'container'
  | 'buttonContainer'
  | 'buttonContainerRight'
  | 'buttonleft'
  | 'button'
  | 'grid'
  | 'error'

const styles = (theme: Theme) =>
  createStyles({
    bar: {
      position: 'relative',
      backgroundColor: 'white',
    },
    title: {
      color: '#000000de',
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: {
      width: '100%',
      maxWidth: '1400px',
      margin: '0 auto',
      display: 'flex',
      //marginTop: '-64px',
      alignContent: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    buttonContainer: {
      marginTop: theme.spacing(3),
    },
    buttonContainerRight: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonleft: {
      marginTop: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
    },
    grid: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      alignItems: 'flex-start',
    },
    error: {
      color: 'red',
    },
  })

interface EditPriorityDialogProps {
  handleEditPriorityClose: MyFnType
  priority: number
  lasTicketId: number
  dialogOpen: boolean
}

type PropsType = EditPriorityDialogProps & WithStyles<ClassKey>

const EditPriorityDialog: FC<PropsType> = observer((props: PropsType) => {
  const { authState } = useOktaAuth()
  const { classes } = props
  const { t } = useTranslation()

  const [priority, setPriority] = useState<number>(props.priority)
  const [errorMessage, setErrorMessage] = useState('')

  const handlePriorityChange = event => {
    let value = parseInt(event.target.value, 10)

    if (value > 99) value = 99
    if (value < 1) value = 1

    setPriority(value)
  }

  const handleClose = () => {
    setErrorMessage('')
    setPriority(0)
    props.handleEditPriorityClose()
  }

  const onClickSave = async (): Promise<void> => {
    const resp = await updateTicketPriority(
      props.lasTicketId ?? 0,
      priority ?? 0,
      authState.accessToken?.accessToken
    )
    if (resp == null) {
      setErrorMessage('')
      setPriority(0)
      props.handleEditPriorityClose()
    } else {
      setErrorMessage(JSON.stringify(resp))
    }
  }

  useEffect(() => {
    setPriority(props.priority)
  }, [props.priority])

  return (
    <div>
      <Dialog open={props.dialogOpen} onClose={handleClose}>
        <DialogTitle>{t('priority')}</DialogTitle>
        <DialogContent>
          <Container>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.grid}>
                {errorMessage !== null && errorMessage !== '' && (
                  <p className={classes.error}>{errorMessage}</p>
                )}
                <MuiTextField
                  autoFocus
                  margin='dense'
                  id='name'
                  type='number'
                  variant='standard'
                  inputProps={{ maxLength: 2, min: 1, max: 99 }}
                  value={priority}
                  onChange={handlePriorityChange}
                />
              </Grid>
              <Grid item sm={12} className={classes.buttonContainerRight}>
                <RfhButton
                  variant='block--contained'
                  className={classes.button}
                  onClick={onClickSave}
                >
                  {t('save')}
                </RfhButton>
                <RfhButton
                  variant='block--outlined'
                  className={classes.button}
                  onClick={handleClose}
                >
                  {t('cancel')}
                </RfhButton>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  )
})

export default withStyles(styles)(EditPriorityDialog)
