export enum WarehouseEnum {
  Aalsmeer = 1,
  Naaldwijk = 2,
  Rijnsburg = 3,
  Eelde = 7,
}

export const warehouseLocations: WarehouseEnum[] = [
  WarehouseEnum.Aalsmeer,
  WarehouseEnum.Naaldwijk,
  WarehouseEnum.Rijnsburg,
  WarehouseEnum.Eelde,
]

export enum ComplaintStatusEnum {
  Total = 1,
  Pending = 2,
  InProgress = 3,
  PendingAndInProgress = 4,
  Solved = 5,
}

export const complaintStatuses: ComplaintStatusEnum[] = [
  ComplaintStatusEnum.Total,
  ComplaintStatusEnum.Pending,
  ComplaintStatusEnum.InProgress,
  ComplaintStatusEnum.PendingAndInProgress,
  ComplaintStatusEnum.Solved,
]

export enum TicketDetailPagesEnum {
  TroubleshooterReport = 1,
  TicketReport = 2,
}

export enum ActivityOrganisationIdentifierEnum {
  Order = 1,
  NaderTeBepalen = 2,
}

export const ActivityOrganisationIdentifiers: ActivityOrganisationIdentifierEnum[] =
  [
    ActivityOrganisationIdentifierEnum.Order,
    ActivityOrganisationIdentifierEnum.NaderTeBepalen,
  ]

export enum ActivityPerformerEnum {
  Koper = 1,
  Kweker = 2,
  RFH = 3,
  Picker = 4,
  Afleverchauffeur = 5,
}

export const ActivityPerformers: ActivityPerformerEnum[] = [
  ActivityPerformerEnum.Koper,
  ActivityPerformerEnum.Kweker,
  ActivityPerformerEnum.RFH,
  ActivityPerformerEnum.Picker,
  ActivityPerformerEnum.Afleverchauffeur,
]

export enum ErrorMeaningEnum {
  'Niet van toepassing' = 0,
  'Aantal eenheden' = 10,
  'Aantal per eenheid' = 20,
  'Fustcode' = 30,
}

export const Errormeanings: ErrorMeaningEnum[] = [
  ErrorMeaningEnum['Niet van toepassing'],
  ErrorMeaningEnum['Aantal eenheden'],
  ErrorMeaningEnum['Aantal per eenheid'],
  ErrorMeaningEnum.Fustcode,
]
