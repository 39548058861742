import * as request from 'superagent'
import { IKlachtCode } from '../models/IKlachtCode'
import { Config } from '../../../common/config/Config'
import {
  addLocalItem,
  addOfflineEvent,
  getLocalItem,
  removeLocalItem,
  setLocalItem,
  updateLocalItem,
} from '../../../common/utils/managers/OfflineManager'
import { errorMonitor } from 'stream'

const backendUrl = Config.api.host
const klachtCodeRoute = 'KlachtCode'
const localStorageKey = 'klachtcode'
//const accessToken = 'todoTOKEN'

export const getKlachtCodes = async (
  accessToken: string,
  code: string,
  omschrijving: string
): Promise<IKlachtCode[]> => {
  if (!isOnline()) {
    console.log('is not online klachtcode')
    console.log(code + ' ' + omschrijving)
    return getLocalItem<IKlachtCode[]>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${klachtCodeRoute}/${code}/${omschrijving}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    console.log({ response })
    setLocalItem<IKlachtCode[]>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

const isOnline = () => true

export const getKlachtCode = async (
  id: number,
  accessToken: string
): Promise<IKlachtCode> => {
  if (!isOnline()) {
    return getLocalItem<IKlachtCode>(localStorageKey, id)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${klachtCodeRoute}/getDetail/${id}`)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('Content-Type', 'application/json')
    updateLocalItem<IKlachtCode>(localStorageKey, response.body, id)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const removeKlachtCode = async (
  id: number,
  accessToken: string
): Promise<IKlachtCode[]> => {
  if (!isOnline()) {
    addOfflineEvent('removeKlachtCode', id)
    return removeLocalItem<IKlachtCode[]>(localStorageKey, id) as IKlachtCode[]
  }

  try {
    const response = await request
      .del(`${backendUrl}/${klachtCodeRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    removeLocalItem<IKlachtCode[]>(localStorageKey, id)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const addKlachtCode = async (
  item: IKlachtCode,
  accessToken: string
): Promise<IKlachtCode> => {
  //item.createdBy = 'ar'
  // Use this dateFormat for the Dev .net Lambda
  //item.created = '0001-01-01'
  // You can use this for your local Json Server
  // `${new Date().getFullYear().toString()}` +
  // '/' +
  // `${new Date().getMonth().toString()}` +
  // '/' +
  // `${new Date().getDate().toString()}`

  if (!isOnline()) {
    addOfflineEvent('addKlachtCode', item)
    return new Promise((_, reject): void => reject('no internet connection'))
    // return offlineManager.addItemToLocalStorage<IKlachtCode>(localStorageKey, item)
    // TODO: show toaster that there is no Internet connection and promise to update when it is back
    //       idem by delete and update
  }

  try {
    const response = await request
      .post(`${backendUrl}/${klachtCodeRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    addLocalItem<IKlachtCode>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateKlachtCode = async (
  item: Partial<IKlachtCode>,
  accessToken: string
): Promise<IKlachtCode> => {
  if (!isOnline()) {
    addOfflineEvent('updateKlachtCode', item)
    return updateLocalItem<IKlachtCode>(
      localStorageKey,
      item as IKlachtCode,
      item.klachtCode1
    )
  }

  const putUri = `${backendUrl}/${klachtCodeRoute}`

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    updateLocalItem<IKlachtCode>(
      localStorageKey,
      response.body,
      item.klachtCode1
    )
    return response.body
  } catch (error: any) {
    return error.message
  }
}
