import React, { useState, ReactElement, FC, useEffect } from 'react'
import { Table, Theme, TextField as MuiTextField } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { ITroubleshooterSearchSelection } from 'src/modules/troubleshooterReport/models/ITroubleshooterSearchSelection'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'src/common/services/helpers/dateUtilities'
import { useOktaAuth } from '@okta/okta-react'
import { WarehouseSelectionButton } from 'src/common/components/WarehouseSelectionButton'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Button } from '@rfh/ui'
import { TextField } from '@rfh/ui'
import Cookies from 'universal-cookie'
import { WarehouseEnum } from 'src/common/enums/Enum'
import { useParams } from 'react-router'
import ExcelComponent from 'src/common/excel/component/ExcelComponent'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import { ExcelOptions } from 'src/common/excel/ExcelOptions'
import { SearchDeliveryNoteHelper } from 'src/modules/troubleshooterReport/helpers/SearchDeliveryNoteHelper'
import PrintReport from './printing/PrintReport'

interface IProps {
  onClickSelect: (selection: ITroubleshooterSearchSelection) => void
  onClickWissen: () => void
  onClickPrint: (openPrintModal: boolean) => void
  isPrinting: boolean
  searchResult: Batch[]
  excelOptions: Partial<ExcelOptions>
}

type ClassKey =
  | 'cell'
  | 'root'
  | 'search'
  | 'table'
  | 'error'
  | 'button'
  | 'hidden'
  | 'buttonListItem'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    search: {
      margin: theme.spacing(ThemeConfig.spacing.small),
    },
    table: {
      marginTop: '10px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
    error: {
      color: 'red',
    },
    hidden: {
      display: 'none',
    },
    buttonListItem: {
      display: 'inline',
    },
  })
type PropsType = IProps & WithStyles<ClassKey>

const getLocationFromURLorCookie = (passedLocation: string): WarehouseEnum => {
  const cookies = new Cookies()
  const cookieVestiging = cookies.get('las-vestiging')
  let storedLocation

  if (passedLocation) {
    storedLocation = passedLocation
    automaticSearch = true
  } else {
    storedLocation = cookieVestiging
  }
  switch (storedLocation) {
    case 'Aalsmeer':
      return WarehouseEnum.Aalsmeer
    case 'Naaldwijk':
      return WarehouseEnum.Naaldwijk
    case 'Rijnsburg':
      return WarehouseEnum.Rijnsburg
    case 'Eelde':
      return WarehouseEnum.Eelde
    default:
      return WarehouseEnum.Aalsmeer
  }
}

let clearFieldsUsed = false
let automaticSearch = false

const TroubleshooterSearch: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { passedOrderNumber }: any = useParams()
  const { passedLocation }: any = useParams()
  const [selectedLocation, setSelectedLocation] = useState<WarehouseEnum>(
    getLocationFromURLorCookie(passedLocation)
  )
  const [selectedLotNum, setSelectedLotNum] = useState('')
  const [selectedLpnSupply, setSelectedLpnSupply] = useState('')
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState('')
  const [selectedOrderNumber, setSelectedOrderNumber] =
    useState(passedOrderNumber)
  const [selectedClock, setSelectedClock] = useState<number>()
  const [selectedTransaction, setSelectedTransaction] = useState<number>()
  const [selectedAuctionDeliveryDate, setSelectedAuctionDeliveryDate] =
    useState<Date | null>(new Date())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true)
  const { t } = useTranslation()
  const { authState } = useOktaAuth()

  const { classes } = props

  useEffect(() => {
    if (selectedLotNum !== '') {
      clearInputFields(
        selectedClock !== undefined,
        selectedTransaction !== undefined,
        selectedLpnSupply !== '',
        selectedDeliveryNote !== '',
        selectedOrderNumber !== '',
        false
      )
      setSelectDisabled(false)
      setErrorMessage('')
    } else if (
      selectedLotNum === '' &&
      selectedLpnSupply === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedLotNum])

  useEffect(() => {
    if (selectedLpnSupply !== undefined && selectedLpnSupply !== '') {
      clearInputFields(
        selectedClock !== undefined,
        selectedTransaction !== undefined,
        false,
        selectedDeliveryNote !== '',
        selectedOrderNumber !== '',
        selectedLotNum !== ''
      )
      setSelectDisabled(false)
      setErrorMessage('')
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedLpnSupply])

  useEffect(() => {
    if (selectedDeliveryNote !== undefined && selectedDeliveryNote !== '') {
      clearInputFields(
        selectedClock !== undefined,
        selectedTransaction !== undefined,
        selectedLpnSupply !== '',
        false,
        selectedOrderNumber !== '',
        selectedLotNum !== ''
      )
      if (selectedDeliveryNote.length > 5) {
        setSelectDisabled(false)
      } else {
        setSelectDisabled(true)
      }
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedDeliveryNote])

  useEffect(() => {
    if (selectedClock !== undefined) {
      clearInputFields(
        false,
        selectedTransaction !== undefined,
        selectedLpnSupply !== '',
        selectedDeliveryNote !== '',
        selectedOrderNumber !== '',
        selectedLotNum !== ''
      )
      if (
        selectedClock !== undefined &&
        selectedTransaction !== undefined &&
        selectedAuctionDeliveryDate != null
      ) {
        setSelectDisabled(false)
        setErrorMessage('')
      }
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedClock])

  useEffect(() => {
    if (selectedTransaction !== undefined) {
      clearInputFields(
        false,
        false,
        selectedLpnSupply !== '',
        selectedDeliveryNote !== '',
        selectedOrderNumber !== '',
        selectedLotNum !== ''
      )
      if (
        selectedClock !== undefined &&
        selectedTransaction !== undefined &&
        selectedAuctionDeliveryDate != null
      ) {
        setSelectDisabled(false)
        setErrorMessage('')
      }
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedTransaction])

  useEffect(() => {
    if (selectedAuctionDeliveryDate != null) {
      if (
        selectedClock !== undefined &&
        selectedTransaction !== undefined &&
        selectedAuctionDeliveryDate != null
      ) {
        setSelectDisabled(false)
        setErrorMessage('')
      }
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedAuctionDeliveryDate])

  useEffect(() => {
    if (selectedOrderNumber !== undefined && selectedOrderNumber !== '') {
      clearInputFields(
        selectedClock !== undefined,
        selectedTransaction !== undefined,
        selectedLpnSupply !== '',
        selectedDeliveryNote !== '',
        false,
        selectedLotNum !== ''
      )
      if (selectedOrderNumber.length > 1) {
        setSelectDisabled(false)
      } else {
        setSelectDisabled(true)
      }
    } else if (
      selectedLpnSupply === '' &&
      selectedLotNum === '' &&
      selectedDeliveryNote === '' &&
      selectedOrderNumber === '' &&
      (selectedClock == null ||
        selectedTransaction == null ||
        selectedAuctionDeliveryDate == null)
    ) {
      setSelectDisabled(true)
    }
  }, [selectedOrderNumber])

  useEffect(() => {
    setSelectedAuctionDeliveryDate(new Date())
    setErrorMessage('')
    if (clearFieldsUsed === false) {
      setSelectDisabled(true)
    }
    if (automaticSearch) {
      startSearch()
    }
  }, [])

  const clearInputFields = (
    clearClock: boolean,
    clearTransaction: boolean,
    clearLpnSupply: boolean,
    clearDeliveryNote: boolean,
    clearOrderNumber: boolean,
    clearLotNum: boolean
  ) => {
    if (clearClock) {
      setSelectedClock(undefined)
    }
    if (clearTransaction) {
      setSelectedTransaction(undefined)
    }
    if (clearLpnSupply) {
      setSelectedLpnSupply('')
    }
    if (clearDeliveryNote) {
      setSelectedDeliveryNote('')
    }
    if (clearOrderNumber) {
      setSelectedOrderNumber('')
    }
    if (clearLotNum) {
      setSelectedLotNum('')
    }
    clearFieldsUsed = true
  }

  const clearState = () => {
    setSelectedAuctionDeliveryDate(new Date())
    setSelectedLotNum('')
    setSelectedLpnSupply('')
    setSelectedTransaction(undefined)
    setSelectedClock(undefined)
    setErrorMessage('')
    setSelectDisabled(true)
    setSelectedDeliveryNote('')
    setSelectedOrderNumber('')
    props.onClickWissen()
  }

  const onDeliveryNoteBlur = () => {
    if (selectedDeliveryNote.length > 1 && selectedDeliveryNote.length < 6) {
      setErrorMessage(t('deliveryNoteTooShortWarning'))
    } else {
      if (errorMessage !== '') {
        setErrorMessage('')
      }
    }

    if (selectedLocation === WarehouseEnum.Aalsmeer && errorMessage === '') {
      if (selectedDeliveryNote.length !== 11) {
        setErrorMessage(t('deliveryNoteAalsmeerWarning'))
      }
    }
  }

  const onClockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedClock(numberValue)
  }

  const onLotnumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLotNum(e.target.value)
  }

  const onLpnSupplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLpnSupply(e.target.value)
  }

  const onDeliveryNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDeliveryNote(e.target.value)
  }

  const checkDeliveryNoteForAalsmeer = (partDeliveryNote: string): string => {
    const deliveryNotePrefix = 2
    if (selectedLocation !== WarehouseEnum.Aalsmeer) {
      return partDeliveryNote
    }

    if (selectedDeliveryNote.length !== 11) {
      throw new Error(t('deliveryNoteAalsmeerWarning'))
    }
    return SearchDeliveryNoteHelper.Create().getDeliveryNoteWithPrefixAndChecksum(
      partDeliveryNote,
      deliveryNotePrefix
    )
  }

  const onOrderNumberChange = event => {
    const newValue = event.target.value
    const regex = /^[0-9a-zA-Z]+$/
    if (newValue === '' || newValue.match(regex)) {
      setSelectedOrderNumber(newValue)
    }
  }

  const onTransactionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedTransaction(numberValue)
  }

  const onLocationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const cookies = new Cookies()
    const warehouseId = Number(ev.target.value)
    cookies.set('las-vestiging', WarehouseEnum[warehouseId], {
      path: '/',
      maxAge: 300000000,
    })
    setSelectedLocation(warehouseId)
  }

  const searchReports = (ev: React.SyntheticEvent) => {
    ev.preventDefault()
    startSearch()
  }

  const startSearch = () => {
    const searchSelection: ITroubleshooterSearchSelection =
      GetCurrentSelection()
    setErrorMessage('')
    console.log(
      `Start searching with selection: ${JSON.stringify(searchSelection)}`
    )
    if (!isValid(searchSelection)) {
      return
    }
    try {
      if (searchSelection.deliveryNote) {
        searchSelection.deliveryNote = checkDeliveryNoteForAalsmeer(
          searchSelection.deliveryNote
        )
      }
      props.onClickSelect(searchSelection)
    } catch (error: unknown) {
      const { message } = error as Error
      setErrorMessage(message)
    }
  }

  const handlePrint = async () => {
    props.onClickPrint(true)
  }

  const GetCurrentSelection = (): ITroubleshooterSearchSelection => ({
    location: selectedLocation,
    auctionDeliveryDate:
      selectedAuctionDeliveryDate === null
        ? ''
        : formatDate(selectedAuctionDeliveryDate),
    lotNum: selectedLotNum,
    lpnSupply: selectedLpnSupply,
    clock: selectedClock,
    transaction: selectedTransaction,
    deliveryNote: selectedDeliveryNote,
    orderNumber: selectedOrderNumber,
  })

  const isValid = (selection: ITroubleshooterSearchSelection): boolean => {
    if (
      (selection.clock === undefined ||
        selection.transaction === undefined ||
        selection.auctionDeliveryDate === '') &&
      selection.deliveryNote === '' &&
      selection.lotNum === '' &&
      selection.lpnSupply === '' &&
      selection.orderNumber === ''
    ) {
      setErrorMessage(t('troubleshooterSearchSelectionError'))
      return false
    }
    return true
  }

  const getPrintHeader = (): string => {
    const separator = ', '
    const currentSelection: ITroubleshooterSearchSelection =
      GetCurrentSelection()
    let printHeader = WarehouseEnum[currentSelection.location]
    if (
      currentSelection.auctionDeliveryDate !== undefined &&
      currentSelection.auctionDeliveryDate !== ''
    ) {
      printHeader += separator + currentSelection.auctionDeliveryDate
    }
    if (currentSelection.clock !== undefined) {
      printHeader += separator + ' Klok ' + currentSelection.clock
    }
    if (
      currentSelection.deliveryNote !== undefined &&
      currentSelection.deliveryNote !== ''
    ) {
      printHeader += separator + currentSelection.deliveryNote
    }
    if (
      currentSelection.lotNum !== undefined &&
      currentSelection.lotNum !== ''
    ) {
      printHeader += separator + currentSelection.lotNum
    }
    if (
      currentSelection.lpnSupply !== undefined &&
      currentSelection.lpnSupply !== ''
    ) {
      printHeader += separator + currentSelection.lpnSupply
    }
    if (
      currentSelection.orderNumber !== undefined &&
      currentSelection.orderNumber !== ''
    ) {
      printHeader += separator + currentSelection.orderNumber
    }
    if (currentSelection.transaction !== undefined) {
      printHeader += separator + ' Transactie ' + currentSelection.transaction
    }
    return printHeader
  }

  return (
    <div className={classes.root}>
      {errorMessage !== '' && (
        <div className={classes.error}>{errorMessage}</div>
      )}
      <form onSubmit={searchReports}>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.cell}>{t('location')}</td>
              <td className={classes.cell}>
                <WarehouseSelectionButton
                  selected={selectedLocation}
                  onChange={onLocationChange}
                  formWidth={50}
                />
              </td>
              <td className={classes.cell}>{t('auctionDeliveryDate')}</td>
              <td className={classes.cell}>
                <DatePicker
                  value={selectedAuctionDeliveryDate}
                  onChange={newValue => {
                    setSelectedAuctionDeliveryDate(newValue)
                  }}
                  renderInput={params => (
                    <MuiTextField {...params} variant='filled' />
                  )}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.cell}>{t('lotNumber')}</td>
              <td className={classes.cell}>
                <TextField
                  autoFocus
                  type='text'
                  inputProps={{ maxLength: 13, tabIndex: '1' }}
                  value={selectedLotNum}
                  onChange={onLotnumChange}
                />
              </td>
              <td className={classes.cell}>{t('clockNumber')}</td>
              <td className={classes.cell}>
                <TextField
                  type='tel'
                  value={selectedClock ?? ''}
                  inputProps={{ maxLength: 2, tabIndex: '4' }}
                  onChange={onClockChange}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.cell}>{t('lpnSupplyNumber')}</td>
              <td className={classes.cell}>
                <TextField
                  type='text'
                  inputProps={{ maxLength: 26, tabIndex: '2' }}
                  value={selectedLpnSupply}
                  onChange={onLpnSupplyChange}
                  style={{ width: 270 }}
                />
              </td>
              <td className={classes.cell}>{t('transactionNumber')}</td>
              <td className={classes.cell}>
                <TextField
                  type='tel'
                  inputProps={{ maxLength: 5, tabIndex: '5' }}
                  value={selectedTransaction ?? ''}
                  onChange={onTransactionChange}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.cell}>{t('deliveryNote')}</td>
              <td className={classes.cell}>
                <TextField
                  type='text'
                  inputProps={{ maxLength: 32, tabIndex: '3' }}
                  value={selectedDeliveryNote}
                  onChange={onDeliveryNoteChange}
                  onBlur={onDeliveryNoteBlur}
                  style={{ width: 270 }}
                />
              </td>
              <td className={classes.cell}>{t('orderNumber')}</td>
              <td className={classes.cell}>
                <TextField
                  type='text'
                  inputProps={{ maxLength: 40, tabIndex: '6' }}
                  value={selectedOrderNumber}
                  onChange={onOrderNumberChange}
                  //onBlur={onOrderNumberBlur}
                  style={{ width: 270 }}
                />
              </td>
            </tr>
            <tr>
              <td
                className={props.isPrinting ? classes.hidden : classes.cell}
                colSpan={5}
              >
                <ul>
                  <li className={props.classes.buttonListItem}>
                    <ExcelComponent
                      useCSVFormat={true}
                      excelData={props.searchResult}
                      excelOptions={props.excelOptions}
                    />
                  </li>
                  <li className={props.classes.buttonListItem}>
                    <PrintReport
                      batches={props && props.searchResult}
                      printHeader={getPrintHeader()}
                      className={
                        props.isPrinting ? classes.hidden : classes.button
                      }
                    />
                  </li>
                  <li className={props.classes.buttonListItem}>
                    <Button
                      variant='contained'
                      type='button'
                      onClick={clearState}
                      className={
                        props.isPrinting ? classes.hidden : classes.button
                      }
                    >
                      {t('reset')}
                    </Button>
                  </li>
                  <li className={props.classes.buttonListItem}>
                    <Button
                      variant='contained'
                      type='submit'
                      inputProps={{ tabIndex: '6' }}
                      //onClick={}
                      className={
                        props.isPrinting ? classes.hidden : classes.button
                      }
                      disabled={selectDisabled}
                    >
                      {t('search')}
                    </Button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
    </div>
  )
}

export default withStyles(styles)(TroubleshooterSearch)
