/* eslint-disable @typescript-eslint/require-await */
//import { SnackbarSingleton } from '@rfh/ui'
import { FC, ReactElement, Suspense, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import { Routes } from 'src/common/components/Routes'
import { ConfirmationProvider } from 'src/common/providers/ConfirmationProvider'
import 'src/i18n'
import { DialogContext } from './modules/context/Context'
import { Layout } from './common/components/Layout/Layout'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Config } from 'src/common/config'
import { PushNotificationProvider } from './modules/pushNotifications/PushNotificationProvider'
import { LoginCallback } from '@okta/okta-react'
import { SecureRoute } from '@okta/okta-react'
import { AccountStoreProvider } from './common/providers/AccountStore'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { nl } from 'date-fns/locale'
const oktaAuth = new OktaAuth(Config.oidc)

const App: FC = (): ReactElement => {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <ConfirmationProvider>
      <Suspense fallback={null}>
        <Router>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
            <AccountStoreProvider>
              <DialogContext.Provider value={{ dialogOpen, setDialogOpen }}>
                <Layout>
                  <Routes />
                </Layout>
              </DialogContext.Provider>
            </AccountStoreProvider>
          </LocalizationProvider>
        </Router>
      </Suspense>
    </ConfirmationProvider>
  )
}
export default App
