import { FC, ReactElement } from 'react'
import { Theme, TableRow, Button } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { ISeeker } from '../models/ISeeker'

interface IProps {
  seeker: ISeeker
  classType: string
  goToSeeker: (seekerId: number) => void
}

type ClassKey = 'root' | 'cell' | 'button'
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cell: {
      textAlign: 'left',
      padding: '2px',
    },
    button: {
      float: 'right',
      padding: '4px',
      marginRight: '10px',
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const SeekerListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const seeker = props.seeker
  const classType = props.classType
  const { classes } = props
  return (
    <TableRow key={seeker?.id}>
      <td className={classType}>{seeker?.id}</td>
      <td className={classType}>{seeker?.name}</td>
      <td className={classType}>{seeker?.isExpired ? 'Ja' : 'Nee'}</td>
      <td className={classes.cell}>
        <Button
          variant='contained'
          fullWidth
          className={classes.button}
          onClick={() => props.goToSeeker(seeker?.id)}
        >
          Details
        </Button>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(SeekerListItem)
