import { FC, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

export const Callback: FC = () => {
  const [loginError, setLoginError] = useState<any>()
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState<boolean>()
  const { oktaAuth, authState } = useOktaAuth()
  const authStateReady = !authState?.isPending ?? false
  const history = useHistory()

  useEffect(() => {
    handleLoginRedirect()
  }, [oktaAuth])

  useEffect(() => {
    if (
      (authStateReady && authState?.error) ||
      (!authStateReady && loginError)
    ) {
      const combinedError = {
        ...(authState.error as any),
        ...loginError,
      }
      setShouldRedirectToLogin(true)
      console.log(combinedError)
    }
  }, [authState, loginError])

  const handleLoginRedirect = async () => {
    try {
      await oktaAuth.handleLoginRedirect()
    } catch (error) {
      if (
        (error as string)?.toString() ===
        'OAuthError: User is not assigned to the client application.'
      ) {
        sessionStorage.clear()
        oktaAuth.signOut()
        oktaAuth.tokenManager.clear()
        history.push('/no-permission')
      }
    }
  }

  return shouldRedirectToLogin ? <Redirect to={'/login'} /> : null
}
