import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState, EmptyStateIcon } from '@rfh/ui'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

//import { Layout } from '../../../../common/components/Layout/Layout'

export const NoPermission: FC = () => {
  const { t } = useTranslation()

  return (
    <EmptyState
      graphic={<EmptyStateIcon />}
      header={t('noPermissionheader')}
      subheader={t('noPermissionsubheader')}
    />
  )
}
