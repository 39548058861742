import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import TicketReportSearchResultItem from './TicketReportSearchResultItem'
import { ITicketReportViewModel } from '../models/ITicketReportViewModel'

type MyFnType = (ticket?: ITicketReportViewModel) => void

type Props = {
  tickets: ITicketReportViewModel[]
  onClickItem: MyFnType
  onClickOrder: MyFnType
  onClickPriority: MyFnType
  printableVersion: boolean
  popupEnabled: boolean
  changePrioTicket: MyFnType
  refresh: () => void
}

const StyledTableCellA = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#3A4D41',
      color: theme.palette.common.white,
      padding: 5,
      lineHeight: 1.5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
  })
)(TableCell)

export const StyledTableCellB = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#0CC988',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      paddingBottom: 0,
      fontSize: 12,
      verticalAlign: 'top',
      border: 0,
    },
  })
)(TableCell)

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    fsTablePaginate: 'paginate',
  },
  thead: {
    display: 'table-row-group',
  },
})

function TicketReportSearchResultsTable(props: Props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead className={classes.thead}>
            <TableRow>
              <StyledTableCellA>&nbsp;</StyledTableCellA>
              <StyledTableCellA>
                <Tooltip title='Omschrijving'>
                  <span>{t('description')}</span>
                </Tooltip>
                <br />
                <Tooltip title='Invoerder'>
                  <span>{t('operator')}</span>
                </Tooltip>
                <br />
                <Tooltip title='Zoeker'>
                  <span>{t('seeker')}</span>
                </Tooltip>
              </StyledTableCellA>
              <StyledTableCellA>
                {t('waarnemer')}
                <br /> {t('submittedtime')}
                <br /> {t('lateshipmentdatetime')}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('customerCkn')} + {t('customerName')}
                <br /> {t('orderNumber')} + {t('detailNumber')}
                <br /> {t('explanation')}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('productDescription')} <br /> {t('lotNumber')}
              </StyledTableCellA>{' '}
              <StyledTableCellA>
                {t('situationShouldBe')} <br /> {t('productCode')}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('situationWas')}
                <br /> <span>{t('solved')} ?</span>
              </StyledTableCellA>
              <StyledTableCellA>
                {t('employee')}
                <br />
                {t('causedBy')}
              </StyledTableCellA>
              <StyledTableCellA>
                {t('damages')}
                <br /> <span>{t('solution')} ?</span>
              </StyledTableCellA>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tickets != null &&
              props.tickets.length > 0 &&
              props.tickets.map(t => (
                <TicketReportSearchResultItem
                  ticket={t}
                  onClickItem={props.onClickItem}
                  onClickOrder={props.onClickOrder}
                  onClickPriority={props.onClickPriority}
                  printableVersion={props.printableVersion}
                  popupEnabled={props.popupEnabled}
                  changePrioTicket={props.changePrioTicket}
                  refresh={props.refresh}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br />
    </>
  )
}

export default TicketReportSearchResultsTable
