import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import DynamicTableCell, { DynamicTableCellSizeEnum } from './DynamicTableCell'

type IReportToPrintType = {
  batches: Batch[] | undefined
  printHeader: string | undefined
}

const AmountOfExplanationRowChars = 172

const ExplanationCell = withStyles(theme => ({
  root: {
    fontSize: '9pt',
    color: '#000000',
    borderBottom: '0px solid #000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borders: 'none',
    padding: 0,
    height: 8,
    paddingLeft: 16,
  },
}))(TableCell)

const AddExplanation = props => {
  if (props === '' || props === null || props === undefined) {
    return
  }
  const explanationRows: string[] = []
  for (let i = 0; i < props.length / AmountOfExplanationRowChars; i++) {
    explanationRows[i] = props.substr(
      i * AmountOfExplanationRowChars,
      AmountOfExplanationRowChars
    )
  }
  return explanationRows.map(explanationRow => (
    <TableRow>
      <ExplanationCell colSpan={8}>{explanationRow}</ExplanationCell>
    </TableRow>
  ))
}

const StyledTableBody = withStyles(theme => ({
  root: {
    border: '1px solid #000000',
  },
}))(TableBody)

const StyledTableHead = withStyles(theme => ({
  root: {
    border: '1px solid #000000',
  },
}))(TableHead)

const StyledTableHeaderCell = withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: '7pt',
    borderBottom: '2px solid #000000',
  },
}))(TableCell)

const StyledTableContainer = withStyles(theme => ({
  root: {
    width: 'max-content',
  },
}))(TableContainer)

const PrintReportLayout = React.forwardRef(
  (props: IReportToPrintType, ref: any) => {
    const { t: translate } = useTranslation()

    return (
      <div
        ref={ref}
        style={{
          overflow: 'hidden',
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '10px',
          paddingBottom: '15px',
        }}
      >
        <style type='text/css' media='print'>
          {'@page { size: landscape; }'}
        </style>
        <h4>{props.printHeader}</h4>
        <StyledTableContainer>
          <Table size='small'>
            <StyledTableHead>
              <TableRow>
                <StyledTableHeaderCell>
                  {translate('complaintCode')} / {translate('errorCode')}
                  <br />
                  {translate('submittedtime')} / {translate('explanation')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  {translate('lotNumber')}
                  <br />
                  {translate('orderNo')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align='right'>
                  {translate('pickTime')}
                  <br />
                  {translate('supplyLocation')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align='right'>
                  {translate('productCodeSortSort')}
                  <br />
                  {translate('deliveryAgreementReference')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align='right'>
                  {translate('productDescription')} <br />
                  {translate('customerCkn')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>
                  {translate('buyersCard')}
                  <br />
                  {translate('customerName')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align='center'>
                  {translate('pickorderfustcontentheader')}
                  <br />
                  {translate('LPN')}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell align='right'>
                  {translate('pickerheader')}
                  <br />
                  {translate('location')}
                </StyledTableHeaderCell>
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {props.batches &&
                props.batches.map(batch =>
                  batch.orderRows.map(order => (
                    <>
                      <TableRow
                        key={
                          batch.lotNumber +
                          order.order?.orderNumber +
                          ((order.tickets && order.tickets[0]?.LasTicketId) ||
                            Math.random())
                        }
                      >
                        <DynamicTableCell
                          firstLine={
                            ((order.tickets &&
                              order.tickets[0]?.errorComplaint
                                ?.complaintCode) ||
                              '-') +
                            ' / ' +
                            ((order.tickets &&
                              order.tickets[0]?.errorComplaint?.errorCode) ||
                              '-')
                          }
                          secondLine={
                            order.tickets && order.tickets[0]?.submittedTime
                          }
                          size={DynamicTableCellSizeEnum.ExtraSmall}
                        />
                        {batch?.oliOrderIndicatie ? (
                          <DynamicTableCell
                            firstLine={batch.oliOrderBatchReference}
                            secondLine={batch?.lotNumber}
                            thirdLine={'*' + order.order?.orderNumber.slice(-5)}
                            size={DynamicTableCellSizeEnum.AllSpace}
                          />
                        ) : (
                          <DynamicTableCell
                            firstLine={batch?.lotNumber}
                            secondLine={
                              '*' + order.order?.orderNumber.slice(-5)
                            }
                            size={DynamicTableCellSizeEnum.AllSpace}
                          />
                        )}
                        <DynamicTableCell
                          firstLine={
                            order?.orderpick && order.orderpick?.pickTime
                          }
                          secondLine={
                            batch?.storageLocation
                              ? batch.storageLocation
                              : order?.orderpick?.pickLocation
                          }
                          size={DynamicTableCellSizeEnum.AllSpace}
                          align='right'
                        />
                        <DynamicTableCell
                          firstLine={batch?.sku}
                          secondLine={order.customer?.afleverafspraak}
                          size={DynamicTableCellSizeEnum.AllSpace}
                          align='right'
                        />
                        <DynamicTableCell
                          firstLine={batch?.itemDescription}
                          secondLine={order.customer?.ckn}
                          size={DynamicTableCellSizeEnum.AllSpace}
                          align='right'
                        />
                        <DynamicTableCell
                          firstLine={order.customer?.plate}
                          secondLine={order.customer?.customername}
                          size={DynamicTableCellSizeEnum.Small}
                        />
                        <DynamicTableCell
                          firstLine={
                            ((order.orderpick && order.orderpick?.quantity) ||
                              '-') +
                            ' / ' +
                            ((order.order && order.order?.quantity) || '-') +
                            ' / ' +
                            batch.packaging +
                            ' / ' +
                            batch.content
                          }
                          secondLine={'*' + order.orderpick?.lpn.slice(-6)}
                          size={DynamicTableCellSizeEnum.Small}
                          align='center'
                        />
                        <DynamicTableCell
                          firstLine={
                            order.orderpick && order.orderpick?.pickerId
                          }
                          secondLine={
                            order.delivery.location && order.delivery?.location
                          }
                          size={DynamicTableCellSizeEnum.Medium}
                          align='right'
                        />
                      </TableRow>
                      {AddExplanation(
                        order.tickets && order.tickets[0]?.explanation
                      )}
                    </>
                  ))
                )}
            </StyledTableBody>
          </Table>
        </StyledTableContainer>
      </div>
    )
  }
)

export default PrintReportLayout
