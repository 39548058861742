import { Box, colors, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container } from '@rfh/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { ThemeConfig } from 'src/common/config'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { ITroubleCauser } from '../models/ITroubleCauser'
import {
  addTroubleCauser,
  getTroubleCauser,
  removeTroubleCauser,
  updateTroubleCauser,
} from '../service/troubleCauserService'
import { FormMode } from 'src/common/enums'
import { IErrorCode } from 'src/modules/errorCodes/models/IErrorCode'
import { getErrorCodes } from 'src/modules/errorCodes/service/errorCodeService'
import { TailSpin } from 'react-loader-spinner/'
import { Backdrop } from '@mui/material'
import { TroubleCauserDetailFields } from '../components/TroubleCauserDetailFields'
import { IActivity } from '../models/IActivity'
import { getActiviteiten } from 'src/modules/activiteiten/service/activiteitService'

interface RouteParams {
  id: string
}

type ClassKey = 'root' | 'error' | 'autocomplete'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: theme.spacing(ThemeConfig.spacing.xsmall),
      marginTop: theme.spacing(ThemeConfig.spacing.small),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    error: {
      color: 'red',
    },
    autocomplete: {
      background: colors.common.white,
      padding: theme.spacing(0, 0),
      margin: theme.spacing(1, 0, 0.5, 0),
      minHeight: theme.spacing(4),
      border: 'none',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
    },
  })

const TroubleCauserDetail: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const params = useParams<RouteParams>()
    const confirm = useConfirmation()
    const { t } = useTranslation<string>()
    const { id } = params
    const { authState } = useOktaAuth()
    const [detailMode, setMode] = useState(FormMode.edit)
    const [activities, setActivities] = useState<IActivity[]>([])
    const [errorMessage, setErrorMessage] = useState('')
    const [errorCode, setErrorCode] = useState<IErrorCode | null>(null)
    const [errorCodes, setErrorCodes] = useState<IErrorCode[]>([])
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [activityObserver, setActivityObserver] = useState<IActivity | null>(
      null
    )
    const [activityCauser, setActivityCauser] = useState<IActivity | null>(null)
    const [activityController, setActivityController] =
      useState<IActivity | null>(null)

    const { classes } = props

    useEffect(() => {
      setIsloading(true)
      if (!isRoleApplicatieBeheer) {
        setMode(FormMode.view)
      } else if (id === '0') {
        setMode(FormMode.add)
      }
      if (
        errorCodes.length !== 0 &&
        activities.length !== 0 &&
        Number.parseInt(id, 10) > 0
      ) {
        console.log('useEffect detail')
        console.log('detail fetch ' + id)
        setSelectedTroubleCauserData()
      }
      setIsloading(false)
    }, [activities, errorCodes])

    useEffect(() => {
      async function setAllErrorCodes() {
        const response = await getErrorCodes(
          { errorCodeNumber: ' ', description: ' ', errorDefinition: ' ' },
          authState.accessToken?.accessToken
        )
        setErrorCodes(response)
      }
      setAllErrorCodes()
    }, [])

    useEffect(() => {
      async function setAllActivities() {
        const response = await getActiviteiten(
          authState.accessToken?.accessToken
        )
        setActivities(response)
      }
      setAllActivities()
    }, [])

    const setSelectedTroubleCauserData = async (): Promise<void> => {
      try {
        const intId: number = Number.parseInt(id, 10)
        console.log('fetchdata :' + id)
        const result = await getTroubleCauser(
          intId,
          authState.accessToken?.accessToken
        )
        setTroubleCauserDetail(result)
      } catch (error) {
        console.log(error)
      }
    }

    const setTroubleCauserDetail = (troubleCauser: ITroubleCauser): void => {
      setErrorCode(getErrorCodeByErrorCodeNumber(troubleCauser.errorcode))
      setActivityObserver(
        getActivityByActivityId(troubleCauser.activityobserver)
      )
      setActivityCauser(getActivityByActivityId(troubleCauser.activitycauser))
      if (troubleCauser.activitycontroller !== undefined) {
        setActivityController(
          getActivityByActivityId(troubleCauser.activitycontroller)
        )
      }
    }

    const getActivityByActivityId = (
      activityCode: number
    ): IActivity | null => {
      const foundActivity: IActivity | undefined = activities.find(
        a => a.id === activityCode
      )
      if (foundActivity !== undefined) {
        return foundActivity
      } else {
        return null
      }
    }

    const getErrorCodeByErrorCodeNumber = (
      errorCode: number
    ): IErrorCode | null => {
      const foundErrorCode: IErrorCode | undefined = errorCodes.find(
        e => e.foutCode1 === errorCode
      )
      if (foundErrorCode !== undefined) {
        return foundErrorCode
      } else {
        return null
      }
    }

    const removeItem = async (): Promise<void> => {
      const removeData = async (id: number): Promise<void> => {
        try {
          await removeTroubleCauser(id, authState.accessToken?.accessToken)
        } catch (error) {
          console.log(error)
        }
      }

      if (id) {
        try {
          await confirm({
            variant: 'danger',
            catchOnCancel: true,
            title: 'Weet u zeker dat u dit wilt verwijderen?',
            description: 'Deze actie kan niet ongedaan gemaakt worden!',
            okbuttontext: 'Ok',
            cancelbuttontext: 'Annuleer',
          })
        } catch (error) {
          if (error) {
            console.log(error)
          }
          return
        }

        await removeData(parseInt(id, 10))
        history.push('/troublecausers')
      }
    }

    const updateData = async (troubleCauser: ITroubleCauser): Promise<void> => {
      try {
        await updateTroubleCauser(
          troubleCauser,
          authState.accessToken?.accessToken
        )
        setTroubleCauserDetail(troubleCauser)
      } catch (error) {
        console.log(error)
      }
      history.push('/troublecausers')
    }

    const addData = async (troubleCauser: ITroubleCauser) => {
      try {
        const result = await addTroubleCauser(
          troubleCauser,
          authState.accessToken?.accessToken
        )
        console.log(result)
        if (result === null) {
          history.goBack()
        } else {
          if (result.id !== undefined) {
            history.goBack()
          } else {
            setErrorMessage('Insert is ongeldig: ' + result)
          }
        }
      } catch (error) {
        setErrorMessage('insert ongeldig')
        console.log(error)
      }
    }

    const handleSave = (): void => {
      const troubleCauser: ITroubleCauser = {
        id: Number.parseInt(id, 10),
        errorcode: errorCode?.foutCode1 ?? 0,
        activityobserver: activityObserver?.id ?? 0,
        activitycauser: activityCauser?.id ?? 0,
        activitycontroller: activityController?.id ?? undefined,
      }
      console.log(` to update ${JSON.stringify(troubleCauser)}`)
      if (
        troubleCauser.errorcode === 0 ||
        troubleCauser.activitycauser === 0 ||
        troubleCauser.activityobserver === 0
      ) {
        setErrorMessage(
          'Fout omschrijving, activiteit waarnemer en activiteit veroorzaker zijn verplicht'
        )
        return
      }
      if (id === '0') {
        addData(troubleCauser)
      } else {
        updateData(troubleCauser)
      }
    }

    const handleActivityObserverChange = (activity: IActivity | null): void => {
      if (activity) {
        setActivityObserver(activity)
      }
    }

    const handleActivityCauserChange = (activity: IActivity | null): void => {
      if (activity) {
        setActivityCauser(activity)
      }
    }

    const handleActivityControllerChange = (
      activity: IActivity | null
    ): void => {
      setActivityController(activity)
    }

    const handleErrorCodeChange = (errorCode: IErrorCode | null): void => {
      if (errorCode) {
        setErrorCode(errorCode)
      }
    }

    return (
      <Container>
        <Typography variant='h2' gutterBottom>
          Foutveroorzaker Details
        </Typography>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <TailSpin color='#3a4d41' height={100} width={100} />
        </Backdrop>
        <TroubleCauserDetailFields
          mode={detailMode}
          activities={activities}
          errorCodes={errorCodes}
          errorcodeValue={errorCode}
          activityObserverValue={activityObserver}
          activityCauserValue={activityCauser}
          activityControllerValue={activityController}
          handleErrorCodeChange={handleErrorCodeChange}
          handleActivityObserverChange={handleActivityObserverChange}
          handleActivityCauserChange={handleActivityCauserChange}
          handleActivityControllerChange={handleActivityControllerChange}
          classes={classes}
        ></TroubleCauserDetailFields>
        <Box mt={3}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            xs={3}
            spacing={1}
            className={classes.root}
          >
            <Grid item xs={12}>
              <Button
                type='submit'
                variant='contained'
                disabled={detailMode === FormMode.view}
                fullWidth
                onClick={handleSave}
              >
                Opslaan
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='outlined'
                disabled={detailMode === FormMode.view}
                fullWidth
                onClick={removeItem}
              >
                Verwijder
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
)

export default withStyles(styles)(TroubleCauserDetail)
