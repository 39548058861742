import React, { useState, ReactElement, FC, useEffect } from 'react'
import { colors, Table, Theme, TextField as MuiTextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { ITroubleshooterSearchSelection } from 'src/modules/troubleshooterReport/models/ITroubleshooterSearchSelection'
import { useTranslation } from 'react-i18next'
import {
  WarehouseEnum,
  warehouseLocations,
  complaintStatuses,
  ComplaintStatusEnum,
} from 'src/common/enums/Enum'
import { formatDate } from 'src/common/services/helpers/dateUtilities'
import { useOktaAuth } from '@okta/okta-react'
import { Button } from '@rfh/ui'
import { TextField } from '@rfh/ui'
import Cookies from 'universal-cookie'
import { ISolutionType } from 'src/modules/tickets/models/ISolutionType'
import { ISeeker } from 'src/modules/seekers/models/ISeeker'
import { ITicketReportSearchSelection } from '../models/ITicketReportSearchSelection'
import { Autocomplete } from '@mui/material'
import ExcelComponent from 'src/common/excel/component/ExcelComponent'
import { ITicketReportViewModel } from '../models/ITicketReportViewModel'
import { ExcelOptions } from 'src/common/excel/ExcelOptions'
import { IErrorObserverReport } from 'src/modules/tickets/models/IErrorObserverReport'

interface IProps {
  onClickSelect: (selection: ITicketReportSearchSelection) => void
  onClickWissen: () => void
  onClickPrint: (openPrintModal: boolean) => void
  isPrinting: boolean
  solutionTypes: ISolutionType[]
  seekers: ISeeker[]
  observers: IErrorObserverReport[]
  searchData: ITicketReportViewModel[]
  excelOptions: Partial<ExcelOptions>
}

type ClassKey =
  | 'cell'
  | 'root'
  | 'search'
  | 'table'
  | 'error'
  | 'button'
  | 'hidden'
  | 'autocomplete'
  | 'width200'
  | 'fieldNameCell'
  | 'smallFieldCell'
  | 'largeFieldCell'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      paddingLeft: '1px',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '0',
      margin: '0',
      whiteSpace: 'nowrap',
    },
    fieldNameCell: {
      textAlign: 'left',
      paddingLeft: '12px',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '0',
      margin: '0',
      whiteSpace: 'nowrap',
      width: '1%',
    },
    smallFieldCell: {
      textAlign: 'left',
      paddingLeft: '1px',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '0',
      margin: '0',
      whiteSpace: 'nowrap',
      width: '8%',
    },
    largeFieldCell: {
      textAlign: 'left',
      paddingLeft: '1px',
      paddingRight: '0',
      paddingBottom: '0',
      paddingTop: '0',
      margin: '0',
      whiteSpace: 'nowrap',
      width: '20%',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    search: {
      margin: theme.spacing(ThemeConfig.spacing.small),
    },
    table: {
      marginTop: '10px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
    },
    error: {
      color: 'red',
    },
    hidden: {
      display: 'none',
    },
    autocomplete: {
      background: '#ecebeb',
      padding: theme.spacing(0, 0),
      margin: theme.spacing(0.75, 0, 0.375, 0),
      border: 'none',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
    },
    width200: {
      width: 200,
    },
  })
type PropsType = IProps & WithStyles<ClassKey>

const getStoredLocation = (): WarehouseEnum => {
  const cookies = new Cookies()
  const storedLocation = cookies.get('las-vestiging')
  switch (storedLocation) {
    case 'Aalsmeer':
      return WarehouseEnum.Aalsmeer
    case 'Naaldwijk':
      return WarehouseEnum.Naaldwijk
    case 'Rijnsburg':
      return WarehouseEnum.Rijnsburg
    case 'Eelde':
      return WarehouseEnum.Eelde
    default:
      return WarehouseEnum.Aalsmeer
  }
}

const emptyDropDownOptionSeeker = { id: 0, name: '', isExpired: false }
const emptyDropDownOptionSolutionType = { code: 'geen', description: '' }
const emptyDropDownOptionErrorObserver = { id: 0, code: 'geen', activiteit: '' }

const TicketReportSearch: FC<PropsType> = (props: PropsType): ReactElement => {
  const { classes } = props
  const { t } = useTranslation()

  const [selectedLocation, setSelectedLocation] = useState<WarehouseEnum>(
    getStoredLocation()
  )
  const [selectedComplaintStatus, setSelectedComplaintStatus] =
    useState<ComplaintStatusEnum>(ComplaintStatusEnum.Total)
  const [selectedErrorCode, setSelectedErrorCode] = useState<number>()
  const [selectedComplaintCode, setSelectedComplaintCode] = useState<number>()
  const [selectedSeeker, setSelectedSeeker] = useState<ISeeker | null>(null)
  const [selectedObserver, setSelectedObserver] =
    useState<IErrorObserverReport | null>(null)
  const [selectedSolutionType, setSelectedSolutionType] =
    useState<ISolutionType | null>(null)
  const [selectedComplaintDate, setSelectedComplaintDate] =
    useState<Date | null>(new Date())
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true)
  const [selectedCustomerCkn, setSelectedCustomerCkn] = useState<number>()
  const [selectedPriority, setSelectedPriority] = useState<number>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (selectedComplaintDate == null || selectedComplaintStatus == null) {
      setSelectDisabled(true)
    } else {
      setSelectDisabled(false)
    }
  }, [selectedComplaintDate, selectedComplaintStatus])

  useEffect(() => {
    setSelectedComplaintStatus(ComplaintStatusEnum.Total)
    setSelectedComplaintDate(new Date())
    setErrorMessage('')
    setSelectDisabled(true)
  }, [])

  const clearState = () => {
    setSelectedComplaintStatus(ComplaintStatusEnum.Total)
    setSelectedErrorCode(undefined)
    setSelectedComplaintCode(undefined)
    setSelectedSeeker(null)
    setSelectedSolutionType(null)
    setSelectedCustomerCkn(undefined)
    setSelectedPriority(undefined)
    setSelectedObserver(null)
    setSelectedComplaintDate(new Date())

    setErrorMessage('')
    setSelectDisabled(true)
    props.onClickWissen()
  }

  const onComplaintCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedComplaintCode(numberValue)
  }

  const onErrorCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedErrorCode(numberValue)
  }

  const onCustomerCknChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedCustomerCkn(numberValue)
  }

  const onPriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != null && isNaN(Number(e.target.value))) {
      return
    }
    let numberValue: number | undefined = Number(e.target.value)
    if (numberValue < 1) {
      numberValue = undefined
    }
    setSelectedPriority(numberValue)
  }

  const handleLocationChange = (location: WarehouseEnum | null): void => {
    if (location) {
      const cookies = new Cookies()
      const warehouseId = location
      cookies.set('las-vestiging', WarehouseEnum[warehouseId], {
        path: '/',
        maxAge: 300000000,
      })
      setSelectedLocation(location)
    }
  }

  const handleSolutionTypeChange = (
    selectedSolutionType: ISolutionType | null
  ): void => {
    if (selectedSolutionType) {
      setSelectedSolutionType(selectedSolutionType)
    }
  }

  const handleComplaintStatusChange = (
    complaintStatus: ComplaintStatusEnum | null
  ): void => {
    if (complaintStatus) {
      setSelectedComplaintStatus(complaintStatus)
    }
  }

  const handleSeekerChange = (selectedSeeker: ISeeker | null): void => {
    if (selectedSeeker) {
      setSelectedSeeker(selectedSeeker)
    }
  }

  const handleObserverChange = (
    selectedObserver: IErrorObserverReport | null
  ): void => {
    if (selectedObserver) {
      setSelectedObserver(selectedObserver)
    }
  }

  const searchReports = (ev: React.SyntheticEvent) => {
    ev.preventDefault()
    const searchSelection: ITroubleshooterSearchSelection =
      getCurrentSelection()
    setErrorMessage('')
    console.log(
      `Start searching with selection: ${JSON.stringify(searchSelection)}`
    )
    if (!isValid(searchSelection)) {
      return
    }
    try {
      // const searchResults: SearchReportResult = await getReports(
      //   searchSelection,
      //   authState.accessToken?.accessToken
      // )
      props.onClickSelect(searchSelection)
    } catch (error: unknown) {
      const { message } = error as Error
      setErrorMessage(message)
    }
  }

  const handlePrint = async () => {
    props.onClickPrint(true)
  }

  const getComplaintStatusInputLabel = (
    option: ComplaintStatusEnum
  ): string => {
    switch (option) {
      case ComplaintStatusEnum.Total: {
        return t('complaintStatusTotal')
      }
      case ComplaintStatusEnum.Pending: {
        return t('complaintStatusPending')
      }
      case ComplaintStatusEnum.PendingAndInProgress: {
        return t('complaintStatusPendingAndInProgress')
      }
      case ComplaintStatusEnum.InProgress: {
        return t('complaintStatusInProgress')
      }
      case ComplaintStatusEnum.Solved: {
        return t('complaintStatusSolved')
      }
      default: {
        return t('complaintStatusTotal')
      }
    }
  }

  const getCurrentSelection = (): ITicketReportSearchSelection => ({
    location: selectedLocation,
    complaintDate:
      selectedComplaintDate === null ? '' : formatDate(selectedComplaintDate),
    complaintStatus: selectedComplaintStatus,
    complaintCode: selectedComplaintCode,
    seekerCode:
      selectedSeeker !== null && selectedSeeker.id !== 0
        ? selectedSeeker?.id
        : undefined,
    errorCode: selectedErrorCode,
    solutionTypeCode:
      selectedSolutionType !== null && selectedSolutionType.code !== 'geen'
        ? selectedSolutionType?.code
        : undefined,
    errorObserver:
      selectedObserver !== null && selectedObserver.id !== 0
        ? selectedObserver?.code
        : undefined,
    customerCkn: selectedCustomerCkn,
    priority: selectedPriority,
  })

  const isValid = (selection: ITicketReportSearchSelection): boolean => {
    if (
      selection.complaintDate === undefined ||
      selection.complaintStatus === undefined
    ) {
      setErrorMessage(t('troubleshooterSearchSelectionError'))
      return false
    }
    return true
  }

  const getSeekerOptions = (): ISeeker[] => {
    if (selectedSeeker == null) {
      return props.seekers
    } else {
      return props.seekers.concat(emptyDropDownOptionSeeker)
    }
  }

  const getSolutionTypeOptions = (): ISolutionType[] => {
    if (selectedSolutionType == null) {
      return props.solutionTypes
    } else {
      return props.solutionTypes.concat(emptyDropDownOptionSolutionType)
    }
  }

  const getObserverOptions = (): IErrorObserverReport[] => {
    if (selectedObserver == null) {
      return props.observers
    } else {
      return props.observers.concat(emptyDropDownOptionErrorObserver)
    }
  }

  return (
    <div className={classes.root}>
      {errorMessage !== '' && (
        <div className={classes.error}>{errorMessage}</div>
      )}
      <form onSubmit={searchReports}>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.fieldNameCell}>{t('location')}</td>
              <td className={classes.largeFieldCell}>
                <Autocomplete
                  id='location'
                  options={warehouseLocations}
                  onChange={(_event, newInputValue) =>
                    handleLocationChange(newInputValue)
                  }
                  getOptionLabel={option => WarehouseEnum[option]}
                  value={selectedLocation}
                  fullWidth
                  className={classes.autocomplete}
                  renderInput={params => <TextField required {...params} />}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('complaintStatus')}</td>
              <td className={classes.largeFieldCell}>
                <Autocomplete
                  id='complaintStatus'
                  options={complaintStatuses}
                  onChange={(_event, newInputValue) =>
                    handleComplaintStatusChange(newInputValue)
                  }
                  getOptionLabel={option =>
                    getComplaintStatusInputLabel(option)
                  }
                  value={selectedComplaintStatus}
                  fullWidth
                  className={classes.autocomplete}
                  renderInput={params => <TextField required {...params} />}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('complaintDate')}</td>
              <td className={classes.largeFieldCell}>
                <DatePicker
                  value={selectedComplaintDate}
                  onChange={newValue => {
                    setSelectedComplaintDate(newValue)
                  }}
                  renderInput={params => (
                    <MuiTextField {...params} variant='filled' />
                  )}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.fieldNameCell}>{t('customerCkn')}</td>
              <td className={classes.smallFieldCell}>
                <TextField
                  type='tel'
                  value={selectedCustomerCkn ?? ''}
                  inputProps={{ maxLength: 6, size: 8.6 }}
                  onChange={onCustomerCknChange}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('complaintCode')}</td>
              <td className={classes.smallFieldCell}>
                <TextField
                  type='tel'
                  value={selectedComplaintCode ?? ''}
                  inputProps={{ maxLength: 3, size: 8.6 }}
                  onChange={onComplaintCodeChange}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('seeker')}</td>
              <td className={classes.largeFieldCell}>
                <Autocomplete
                  id='seeker'
                  options={getSeekerOptions()}
                  getOptionLabel={option => (option.name ? option.name : '')}
                  onChange={(_event, newInputValue) =>
                    handleSeekerChange(newInputValue)
                  }
                  value={selectedSeeker}
                  fullWidth
                  className={`${classes.autocomplete} ${classes.width200}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.fieldNameCell}>{t('priorityLabel')}</td>
              <td className={classes.smallFieldCell}>
                <TextField
                  type='tel'
                  value={selectedPriority ?? ''}
                  inputProps={{ maxLength: 2, size: 8.6 }}
                  onChange={onPriorityChange}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('errorCode')}</td>
              <td className={classes.smallFieldCell}>
                <TextField
                  type='tel'
                  value={selectedErrorCode ?? ''}
                  inputProps={{ maxLength: 3, size: 8.6 }}
                  onChange={onErrorCodeChange}
                />
              </td>
              <td className={classes.fieldNameCell}>{t('solution')}</td>
              <td className={classes.largeFieldCell}>
                <Autocomplete
                  id='solution'
                  options={getSolutionTypeOptions()}
                  getOptionLabel={option =>
                    option.description ? option.description : ''
                  }
                  onChange={(_event, newInputValue) =>
                    handleSolutionTypeChange(newInputValue)
                  }
                  value={selectedSolutionType}
                  fullWidth
                  className={`${classes.autocomplete} ${classes.width200}`}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.fieldNameCell}>{t('waarnemer')}</td>
              <td className={classes.largeFieldCell}>
                <Autocomplete
                  id='observer'
                  options={getObserverOptions()}
                  getOptionLabel={option =>
                    option.activiteit ? option.activiteit : ''
                  }
                  onChange={(_event, newInputValue) =>
                    handleObserverChange(newInputValue)
                  }
                  value={selectedObserver}
                  fullWidth
                  className={classes.autocomplete}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </td>
              <td
                className={props.isPrinting ? classes.hidden : classes.cell}
                colSpan={4}
              >
                <ExcelComponent
                  excelData={props.searchData}
                  excelOptions={props.excelOptions}
                  useCSVFormat={true}
                />
                <Button
                  variant='contained'
                  type='button'
                  onClick={handlePrint}
                  className={props.isPrinting ? classes.hidden : classes.button}
                >
                  Print
                </Button>

                <Button
                  variant='contained'
                  type='button'
                  onClick={clearState}
                  className={props.isPrinting ? classes.hidden : classes.button}
                >
                  {t('reset')}
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  className={props.isPrinting ? classes.hidden : classes.button}
                  disabled={selectDisabled}
                >
                  {t('search')}
                </Button>
              </td>
              {props.isPrinting && (
                <td colSpan={4} className={classes.cell}></td>
              )}
            </tr>
          </tbody>
        </Table>
      </form>
    </div>
  )
}

export default withStyles(styles)(TicketReportSearch)
