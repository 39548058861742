/* eslint-disable react/jsx-no-comment-textnodes */
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { FC } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { StyledTableCellB } from './SearchResultsTable'
import { Ticket } from 'src/common/interfaces/SearchResults/Ticket'
import { Customer } from 'src/common/interfaces/SearchResults/Customer'
import { OrderPick } from 'src/common/interfaces/SearchResults/OrderPick'
import { Delivery } from 'src/common/interfaces/SearchResults/Delivery'
import { Order } from 'src/common/interfaces/SearchResults/Order'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ConstructionIcon from '@mui/icons-material/Construction'
import DangerousIcon from '@mui/icons-material/Dangerous'
import CheckIcon from '@mui/icons-material/Check'
import { CreateOpenTicketClick } from './CreateOpenTicketClick'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
type TicketView = (ticket?: Ticket) => void

interface SearchResultItemProps {
  ticket?: Ticket
  order: Order
  customer: Customer
  orderPick: OrderPick
  delivery: Delivery
  platesAmount: string
  printableVersion: boolean
  onClickItem: CreateOpenTicketClick
  warehouseId: number
  orderdata: string
  batch: Batch
  onClickPriority: TicketView
  priorityChangeAllowed: boolean
}

type PropsType = SearchResultItemProps

const StyledTableCellB2 = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#0CC988',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      paddingTop: 0,
      fontSize: 12,
      verticalAlign: 'top',
    },
  })
)(TableCell)

const StyledTableRowA = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const StyledTableRowB = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const styleStateCell = (state: string, priorityAllowed: boolean) => {
  if (state === '1') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#ff0000',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#ff0000',
      }
    }
  }
  if (state === '2') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFD800',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#FFD800',
      }
    }
  }
  if (state === '3') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#00ff00',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#00ff00',
      }
    }
  }
  return {}
}

const getPrinterStateIcon = (state: string) => {
  if (state === '1') return <ReportGmailerrorredIcon />
  if (state === '2') return <ConstructionIcon />
  if (state === '3') return <CheckIcon />
  return <DangerousIcon />
}

const SearchResultItem: FC<PropsType> = observer((props: PropsType) => {
  const {
    ticket,
    order,
    customer,
    orderPick,
    delivery,
    printableVersion,
    onClickItem,
    warehouseId,
    orderdata,
    batch,
    onClickPriority,
  } = props

  return (
    <>
      <StyledTableRowA
        key={order?.orderNumber + orderPick?.orderPickId + ticket?.LasTicketId}
      >
        {ticket == null ? (
          <>
            <StyledTableCellB
              rowSpan={2}
              colSpan={3}
              onClick={(): void =>
                onClickItem(warehouseId, orderdata, order, orderPick)
              }
              style={{ cursor: 'pointer' }}
            >
              &nbsp;
            </StyledTableCellB>
          </>
        ) : (
          <>
            {!printableVersion && (
              <StyledTableCellB
                onClick={(): void => onClickPriority(ticket)}
                rowSpan={2}
                style={styleStateCell(
                  ticket.state,
                  props.priorityChangeAllowed
                )}
              >
                {ticket.priority}
              </StyledTableCellB>
            )}
            {printableVersion && (
              <StyledTableCellB rowSpan={2}>
                {getPrinterStateIcon(ticket.state)}
              </StyledTableCellB>
            )}

            <StyledTableCellB
              onClick={(): void =>
                onClickItem(
                  warehouseId,
                  orderdata,
                  order,
                  orderPick,
                  ticket,
                  batch
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {ticket?.code} <br /> {ticket?.searcher}
            </StyledTableCellB>
            <StyledTableCellB
              onClick={(): void =>
                onClickItem(
                  warehouseId,
                  orderdata,
                  order,
                  orderPick,
                  ticket,
                  batch
                )
              }
              style={{ cursor: 'pointer' }}
            >
              {ticket?.operator}
            </StyledTableCellB>
          </>
        )}

        <StyledTableCellB>
          {order?.orderNumber} <br /> {order?.date} <br /> {order?.time}
        </StyledTableCellB>
        <StyledTableCellB>
          {order?.clock} <br /> {order?.transactionNumber} <br />
          {order?.quantity}
        </StyledTableCellB>
        <StyledTableCellB>
          {customer?.plate} / {customer?.ean} / {customer?.ckn ?? ''}
          <br />
          {customer?.customername} <br />
          {customer?.afleverafspraak}
        </StyledTableCellB>
        <StyledTableCellB>
          {orderPick?.pickerId}
          <br />
          {orderPick?.pickDate} <br /> {orderPick?.pickTime}
        </StyledTableCellB>
        <StyledTableCellB>
          {orderPick?.pickLocation}
          <br />
          {orderPick?.quantity}
        </StyledTableCellB>
        <StyledTableCellB>
          <span>{orderPick?.track}</span>
          <br />
          {orderPick?.lpn}
          <br />
          {orderPick?.pickPlatesAmount}
        </StyledTableCellB>
        <StyledTableCellB>{delivery?.oppicktijd}</StyledTableCellB>
        <StyledTableCellB>
          {delivery?.deliveryDriver}
          <br />
          {delivery?.deliveryDate} <br /> {delivery?.deliveryTime}
        </StyledTableCellB>
        <StyledTableCellB>
          {delivery?.location}
          <br />
          <br />
          {order?.lateshipmentTime}
        </StyledTableCellB>
      </StyledTableRowA>
      <StyledTableRowB>
        <StyledTableCellB2 colSpan={11}>
          {ticket &&
            ticket.explanation &&
            ticket.explanation.slice(0, 128) + '...'}
        </StyledTableCellB2>
      </StyledTableRowB>
      {/* empty TableRow below is needed to preserve background color for even-odd rows, in StyledTableRowA */}
      <TableRow></TableRow>
    </>
  )
})

export default SearchResultItem
