export const convertToDateTime = (date: Date | string = ''): Date => {
  let dat: Date
  if (date === '') {
    return new Date()
  } else if (typeof date === 'string') {
    dat = new Date(date)
  } else {
    dat = date
  }
  return dat
}

export const formatDate = (date: Date | string): string => {
  const dat = convertToDateTime(date)

  const day = ('00' + dat.getDate()).slice(-2)
  const month = ('00' + (dat.getMonth() + 1)).slice(-2)

  return `${dat.getFullYear()}${month}${day}`
}
