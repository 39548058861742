import { useLocalObservable } from 'mobx-react'

import { createContext, FC, useContext } from 'react'

import { AccessToken, IDToken } from '@okta/okta-auth-js/lib/types/Token'
import { AuthState, UserClaims } from '@okta/okta-auth-js/lib/types'

export interface AccountStore {
  user?: UserClaims
  accessToken?: AccessToken
  idToken?: IDToken
  isAuthenticated?: boolean
  permittedScopes?: string[]
  setPermittedScopes: (actor: any) => void
  authStateReady?: boolean
  setUser: (user: UserClaims) => void
  setAuthState: (authState: AuthState) => void
  setAuthStateReady: (isReady: boolean) => void
  isRoleApplicatieBeheer: boolean
  isRoleOnderhoudenKlachten: boolean
  isRoleInzienKlachten: boolean
  isRoleCoordinator: boolean
}

export const AccountStoreContext = createContext<AccountStore | null>(null)

// Store as component
export const AccountStoreProvider: FC = ({ children }) => {
  const accountStore: AccountStore = useLocalObservable(
    () =>
      ({
        setUser(user) {
          this.user = user
        },
        setAuthState(authState: AuthState) {
          this.accessToken = authState.accessToken
          this.idToken = authState.idToken
          this.isAuthenticated = authState.isAuthenticated
        },
        setPermittedScopes(scopes: string[]) {
          this.permittedScopes = scopes
          this.isRoleApplicatieBeheer =
            scopes.findIndex(e => e.includes('applicatiebeheerder')) > -1
          this.isRoleOnderhoudenKlachten =
            scopes.findIndex(e => e.includes('onderhoudenklachten')) > -1
          this.isRoleInzienKlachten =
            scopes.findIndex(e => e.includes('inzienklachten')) > -1
          this.isRoleCoordinator =
            scopes.findIndex(e => e.includes('coordinator')) > -1
        },
        setAuthStateReady(isReady: boolean) {
          this.authStateReady = isReady
        },
      } as AccountStore)
  )

  return (
    <AccountStoreContext.Provider value={accountStore}>
      {children}
    </AccountStoreContext.Provider>
  )
}

export const useAccountStore = (): AccountStore => {
  const store = useContext(AccountStoreContext)

  if (!store) {
    throw new Error('No account store defined')
  }

  return store
}
