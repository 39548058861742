import * as request from 'superagent'
import { Config } from 'src/common/config'
import { ApiResponse } from 'src/modules/troubleshooterReport/models/ApiResponse'
import { ITicketReportSearchSelection } from '../models/ITicketReportSearchSelection'
import { ITicketReportSearchResult } from '../models/ITicketReportSearchResult'
import { WarehouseEnum } from 'src/common/enums/Enum'

const backendUrl = Config.api.host
const ticketReportRoute = 'TicketReport'

export const getTickets = async (
  selection: ITicketReportSearchSelection,
  accessToken: string
): Promise<ApiResponse<ITicketReportSearchResult>> => {
  let urlQuery = `${WarehouseEnum[selection.location]}/search/${
    selection.complaintDate
  }/${selection.complaintStatus}`

  if (selection.complaintCode !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    }
    urlQuery += `complaintCode=${selection.complaintCode}`
  }

  if (selection.errorCode !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `errorCode=${selection.errorCode}`
  }

  if (selection.solutionTypeCode !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `solutionTypeCode=${selection.solutionTypeCode}`
  }

  if (selection.seekerCode !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `seekerCode=${selection.seekerCode}`
  }

  if (selection.customerCkn !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `customerCkn=${selection.customerCkn}`
  }

  if (selection.priority !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `priority=${selection.priority}`
  }

  if (selection.errorObserver !== undefined) {
    if (!urlQuery.includes('?')) {
      urlQuery += '?'
    } else {
      urlQuery += '&'
    }
    urlQuery += `errorObserver=${selection.errorObserver}`
  }

  try {
    const response = await request
      .get(`${backendUrl}/${ticketReportRoute}/${urlQuery}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    const ticketResponse: ApiResponse<ITicketReportSearchResult> = {
      body: {
        tickets: response.body,
      },
      httpStatus: response.statusCode,
      errorMessage: '',
    }
    console.log(ticketResponse)
    return ticketResponse
  } catch (error: any) {
    const ticketResponse: ApiResponse<ITicketReportSearchResult> = {
      httpStatus: error.status,
      errorMessage: error.message,
    }
    return ticketResponse
  }
}
