import { Grid } from '@mui/material'
import { Autocomplete, TextField as MuiTextField } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormMode } from 'src/common/enums'
import { IErrorCode } from 'src/modules/errorCodes/models/IErrorCode'
import { IActivity } from '../models/IActivity'

type HandleActivityChangeFunctionType = (
  newInputValue: IActivity | null
) => void
type HandleErrorCodeChangeFunctionType = (
  newInputValue: IErrorCode | null
) => void

interface IProps {
  mode: FormMode
  activities: IActivity[]
  errorCodes: IErrorCode[]
  errorcodeValue: IErrorCode | null
  activityObserverValue: IActivity | null
  activityCauserValue: IActivity | null
  activityControllerValue: IActivity | null
  classes: any
  handleErrorCodeChange: HandleErrorCodeChangeFunctionType
  handleActivityObserverChange: HandleActivityChangeFunctionType
  handleActivityCauserChange: HandleActivityChangeFunctionType
  handleActivityControllerChange: HandleActivityChangeFunctionType
}
type PropsType = IProps

const emptyDropDownOptionActivityOption = {
  id: 0,
  code: '',
  omschrijving: '',
  organisatiekenmerk: 0,
  uitvoerder: 0,
}

export const TroubleCauserDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { mode } = props

  const getActivityControllerOptions = (): IActivity[] => {
    if (props.activityControllerValue == null) {
      return props.activities
    } else {
      const activitiesPlusEmptyOption: IActivity[] = props.activities.concat(
        emptyDropDownOptionActivityOption
      )
      return activitiesPlusEmptyOption
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          Fout omschrijving*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Activiteit waarnemer*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Activiteit veroorzaker*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Activiteit controleur
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={props.errorCodes}
            getOptionLabel={option =>
              option.foutOmschrijving ? option.foutOmschrijving : ''
            }
            onChange={(_event, newInputValue) =>
              props.handleErrorCodeChange(newInputValue)
            }
            fullWidth
            value={props.errorcodeValue}
            className={props.classes.autocomplete}
            isOptionEqualToValue={(option, value) =>
              option.foutOmschrijving === value.foutOmschrijving
            }
            renderInput={params => <TextField {...params} required />}
            disabled={props.mode === FormMode.edit}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={props.activities}
            getOptionLabel={option =>
              option.omschrijving ? option.omschrijving : ''
            }
            onChange={(_event, newInputValue) =>
              props.handleActivityObserverChange(newInputValue)
            }
            fullWidth
            value={props.activityObserverValue}
            className={props.classes.autocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => <TextField {...params} required />}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={props.activities}
            getOptionLabel={option =>
              option.omschrijving ? option.omschrijving : ''
            }
            onChange={(_event, newInputValue) =>
              props.handleActivityCauserChange(newInputValue)
            }
            fullWidth
            value={props.activityCauserValue}
            className={props.classes.autocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => <TextField {...params} required />}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={getActivityControllerOptions()}
            getOptionLabel={option =>
              option.omschrijving ? option.omschrijving : ''
            }
            onChange={(_event, newInputValue) =>
              props.handleActivityControllerChange(newInputValue)
            }
            fullWidth
            value={props.activityControllerValue}
            className={props.classes.autocomplete}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </>
  )
}
