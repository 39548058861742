import { TableCell, TableCellProps } from '@mui/material'
import { withStyles } from '@mui/styles'
import { FC, ReactElement } from 'react'

export enum DynamicTableCellSizeEnum {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  AllSpace = 'AllSpace',
}

export enum DynamicTableCellString {
  Empty = 'Empty',
}

type DynamicTableCellProperties = {
  firstLine: string | number | DynamicTableCellString | any | undefined
  secondLine: string | number | DynamicTableCellString | any | undefined
  thirdLine?: string | number | DynamicTableCellString | any | undefined
  size: DynamicTableCellSizeEnum | string
  align?: TableCellProps['align']
}

const BaseCell = withStyles(theme => ({
  root: {
    fontSize: '9pt',
    color: '#000000',
    borderTop: '1px solid #000000',
    borderBottom: '0px solid #000000 ',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(TableCell)

const ExtraSmallCell = withStyles(theme => ({
  root: {
    maxWidth: '80px',
  },
}))(BaseCell)

const SmallCell = withStyles(theme => ({
  root: {
    maxWidth: '120px',
  },
}))(BaseCell)

const MediumCell = withStyles(theme => ({
  root: {
    maxWidth: '160px',
  },
}))(BaseCell)

const LargeCell = withStyles(theme => ({
  root: {
    maxWidth: '400px',
  },
}))(BaseCell)

const createCellBody = (props: DynamicTableCellProperties): React.ReactNode => {
  return (
    <>
      {!props.firstLine && '-'}
      {props.firstLine === DynamicTableCellString.Empty && ''}
      {props.firstLine &&
        props.firstLine !== DynamicTableCellString.Empty &&
        props.firstLine}
      <br />
      {!props.secondLine && '-'}
      {props.secondLine === DynamicTableCellString.Empty && ''}
      {props.secondLine &&
        props.secondLine !== DynamicTableCellString.Empty &&
        props.secondLine}
      {props.thirdLine && (
        <>
          <br />
          {props.thirdLine === DynamicTableCellString.Empty && ''}
          {props.thirdLine &&
            props.thirdLine !== DynamicTableCellString.Empty &&
            props.thirdLine}
        </>
      )}
    </>
  )
}

const DynamicTableCell: FC<DynamicTableCellProperties> = (
  props: DynamicTableCellProperties
): ReactElement => {
  return (
    <>
      {props.size === DynamicTableCellSizeEnum.ExtraSmall && (
        <ExtraSmallCell align={props.align}>
          {createCellBody(props)}
        </ExtraSmallCell>
      )}

      {props.size === DynamicTableCellSizeEnum.Small && (
        <SmallCell align={props.align}>{createCellBody(props)}</SmallCell>
      )}

      {props.size === DynamicTableCellSizeEnum.Medium && (
        <MediumCell align={props.align}>{createCellBody(props)}</MediumCell>
      )}

      {props.size === DynamicTableCellSizeEnum.Large && (
        <LargeCell align={props.align}>{createCellBody(props)}</LargeCell>
      )}

      {props.size === DynamicTableCellSizeEnum.AllSpace && (
        <BaseCell align={props.align}>{createCellBody(props)}</BaseCell>
      )}
    </>
  )
}

export default DynamicTableCell
