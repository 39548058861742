import { Button, createStyles, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { FC, ReactElement, useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { Batch } from 'src/common/interfaces/SearchResults/Batch'
import PrintReportLayout from './PrintReportLayout'

type PrintReportTypeProperties = {
  batches: Batch[]
  printHeader: string
  className: string
}

type ClassKey = 'button'

const styles = (theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
  })

type PropsType = WithStyles<ClassKey>

type PrintReportPropsType = PrintReportTypeProperties & PropsType

const PrintReport: FC<Partial<PrintReportPropsType>> = (
  props: Partial<PrintReportPropsType>
): ReactElement => {
  const printLayoutRef = useRef(null)

  return (
    <div
      className={props.className}
      style={{
        margin: '4px',
      }}
    >
      <ReactToPrint
        trigger={() => <Button variant='contained'>Print</Button>}
        content={() => printLayoutRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintReportLayout
          batches={props.batches}
          printHeader={props.printHeader}
          ref={printLayoutRef}
        />
      </div>
    </div>
  )
}

export default PrintReport
