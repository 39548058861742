import * as request from 'superagent'
import { Config } from 'src/common/config'
import { ISeeker } from '../models/ISeeker'

const backendUrl = Config.api.host
const seekerRoute = 'Seeker'

export const getSeekers = async (
  accessToken: string,
  name: string,
  withExpired: boolean
): Promise<ISeeker[]> => {
  try {
    const queryObject = { name, withExpired }
    const response = await request
      .get(`${backendUrl}/${seekerRoute}`)
      .query(queryObject)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const getSeeker = async (
  accessToken: string,
  seekerId: number
): Promise<ISeeker> => {
  try {
    const response = await request
      .get(`${backendUrl}/${seekerRoute}/${seekerId}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateSeeker = async (
  accessToken: string,
  seekerId: number,
  seeker: ISeeker
): Promise<void> => {
  await request
    .put(`${backendUrl}/${seekerRoute}/${seekerId}`)
    .send(seeker)
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer ' + accessToken)
}

export const deleteSeeker = async (
  accessToken: string,
  seekerId: number
): Promise<void> => {
  await request
    .del(`${backendUrl}/${seekerRoute}/${seekerId}`)
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer ' + accessToken)
}

export const createNewSeeker = async (
  accessToken: string,
  seeker: ISeeker
): Promise<void> => {
  await request
    .post(`${backendUrl}/${seekerRoute}/`)
    .send(seeker)
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Bearer ' + accessToken)
}
