import { useState, ReactElement, FC, useEffect } from 'react'
import { colors, Table, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { useOktaAuth } from '@okta/okta-react'
import { Button } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField } from '@rfh/ui'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { getTroubleCausers } from '../service/troubleCauserService'
import { IErrorCode } from 'src/modules/errorCodes/models/IErrorCode'
import { Autocomplete } from '@mui/material'
import TroubleCauserListItem from '../components/TroubleCauserListItem'
import { ITroubleCauserSearchSelection } from '../models/IITroubleCauserSearchSelection'
import { ITroubleCauser } from '../models/ITroubleCauser'
import { getErrorCodes } from 'src/modules/errorCodes/service/errorCodeService'
import { TailSpin } from 'react-loader-spinner'
import { Backdrop } from '@mui/material'
import { IActivity } from '../models/IActivity'
import { ITroubleCauserListItemModel } from '../models/ITroubleCauserListItemModel'
import { getActiviteiten } from 'src/modules/activiteiten/service/activiteitService'

type ClassKey = 'cell' | 'root' | 'table' | 'error' | 'button' | 'autocomplete'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
    error: {
      color: 'red',
    },
    autocomplete: {
      background: colors.common.white,
      padding: theme.spacing(0, 0),
      margin: theme.spacing(1, 0, 0.5, 0),
      minHeight: theme.spacing(4),
      border: 'none',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
      width: '50%',
    },
  })
type PropsType = WithStyles<ClassKey>

const TroubleCauserList: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()
    const { authState } = useOktaAuth()

    const [activities, setActivities] = useState<IActivity[]>([])
    const [errorCodes, setErrorCodes] = useState<IErrorCode[]>([])
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [selectedErrorCode, setSelectedErrorCode] =
      useState<IErrorCode | null>()
    const [troubleCausers, setTroubleCausers] = useState<ITroubleCauser[]>([])

    const { classes } = props

    useEffect(() => {
      async function getToubleCauserData() {
        setIsloading(true)
        await fetchData()
        setIsloading(false)
      }
      getToubleCauserData()
      if (authState.isAuthenticated) {
        console.log(authState)
      }
    }, [authState])

    useEffect(() => {
      async function setAllErrorCodes() {
        const response = await getErrorCodes(
          { errorCodeNumber: ' ', description: ' ', errorDefinition: ' ' },
          authState.accessToken?.accessToken
        )
        setErrorCodes(response)
      }
      setAllErrorCodes()
    }, [])

    useEffect(() => {
      async function setAllActivities() {
        const response = await getActiviteiten(
          authState.accessToken?.accessToken
        )
        setActivities(response)
      }
      setAllActivities()
    }, [])

    const fetchData = async (): Promise<void> => {
      try {
        console.log('zoek data')
        const searchErrorCode =
          selectedErrorCode != null ? selectedErrorCode.foutCode1 : null
        console.log('code' + searchErrorCode)

        const selection: ITroubleCauserSearchSelection = {
          errorCode: searchErrorCode,
        }

        const result = await getTroubleCausers(
          selection,
          authState.accessToken?.accessToken
        )
        console.log('result:')
        console.log(result)
        console.log({ result })
        setTroubleCausers(result)
      } catch (error) {
        console.log(error)
      }
    }

    const createNewItem = (): void => {
      if (isRoleApplicatieBeheer) {
        history.push(`${location.pathname}/${0}`)
      }
    }

    const zoekIfEnter = (e: string) => {
      if (e === 'Enter') {
        fetchData()
      }
    }

    const goToTroubleCauser = (id: number): void => {
      history.push(`${location.pathname}/${id}`)
    }

    const convertToListItemModel = (
      troubleCauser: ITroubleCauser
    ): ITroubleCauserListItemModel => {
      return {
        id: troubleCauser.id,
        errorcodecode:
          errorCodes.find(e => e.foutCode1 === troubleCauser.errorcode)
            ?.foutCode1 ?? 0,
        errorcodedescription:
          errorCodes.find(e => e.foutCode1 === troubleCauser.errorcode)
            ?.foutOmschrijving ?? '',
        activityobserverdescription:
          activities.find(a => a.id === troubleCauser.activityobserver)
            ?.omschrijving ?? '',
        activitycauserdescription:
          activities.find(a => a.id === troubleCauser.activitycauser)
            ?.omschrijving ?? '',
        activitycontrollerdescription:
          activities.find(a => a.id === troubleCauser.activitycontroller)
            ?.omschrijving ?? '',
      }
    }

    return (
      <div className={classes.root}>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <h2>Onderhouden Foutveroorzakers</h2>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.cell}>Fout Omschrijving</td>
            </tr>
            <tr onKeyUp={e => zoekIfEnter(e.key)}>
              <td className={classes.cell}>
                <Autocomplete
                  id='errordescription'
                  options={errorCodes}
                  getOptionLabel={option =>
                    option.foutOmschrijving ? option.foutOmschrijving : ''
                  }
                  onChange={(event: any, newValue: IErrorCode | null) => {
                    setSelectedErrorCode(newValue)
                  }}
                  fullWidth
                  value={selectedErrorCode}
                  className={classes.autocomplete}
                  isOptionEqualToValue={(option, value) =>
                    option.foutCode1 === value.foutCode1
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </td>
              <td className={classes.cell}>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={fetchData}
                  className={classes.button}
                >
                  Zoeken
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table className={classes.table}>
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <TailSpin color='#3a4d41' height={100} width={100} />
          </Backdrop>
          <thead>
            <tr>
              <th className={classes.cell}>Fout Code</th>
              <th className={classes.cell}>Fout Omschrijving</th>
              <th className={classes.cell}>Activiteit waarnemer</th>
              <th className={classes.cell}>Activiteit veroorzaker</th>
              <th className={classes.cell}>Activiteit controleur</th>
            </tr>
          </thead>
          <tbody>
            {troubleCausers !== null &&
              troubleCausers.length &&
              Array.isArray(troubleCausers) &&
              troubleCausers?.map(
                (troubleCauser: ITroubleCauser, index: number) => (
                  <TroubleCauserListItem
                    key={index}
                    troubleCauserListItem={convertToListItemModel(
                      troubleCauser
                    )}
                    classType={classes.cell}
                    goToTroubleCauser={goToTroubleCauser}
                  ></TroubleCauserListItem>
                )
              )}
            <tr>
              <td>
                {isRoleApplicatieBeheer && (
                  <Button
                    variant='contained'
                    className='btn btn-primary'
                    onClick={createNewItem}
                  >
                    Toevoegen
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
)

export default withStyles(styles)(TroubleCauserList)
