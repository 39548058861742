import { IConfig } from 'src/common/interfaces'
import firebaseConfig from './firebase.json'

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
let envValue = '#{OktaOmgeving}#'
if (envValue.startsWith('#{')) {
  envValue = 'dev'
}
console.log('Env: ' + envValue)

let apiHostValue = '#{ApiHost}#'
if (apiHostValue.startsWith('#{')) {
  apiHostValue = 'https://localhost:50214'
  //apiHostValue = 'https://localhost:44369'
}

// Enable or disable OktaAuthentication (IE: disable for Cypress testing)
let oktaAuthentication = 'on'
if (oktaAuthentication.startsWith('#{')) {
  oktaAuthentication = 'on'
}

let redirectUriValue = '#{OktaRedirectUri}#'
if (redirectUriValue.startsWith('#{')) {
  redirectUriValue = 'http://localhost:3000/callback'
}

let clientIdValue = '#{OktaClientId}#'
if (clientIdValue.startsWith('#{') || clientIdValue === '') {
  clientIdValue = '0oa54z8p85BiJo3Fe0x7'
}

let domainValue = '#{OktaDomain}#'
if (domainValue.startsWith('#{')) {
  domainValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let issuerValue = '#{OktaIssuer}#'
if (issuerValue.startsWith('#{')) {
  issuerValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let buildVersion = '$11.9.3.0$3'
if (buildVersion.startsWith('$')) {
  buildVersion = buildVersion.replaceAll('$', '')
}
if (/$11.9.3.0$3/.exec(buildVersion)) {
  buildVersion = 'onbekend'
}

export const Config: IConfig = {
  env: envValue,
  oktaRoute: oktaAuthentication,
  api: {
    host: apiHostValue,
    path: '',
    version: '',
  },
  oidc: {
    clientId: clientIdValue,
    domain: domainValue,
    issuer: issuerValue,
    redirectUri: redirectUriValue,
    // prettier-ignore
    scopes: ['openid', 'profile', 'email', 'las-zoekenencorrectie-api-' + envValue],
    pkce: true,
  },
  aws: {
    IdentityPoolId: 'eu-west-1:34b2b6f1-3c5c-4263-84ec-ba688f577da4',
    region: 'eu-west-1',
    PlatformApplicationArn:
      'arn:aws:sns:eu-west-1:002779451522:app/GCM/pnb-oi-subscriber-sns-platform-app',
    topicArn: 'arn:aws:sns:eu-west-1:002779451522:pnb-oi-notifications-topic',
    dotnetApiSubscribe:
      'https://pnb-oi-notifications-api.sandbox.royalfloraholland.com/subscriber',
    dotnetApiPublish:
      'https://pnb-oi-notifications-api.sandbox.royalfloraholland.com/publisher',
  },
  firebase: firebaseConfig,
  buildVersion,
}
