/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-template-curly-in-string */
//import { useOktaAuth } from '@okta/okta-react'
import { useAccountStore } from '../../providers/AccountStore'
import { icons, RfhAppBar } from '@rfh/ui'
import { RfhPrimaryMenuProps } from '@rfh/ui/components/RfhPrimaryMenu/RfhPrimaryMenu'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import i18n from 'src/i18n'
import { observer } from 'mobx-react'
import { Config } from 'src/common/config/Config'

export const AppBar: FC = observer(() => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  //const { setDrawerIsOpen } = useGlobalStore()
  const { user } = useAccountStore()
  const { FlagGB, FlagNL, RfhLogoMini } = icons
  const getFlag = (): JSX.Element =>
    i18n.language?.substr(0, 2) === 'nl' ? (
      <FlagNL />
    ) : (
      <FlagGB /> //forcing hook on icon
    )

  const nextLanguageAsync = (): void => {
    i18n.changeLanguage(i18n.language?.substr(0, 2) === 'nl' ? 'en' : 'nl')
  }
  //const login = async () => await oktaAuth.signInWithRedirect('/')

  const primaryMenuProps: RfhPrimaryMenuProps = {
    items: [
      {
        text: t('version').concat(': ', Config.buildVersion),
        icon: undefined,
        isFooter: true,
        onClick: undefined,
      },
      {
        text: t('changeLanguage'),
        icon: getFlag(),
        isFooter: true,
        onClick: (event: React.MouseEvent<Element, MouseEvent>) =>
          nextLanguageAsync(),
      },
      //{
      //  text: t('loginWithOkta'),
      //  icon: <RfhLogoMini />,
      //  isFooter: true,
      //onClick: (event: React.MouseEvent<Element, MouseEvent>) => login(),
      //},
    ],
  }

  return (
    <>
      <RfhAppBar
        primaryMenu={primaryMenuProps}
        mode={'menuItems'}
        title={'LAS'}
        onLogoClick={() => {
          history.push('/')
        }}
      />
    </>
  )
})
//export default AppBar
