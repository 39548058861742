import * as request from 'superagent'
import { IActiviteit } from '../models/IActiviteit'
import { Config } from '../../../common/config/Config'
import {
  addLocalItem,
  addOfflineEvent,
  getLocalItem,
  removeLocalItem,
  setLocalItem,
  updateLocalItem,
} from '../../../common/utils/managers/OfflineManager'

const backendUrl = Config.api.host
const activiteitRoute = 'Activiteit'
const localStorageKey = 'activiteit'
//const accessToken = 'todoTOKEN'

export const getActiviteiten = async (
  accessToken: string
): Promise<IActiviteit[]> => {
  if (!isOnline()) {
    console.log('is not online activiteit')
    return getLocalItem<IActiviteit[]>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${activiteitRoute}/All`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    console.log({ response })
    setLocalItem<IActiviteit[]>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

const isOnline = () => true

export const getActiviteit = async (
  accessToken: string,
  code: string,
  omschrijving: string
): Promise<IActiviteit> => {
  if (!isOnline()) {
    console.log('is not online activiteit')
    console.log(code + ' ' + omschrijving)
    return getLocalItem<IActiviteit>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${activiteitRoute}/Code/${code}/${omschrijving}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    console.log({ response })
    setLocalItem<IActiviteit>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const getActiviteitById = async (
  accessToken: string,
  id: number
): Promise<IActiviteit> => {
  if (!isOnline()) {
    console.log('is not online activiteit')
    console.log(id)
    return getLocalItem<IActiviteit>(localStorageKey)
  }

  try {
    const response = await request
      .get(`${backendUrl}/${activiteitRoute}/Id/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    console.log({ response })
    setLocalItem<IActiviteit>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const removeActiviteit = async (
  id: number,
  accessToken: string
): Promise<IActiviteit> => {
  if (!isOnline()) {
    addOfflineEvent('removeActiviteit', id)
    return removeLocalItem<IActiviteit>(localStorageKey, id) as IActiviteit
  }

  try {
    console.log(`${backendUrl}/${activiteitRoute}/${id}`)
    const response = await request
      .del(`${backendUrl}/${activiteitRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    removeLocalItem<IActiviteit>(localStorageKey, id)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const addActiviteit = async (
  item: IActiviteit,
  accessToken: string
): Promise<IActiviteit> => {
  //item.createdBy = 'ar'
  // Use this dateFormat for the Dev .net Lambda
  //item.created = '0001-01-01'
  // You can use this for your local Json Server
  // `${new Date().getFullYear().toString()}` +
  // '/' +
  // `${new Date().getMonth().toString()}` +
  // '/' +
  // `${new Date().getDate().toString()}`

  if (!isOnline()) {
    addOfflineEvent('addActiviteit', item)
    return new Promise((_, reject): void => reject('no internet connection'))
    // return offlineManager.addItemToLocalStorage<IActiviteit>(localStorageKey, item)
    // TODO: show toaster that there is no Internet connection and promise to update when it is back
    //       idem by delete and update
  }

  try {
    const response = await request
      .post(`${backendUrl}/${activiteitRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    addLocalItem<IActiviteit>(localStorageKey, response.body)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateActiviteit = async (
  item: Partial<IActiviteit>,
  accessToken: string
): Promise<IActiviteit> => {
  if (!isOnline()) {
    addOfflineEvent('updateActiviteit', item)
    return updateLocalItem<IActiviteit>(
      localStorageKey,
      item as IActiviteit,
      item.id
    )
  }

  const putUri = `${backendUrl}/${activiteitRoute}`

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    updateLocalItem<IActiviteit>(localStorageKey, response.body, item.id)
    return response.body
  } catch (error: any) {
    return error.message
  }
}
