import * as request from 'superagent'
import { Config } from 'src/common/config'
import { ITroubleCauserSearchSelection } from '../models/IITroubleCauserSearchSelection'
import { ITroubleCauser } from '../models/ITroubleCauser'

const backendUrl = Config.api.host
const troubleCauserRoute = 'TroubleCauser'

export const getTroubleCausers = async (
  selection: ITroubleCauserSearchSelection,
  accesToken: string
): Promise<ITroubleCauser[]> => {
  try {
    const response = await request
      .get(`${backendUrl}/${troubleCauserRoute}/${selection.errorCode ?? ''}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const getTroubleCauser = async (
  id: number,
  accessToken: string
): Promise<ITroubleCauser> => {
  try {
    const response = await request
      .get(`${backendUrl}/${troubleCauserRoute}/getDetail/${id}`)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('Content-Type', 'application/json')
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const addTroubleCauser = async (
  item: ITroubleCauser,
  accesToken: string
): Promise<ITroubleCauser> => {
  try {
    const response = await request
      .post(`${backendUrl}/${troubleCauserRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
      .send(item)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const removeTroubleCauser = async (
  id: number,
  accessToken: string
): Promise<ITroubleCauser[]> => {
  try {
    const response = await request
      .del(`${backendUrl}/${troubleCauserRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateTroubleCauser = async (
  item: Partial<ITroubleCauser>,
  accessToken: string
): Promise<ITroubleCauser> => {
  const putUri = `${backendUrl}/${troubleCauserRoute}`

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    return response.body
  } catch (error: any) {
    return error.message
  }
}
