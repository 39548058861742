import * as request from 'superagent'
import { ITroubleshooterSearchSelection } from '../models/ITroubleshooterSearchSelection'
import { SearchReportResult } from 'src/common/interfaces/SearchResults/SearchReportResult'
import { Config } from 'src/common/config'
import { ApiResponse } from '../models/ApiResponse'
import { WarehouseEnum } from 'src/common/enums/Enum'

const backendUrl = Config.api.host
const troubleshooterRoute = 'TroubleShooterReport'

export const getReports = async (
  selection: ITroubleshooterSearchSelection,
  accessToken: string
): Promise<ApiResponse<SearchReportResult>> => {
  let urlSearchPart = WarehouseEnum[selection.location]

  if (selection.clock && selection.transaction) {
    urlSearchPart += `/searchoutboundordernumber/${selection.clock}/${selection.transaction}/${selection.auctionDeliveryDate}`
  }
  if (selection.lotNum && selection.lotNum.length > 0) {
    urlSearchPart += `/searchlotnumber/${selection.lotNum}`
  }
  if (selection.lpnSupply && selection.lpnSupply.length > 0) {
    urlSearchPart += `/searchlpn/${selection.lpnSupply}`
  }
  if (selection.deliveryNote && selection.deliveryNote.length > 0) {
    urlSearchPart += `/searchdeliverynote/${selection.deliveryNote}`
  }
  if (selection.orderNumber && selection.orderNumber.length > 0) {
    urlSearchPart += `/searchordernumber/${selection.orderNumber}`
  }

  const response: ApiResponse<SearchReportResult> = await fetchReports(
    accessToken,
    urlSearchPart
  )
  return response
}

const fetchReports = async (
  accessToken: string,
  urlSearchPart: string
): Promise<ApiResponse<SearchReportResult>> => {
  try {
    const response = await request
      .get(`${backendUrl}/${troubleshooterRoute}/${urlSearchPart}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    const newResponse: ApiResponse<SearchReportResult> = {
      body: response.body,
      httpStatus: response.statusCode,
      errorMessage: '',
    }
    return newResponse
  } catch (error: any) {
    //console.log(error)
    const newResponse: ApiResponse<SearchReportResult> = {
      httpStatus: error.status,
      errorMessage: error.message,
    }
    return newResponse
  }
}
