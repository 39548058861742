import * as request from 'superagent'
import { Config } from 'src/common/config'
import { IErrorCodeSearchSelection } from '../models/IErrorCodeSearchSelection'
import { IErrorCode } from '../models/IErrorCode'

const backendUrl = Config.api.host
const errorCodeRoute = 'ErrorCode'

export const getErrorCodes = async (
  selection: IErrorCodeSearchSelection,
  accesToken: string
): Promise<IErrorCode[]> => {
  try {
    const response = await request
      .get(
        `${backendUrl}/${errorCodeRoute}?code=${selection.errorCodeNumber}&omschrijving=${selection.description}&betekeniscode=${selection.errorDefinition}`
      )
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
    console.log({ response })
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const getErrorCode = async (
  id: number,
  accessToken: string
): Promise<IErrorCode> => {
  try {
    const response = await request
      .get(`${backendUrl}/${errorCodeRoute}/getDetail/${id}`)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('Content-Type', 'application/json')
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const addErrorCode = async (
  errorCode: IErrorCode,
  accesToken: string
): Promise<IErrorCode> => {
  try {
    const response = await request
      .post(`${backendUrl}/${errorCodeRoute}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accesToken)
      .send(errorCode)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const removeErrorCode = async (
  id: number,
  accessToken: string
): Promise<IErrorCode[]> => {
  try {
    const response = await request
      .del(`${backendUrl}/${errorCodeRoute}/${id}`)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
    return response.body
  } catch (error: any) {
    return error.message
  }
}

export const updateErrorCode = async (
  item: Partial<IErrorCode>,
  accessToken: string
): Promise<IErrorCode> => {
  const putUri = `${backendUrl}/${errorCodeRoute}`

  try {
    const response = await request
      .put(putUri)
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + accessToken)
      .send(item)
    return response.body
  } catch (error: any) {
    return error.message
  }
}
