/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/unbound-method */
import { createStyles, Table, Theme } from '@mui/material'
import { Button } from '@rfh/ui'
import { TextField } from '@rfh/ui'
import { useOktaAuth } from '@okta/okta-react'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IKlachtCode } from '../../models/IKlachtCode'
import KlachtCodeListItem from './components/KlachtCodeListItem'
import { ThemeConfig } from 'src/common/config'
import {
  getKlachtCodes,
  removeKlachtCode,
} from '../../service/klachtCodeService'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import withStyles, { WithStyles } from '@mui/styles/withStyles'

type ClassKey = 'cell' | 'root' | 'table' | 'error' | 'button'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
    error: {
      color: 'red',
    },
  })
type PropsType = WithStyles<ClassKey>

const KlachtCodeList: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const location = useLocation()
    const confirm = useConfirmation()
    const { register, handleSubmit } = useForm()
    const [errorMessage, setErrorMessage] = useState<string>()
    const { authState } = useOktaAuth()
    const [klachtCodes, setKlachtCodes] = useState<IKlachtCode[]>([])
    const [newModalOpen, setNewModalOpen] = useState(false)
    const [klachtCode, setKlachtCode] = useState(' ')
    const [klachtOmschrijving, setKlachtOmschrijving] = useState(' ')

    useEffect(() => {
      if (authState.isAuthenticated) {
        console.log(authState)
      }
      fetchData()
    }, [authState])

    const fetchData = async (): Promise<void> => {
      try {
        console.log('zoek data')
        console.log('code' + klachtCode)
        console.log('oms' + klachtOmschrijving)
        const result = await getKlachtCodes(
          authState.accessToken?.accessToken,
          klachtCode,
          klachtOmschrijving
        )
        console.log('result:')
        console.log(result)
        console.log({ result })
        setKlachtCodes(result)
      } catch (error) {
        console.log(error)
      }
    }

    const removeItem = async (id: number): Promise<void> => {
      try {
        await confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: 'Verwijderen',
          description: 'Bevestigen',
          okbuttontext: 'Bevestigd',
          cancelbuttontext: 'Annuleren',
        })
      } catch (error) {
        if (error) {
          console.log(error)
        }
        return
      }

      const removeData = async (klachtCodeCode: any): Promise<void> => {
        try {
          // Call to API to delete row from DB
          await removeKlachtCode(
            klachtCodeCode,
            authState.accessToken?.accessToken
          )
          fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      removeData(id)
    }

    const createNewItem = (): void => {
      if (isRoleApplicatieBeheer) {
        history.push(`${location.pathname}/${0}`)
      }
    }

    const gotoKlachtCode = (klachtCodeCode: number): void => {
      history.push(`${location.pathname}/${klachtCodeCode}`)
    }

    const zoekIfEnter = (ent: string) => {
      if (ent === 'Enter') {
        fetchData()
      }
    }

    const handleCodeChange = (code: string) => {
      if (parseInt(code, 10) > 0) {
        setKlachtCode(code)
      } else {
        setKlachtCode(' ')
      }
    }

    const handleOmschrijvingChange = (omschrijving: string) => {
      setKlachtOmschrijving(omschrijving)
    }

    const submitHandler = (evt: { preventDefault: () => void }) => {
      evt.preventDefault()
    }

    const handleClose = (): void => {
      fetchData()
    }

    const { classes } = props

    return (
      <div className={classes.root}>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <h2>Onderhouden Klachtcodes</h2>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.cell}>Klachtcode</td>
              <td className={classes.cell}>Klachtomschrijving</td>
            </tr>
            <tr onKeyUp={e => zoekIfEnter(e.key)}>
              <td className={classes.cell}>
                <TextField
                  type='tel'
                  inputProps={{ maxLength: 4 }}
                  value={klachtCode ?? ''}
                  onChange={e => handleCodeChange(e.target.value)}
                />
              </td>
              <td className={classes.cell}>
                <TextField
                  type='text'
                  inputProps={{ maxLength: 128 }}
                  value={klachtOmschrijving}
                  onChange={e => handleOmschrijvingChange(e.target.value)}
                />
              </td>
              <td className={classes.cell}>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={fetchData}
                  className={classes.button}
                >
                  Zoeken
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        <Table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.cell}>Klachtcode</th>
              <th className={classes.cell}>Klachtomschrijving</th>
              <th className={classes.cell}>Prioriteit</th>
            </tr>
          </thead>
          <tbody>
            {klachtCodes !== null &&
              klachtCodes.length &&
              Array.isArray(klachtCodes) &&
              klachtCodes?.map((klachtCode: IKlachtCode, index: number) => (
                <KlachtCodeListItem
                  key={index}
                  klachtCode={klachtCode}
                  classType={classes.cell}
                  gotoKlachtCode={gotoKlachtCode}
                  removeItem={removeItem}
                ></KlachtCodeListItem>
              ))}
            <tr>
              <td>
                {isRoleApplicatieBeheer && (
                  <Button
                    variant='contained'
                    className='btn btn-primary'
                    onClick={createNewItem}
                  >
                    Toevoegen
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
)

export default withStyles(styles)(KlachtCodeList)
