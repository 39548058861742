import { useState, ReactElement, FC, useEffect } from 'react'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner/'
import { ApiResponse } from 'src/modules/troubleshooterReport/models/ApiResponse'
import TicketReportSearch from './TicketReportSearch'
import { WarehouseEnum } from 'src/common/enums/Enum'
import OverviewSearchResults from '../components/TicketReportSearchResults'
import { ISeeker } from 'src/modules/seekers/models/ISeeker'
import { ISolutionType } from 'src/modules/tickets/models/ISolutionType'
import { getAllSolutionTypes } from 'src/modules/tickets/service/solutionTypeService'
import { getSeekers } from 'src/modules/seekers/service/seekerService'
import { getTickets } from '../service/ticketReportService'
import { ITicketReportSearchResult } from '../models/ITicketReportSearchResult'
import { ITicketReportSearchSelection } from '../models/ITicketReportSearchSelection'
import { IErrorObserverReport } from 'src/modules/tickets/models/IErrorObserverReport'
import { getErrorObservers } from 'src/modules/tickets/service/errorCausesService'

type ClassKey = 'cell' | 'root' | 'search' | 'divStyle' | 'loaderStyle'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    search: {
      margin: theme.spacing(ThemeConfig.spacing.small),
    },
    divStyle: {
      display: 'flex',
      flexDirection: 'column',
    },
    loaderStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
type PropsType = WithStyles<ClassKey>

const TicketReport: FC<PropsType> = (props: PropsType): ReactElement => {
  const { t } = useTranslation()
  const [searchResult, setSearchResult] = useState<ITicketReportSearchResult>({
    tickets: [],
  })
  const [printableVersion, setPrintableVersion] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [noresult, setNoResult] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [allSolutionTypes, setAllSolutionTypes] = useState<ISolutionType[]>([])
  const [allSeekers, setAllSeekers] = useState<ISeeker[]>([])
  const [allErrorObservers, setAllErrorCauseObservers] = useState<
    IErrorObserverReport[]
  >([])
  const [searchSelection, setSearchSelection] =
    useState<ITicketReportSearchSelection>({
      location: WarehouseEnum.Aalsmeer,
      complaintDate: '',
      complaintCode: undefined,
      errorCode: undefined,
      seekerCode: undefined,
      solutionTypeCode: '',
    })
  const { authState } = useOktaAuth()

  const { classes } = props

  useEffect(() => {
    async function getSelectionOptions() {
      await getSolutionTypes()
      await getAllSeekers()
      await getAllErrorObservers()
    }
    getSelectionOptions()
    if (printableVersion) {
      window.print()
      setPrintableVersion(false)
    }
  }, [printableVersion])

  const onSearch = async (selected: ITicketReportSearchSelection) => {
    setSearchSelection(selected)
    await refreshSearch(selected)
    setPrintableVersion(false)
  }

  const onClear = () => {
    setSearchResult({ tickets: [] })
    setPrintableVersion(false)
    setSearchSelection({
      location: WarehouseEnum.Aalsmeer,
      complaintDate: '',
      complaintCode: undefined,
      errorCode: undefined,
      seekerCode: undefined,
      solutionTypeCode: '',
    })
  }

  const onPrint = (requestPrint: boolean) => {
    setPrinterFriendly()
  }

  const setPrinterFriendly = () => {
    setPrintableVersion(true)
  }
  const refreshSearch = async (selected?: ITicketReportSearchSelection) => {
    setErrorMessage('')
    setIsLoading(true)
    setNoResult(false)
    setSearchResult({ tickets: [] })
    try {
      const searchResultsResponse: ApiResponse<ITicketReportSearchResult> =
        await getTickets(
          selected ?? searchSelection,
          authState.accessToken?.accessToken
        )
      if (
        searchResultsResponse.httpStatus >= 200 &&
        searchResultsResponse.httpStatus < 400 &&
        searchResultsResponse.body
      ) {
        const searchResults = searchResultsResponse.body
        //console.log(searchResults)
        setSearchResult(searchResults)
      } else if (searchResultsResponse.httpStatus === 404) {
        setNoResult(true)
      } else {
        setErrorMessage(
          `Er is een fout opgetreden - ${searchResultsResponse.httpStatus}`
        )
      }
    } catch (error: unknown) {
      const { message } = error as Error
    }
    setIsLoading(false)
  }

  const getSolutionTypes = async () => {
    const response = await getAllSolutionTypes(
      authState.accessToken?.accessToken
    )
    setAllSolutionTypes(response)
  }

  const getAllSeekers = async () => {
    const response = await getSeekers(
      authState.accessToken?.accessToken,
      ' ',
      true
    )
    setAllSeekers(response)
  }

  const getAllErrorObservers = async () => {
    const response = await getErrorObservers(authState.accessToken?.accessToken)
    setAllErrorCauseObservers(response)
  }

  const getReportSearchSelectionAsString = () => {
    return `${searchSelection.complaintDate}_${
      searchSelection.complaintCode ?? ''
    }_${searchSelection.location ?? ''}_${
      searchSelection.complaintStatus ?? ''
    }_${searchSelection.seekerCode ?? ''}`
  }

  return (
    <div className={classes.divStyle}>
      <h2>{t('ticketreport')}</h2>
      <TicketReportSearch
        onClickSelect={onSearch}
        onClickWissen={onClear}
        onClickPrint={onPrint}
        isPrinting={printableVersion}
        solutionTypes={allSolutionTypes}
        seekers={allSeekers}
        observers={allErrorObservers}
        searchData={searchResult.tickets}
        excelOptions={{
          fileName: getReportSearchSelectionAsString(),
          useObjectKeysAsHeader: true,
          autofitColumns: true,
          workSheetName: 'LasTickets',
        }}
      />
      {isLoading ? (
        <div className={classes.loaderStyle}>
          <TailSpin color='#3a4d41' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      {searchResult !== null &&
        searchResult.tickets !== undefined &&
        searchResult.tickets.length > 0 && (
          <OverviewSearchResults
            tickets={searchResult.tickets}
            printerFriendly={printableVersion}
            Refresh={refreshSearch}
          />
        )}
      {noresult && (
        <div>
          <h2>{t('noResults')}</h2>
        </div>
      )}
      {errorMessage && (
        <div>
          <h2>{errorMessage}</h2>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(TicketReport)
