/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/unbound-method */
import { colors, Table, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Backdrop, TextField as MuiTextField } from '@mui/material'
import { Button } from '@rfh/ui'
import { TextField } from '@rfh/ui'
import { WithStyles } from '@mui/styles'
import { useOktaAuth } from '@okta/okta-react'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IActiviteit } from '../../models/IActiviteit'
import ActiviteitListItem from './components/ActiviteitListItem'
import { ThemeConfig } from 'src/common/config'
import {
  getActiviteitById,
  getActiviteiten,
  removeActiviteit,
} from '../../service/activiteitService'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { Autocomplete } from '@mui/material'
import { TailSpin } from 'react-loader-spinner'

type ClassKey = 'cell' | 'root' | 'table' | 'error' | 'button' | 'autocomplete'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
    error: {
      color: 'red',
    },
    autocomplete: {
      background: colors.common.white,
      padding: theme.spacing(0, 0),
      margin: theme.spacing(1, 0, 0.5, 0),
      minHeight: theme.spacing(4),
      border: 'none',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
      width: '50%',
    },
  })
type PropsType = WithStyles<ClassKey>

const ActiviteitList: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const location = useLocation()
    const confirm = useConfirmation()
    const { register, handleSubmit } = useForm()
    const [errorMessage, setErrorMessage] = useState<string>()
    const { authState } = useOktaAuth()
    const [activiteiten, setActiviteiten] = useState<IActiviteit[]>([])
    const [allActivities, setAllActivities] = useState<IActiviteit[]>([])
    const [selectedActivity, setSelectedActivity] =
      useState<IActiviteit | null>(null)
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortField, setSortField] = useState('code')
    const [isLoading, setIsloading] = useState<boolean>(false)

    useEffect(() => {
      setIsloading(true)
      if (authState.isAuthenticated) {
        console.log(authState)
      }
      fetchData()
      setIsloading(false)
    }, [authState])

    useEffect(() => {
      async function setActivities() {
        const response = await getActiviteiten(
          authState.accessToken?.accessToken
        )
        setAllActivities(response)
      }
      setActivities()
    }, [])

    const fetchData = async (): Promise<void> => {
      try {
        console.log('fetch data')
        if (selectedActivity == null) {
          const result = await getActiviteiten(
            authState.accessToken?.accessToken
          )
          console.log('result:')
          console.log({ result })
          setActiviteiten(result)
        } else {
          const result = await getActiviteitById(
            authState.accessToken?.accessToken,
            selectedActivity.id
          )
          console.log('result:')
          console.log({ result })
          setActiviteiten([result])
        }
      } catch (error) {
        console.log(error)
      }
    }

    const removeItem = async (id: string): Promise<void> => {
      try {
        await confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: 'Verwijderen',
          description: 'Bevestigen',
          okbuttontext: 'Bevestigd',
          cancelbuttontext: 'Annuleren',
        })
      } catch (error) {
        if (error) {
          console.log(error)
        }
        return
      }

      const removeData = async (activiteitCode: any): Promise<void> => {
        try {
          // Call to API to delete row from DB
          await removeActiviteit(
            activiteitCode,
            authState.accessToken?.accessToken
          )
          fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      await removeData(id)
    }

    const createNewItem = (): void => {
      if (isRoleApplicatieBeheer) {
        history.push(`${location.pathname}/${0}`)
      }
    }

    const gotoActiviteit = (activiteitId: number): void => {
      history.push(`${location.pathname}/${activiteitId.toString()}`)
    }

    const zoekIfEnter = (ent: string) => {
      if (ent === 'Enter') {
        fetchData()
      }
    }

    const changeSorting = (field: string): void => {
      setSortField(field)
      sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc')
    }

    const activitysort = (a: IActiviteit, b: IActiviteit): number => {
      if (sortField === 'code') {
        if (a.code > b.code) {
          return sortOrder === 'asc' ? 1 : -1
        }
        if (a.code < b.code) {
          return sortOrder === 'asc' ? -1 : 1
        }
      }
      if (sortField === 'omschrijving') {
        if (a.omschrijving > b.omschrijving) {
          return sortOrder === 'asc' ? 1 : -1
        }
        if (a.omschrijving < b.omschrijving) {
          return sortOrder === 'asc' ? -1 : 1
        }
      }
      return 0
    }

    const { classes } = props

    return (
      <div className={classes.root}>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <TailSpin color='#3a4d41' height={100} width={100} />
        </Backdrop>
        <h2>Onderhouden Activiteiten</h2>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.cell}>Omschrijving</td>
            </tr>
            <tr onKeyUp={e => zoekIfEnter(e.key)}>
              <td className={classes.cell}>
                <Autocomplete
                  id='activitydescription'
                  options={allActivities}
                  getOptionLabel={option =>
                    option.omschrijving ? option.omschrijving : ''
                  }
                  onChange={(event: any, newValue: IActiviteit | null) => {
                    setSelectedActivity(newValue)
                  }}
                  fullWidth
                  value={selectedActivity}
                  className={classes.autocomplete}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={params => <TextField {...params} />}
                />
              </td>
              <td className={classes.cell}>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={fetchData}
                  className={classes.button}
                >
                  Zoeken
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        <Table className={classes.table}>
          <thead>
            <tr>
              <th
                className={classes.cell}
                onClick={() => changeSorting('code')}
                style={{ cursor: 'pointer' }}
              >
                Code
              </th>
              <th
                className={classes.cell}
                onClick={() => changeSorting('omschrijving')}
                style={{ cursor: 'pointer' }}
              >
                Omschrijving
              </th>
              <th className={classes.cell}>Organisatiekenmerk</th>
              <th className={classes.cell}>Uitvoerder</th>
              <th className={classes.cell}></th>
            </tr>
          </thead>
          <tbody>
            {activiteiten !== null &&
              activiteiten.length &&
              Array.isArray(activiteiten) &&
              activiteiten
                ?.sort(activitysort)
                .map((activiteit: IActiviteit, index: number) => (
                  <ActiviteitListItem
                    key={index}
                    activiteit={activiteit}
                    classType={classes.cell}
                    allActivities={allActivities}
                    gotoActiviteit={gotoActiviteit}
                    removeItem={removeItem}
                  ></ActiviteitListItem>
                ))}
            <tr>
              <td>
                {isRoleApplicatieBeheer && (
                  <Button
                    variant='contained'
                    className='btn btn-primary'
                    onClick={createNewItem}
                  >
                    Toevoegen
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
)

export default withStyles(styles)(ActiviteitList)
