import { useState, useContext, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ThemeConfig } from 'src/common/config'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { DialogContext } from 'src/modules/context/Context'
import TicketReportSearchResultsTable from './TicketReportSearchResultsTable'
import { ITicket } from '../../tickets/models/ITicket'
import { ITicketReportViewModel } from '../models/ITicketReportViewModel'
import {
  getTicketDetail,
  updateTicket,
} from 'src/modules/tickets/service/TicketService'
import { useOktaAuth } from '@okta/okta-react'

type Props = {
  tickets: ITicketReportViewModel[]
  printerFriendly: boolean
  Refresh: () => void
}

const useStyles = makeStyles({
  root: {
    flexGrow: ThemeConfig.spacing.xsmall,
  },
})

function TicketReportSearchResults(props: Props): JSX.Element {
  const { ticketId }: any = useParams()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { authState } = useOktaAuth()
  const dialogContext = useContext(DialogContext)
  const [selectedTicket, setTicket] = useState<ITicketReportViewModel>()
  const [popupEnabled, setPopupEnabled] = useState(false)

  const gotoItemDetails = (ticket?: ITicketReportViewModel) => {
    if (ticket != null && ticket.LasTicketId > 0) {
      setTicket(ticket)
      const step = ticket.statuscode !== undefined ? ticket.statuscode : 0

      const newTicketUrl = new URL(
        `https://${window.location.hostname}/v2/new-ticket`
      )
      newTicketUrl.searchParams.set(
        'ticketNumber',
        ticket.LasTicketId.toString()
      )
      newTicketUrl.searchParams.set(
        'ticketStatus',
        ticket.statuscode.toString()
      )
      if (ticket.lotnum) {
        newTicketUrl.searchParams.set('batchNumber', ticket.lotnum)
      }
      if (ticket.orderidreference) {
        newTicketUrl.searchParams.set('orderNumber', ticket.orderidreference)
      }
      if (ticket.orderpickidreference) {
        newTicketUrl.searchParams.set(
          'orderPickNumber',
          ticket.orderpickidreference
        )
      }
      if (step) {
        newTicketUrl.searchParams.set('step', String(step - 1))
      }
      newTicketUrl.searchParams.set(
        'callback',
        `https://${window.location.hostname}/ticketreport`
      )
      window.location.assign(newTicketUrl)
    }
  }

  const gotoOrderDetails = (ticket?: ITicketReportViewModel) => {
    if (ticket != null && ticket.LasTicketId > 0) {
      history.push(
        `troubleshooterreport/ordernumber/${ticket.orderidreference}`
      )
    }
  }

  /* Priority update List scherm  */

  const showPriorityDialog = (x: ITicketReportViewModel | undefined) => {
    setPopupEnabled(!popupEnabled)
  }

  const changePrioTicket = async (x: ITicketReportViewModel | undefined) => {
    if (x !== undefined) {
      await updateTicketPriority(x?.LasTicketId, x?.priority)
      setPopupEnabled(!popupEnabled)
      props.Refresh()
    }
  }

  const updateTicketPriority = async (
    id: number,
    priority: number
  ): Promise<void> => {
    try {
      await getTicketDetail(id, authState.accessToken?.accessToken).then(
        result => {
          setAndUpdateTicketDetail(result, priority)
        }
      )
    } catch (error) {
      console.log(error)
    }
    return
  }

  const setAndUpdateTicketDetail = async (
    ticket: ITicket,
    priority: number
  ): Promise<boolean> => {
    try {
      if (priority > 0) {
        ticket.priority = priority
        if (ticket.errorcode > 0 && ticket.errorcauseid === 0) {
          return false
        }
        const res = await updateTicket(
          ticket,
          authState.accessToken?.accessToken
        )
        return true
      }
    } catch (error) {
      console.log(error)
    }
    return false
  }

  /* Einde Priority update List scherm */

  /*
  Effects
  */
  useEffect(() => {
    dialogContext.setDialogOpen(!!ticketId)
  }, [ticketId])

  return (
    <div className={classes.root}>
      <h2>{t('searchResults')}</h2>
      {props.tickets.length > 0 && (
        <TicketReportSearchResultsTable
          tickets={props.tickets}
          onClickItem={x => gotoItemDetails(x)}
          onClickOrder={x => gotoOrderDetails(x)}
          onClickPriority={x => showPriorityDialog(x)}
          printableVersion={props.printerFriendly}
          popupEnabled={popupEnabled}
          changePrioTicket={x => changePrioTicket(x)}
          refresh={props.Refresh}
        />
      )}
    </div>
  )
}

export default TicketReportSearchResults
