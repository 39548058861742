import { FC, ReactElement } from 'react'
import { Theme, TableRow } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { IErrorCode } from '../models/IErrorCode'
import { Button } from '@rfh/ui'
import { ErrorMeaningEnum } from 'src/common/enums/Enum'

interface IProps {
  errorCode: IErrorCode
  classType: string
  redirectToErrorCodeData: (errorCode: number) => void
}

type ClassKey = 'root' | 'cell' | 'button'
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cell: {
      textAlign: 'left',
      padding: '2px',
    },
    button: {
      float: 'right',
      padding: '4px',
      marginRight: '10px',
    },
  })

type PropsType = IProps & WithStyles<ClassKey>

const ErrorCodeListItem: FC<PropsType> = (props: PropsType): ReactElement => {
  const errorCode = props.errorCode
  const classType = props.classType
  const { classes } = props

  return (
    <TableRow key={errorCode.foutCode1}>
      <td className={classType}>{errorCode.foutCode1}</td>
      <td className={classType}>{errorCode.foutOmschrijving}</td>
      <td className={classType}>{ErrorMeaningEnum[errorCode.foutBetekenis]}</td>
      <td className={classes.cell}>
        <Button
          variant='contained'
          type='submit'
          onClick={(): void =>
            props.redirectToErrorCodeData(errorCode.foutCode1)
          }
          className={classes.button}
        >
          Detail
        </Button>
      </td>
    </TableRow>
  )
}

export default withStyles(styles)(ErrorCodeListItem)
