import { FC, useEffect, useState } from 'react'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import TableRow from '@mui/material/TableRow'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import ConstructionIcon from '@mui/icons-material/Construction'
import DangerousIcon from '@mui/icons-material/Dangerous'
import CheckIcon from '@mui/icons-material/Check'
import { StyledTableCellB } from './TicketReportSearchResultsTable'
import { ITicketReportViewModel } from '../models/ITicketReportViewModel'
import { TableCell } from '@mui/material'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import EditPriorityDialog from 'src/modules/troubleshooterReport/views/troubleshooter-list/EditPriorityDialog'
type MyFnType = (ticket?: ITicketReportViewModel) => void

interface IProps {
  ticket: ITicketReportViewModel
  printableVersion: boolean
  popupEnabled: boolean
  onClickItem: MyFnType
  onClickOrder: MyFnType
  onClickPriority: MyFnType
  changePrioTicket: MyFnType
  refresh: () => void
}

const explanationVisibleMaxLength = 128

const StyledTableRowA = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const StyledTableCellB2 = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#0CC988',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      padding: 5,
      fontSize: 12,
      verticalAlign: 'top',
    },
    body: {
      padding: 5,
      paddingTop: 0,
      fontSize: 12,
      verticalAlign: 'top',
    },
  })
)(TableCell)

const StyledTableRowB = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

const styleStateCell = (state: string, priorityAllowed: boolean) => {
  if (state === '1') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#ff0000',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#ff0000',
      }
    }
  }
  if (state === '2') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#FFD800',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#FFD800',
      }
    }
  }
  if (state === '3') {
    if (priorityAllowed) {
      return {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#00ff00',
      }
    } else {
      return {
        textAlign: 'center',
        backgroundColor: '#00ff00',
      }
    }
  }
  return {}
}

const getPrinterStateIcon = (state: string) => {
  if (state === '1') return <ReportGmailerrorredIcon />
  if (state === '2') return <ConstructionIcon />
  if (state === '3') return <CheckIcon />
  return <DangerousIcon />
}

const getCroppedExplanation = (explanation: string): string => {
  const croppedString = explanation
    .substring(0, explanationVisibleMaxLength)
    .concat('...')
  return croppedString
}

const getDamagesString = (damages: number | null): string => {
  if (damages == null) {
    return ''
  }
  console.log(damages.toString())
  let damagesString = damages.toString()
  const splitDamages = damagesString.split('.')
  if (splitDamages.length === 1) {
    damagesString = '€ ' + damages + ',-'
  } else if (splitDamages.length === 2) {
    if (splitDamages[1].length < 2) {
      damagesString = '€ ' + splitDamages[0] + ',' + splitDamages[1] + '0'
    } else {
      damagesString = '€ ' + splitDamages[0] + ',' + splitDamages[1]
    }
  }
  return damagesString
}

type PropsType = IProps

const TicketReportSearchResultItem: FC<PropsType> = observer(
  (props: PropsType) => {
    const { t } = useTranslation()
    const { isRoleApplicatieBeheer, isRoleCoordinator } = useAccountStore()

    const [priorityChangeAllowed, setPriorityChangeAllowed] = useState(false)
    const [newPrioTicket, setNewPrioTicket] = useState(0)
    const [prioTicketId, setPrioTicketid] = useState(0)
    const [popupEnabled, setPopupEnabled] = useState(false)

    const handleEditPriorityClose = () => {
      setPrioTicketid(0)
      setNewPrioTicket(0)
      setPopupEnabled(false)
      props.refresh()
    }

    const handlePriorityClick = () => {
      if (!props.popupEnabled && priorityChangeAllowed) {
        setPrioTicketid(props.ticket.LasTicketId)
        props.onClickPriority(props.ticket)
        setNewPrioTicket(props.ticket.priority)
        setPopupEnabled(true)
      }
    }

    useEffect(() => {
      if (isRoleApplicatieBeheer || isRoleCoordinator) {
        setPriorityChangeAllowed(true)
      }
    })

    return (
      <>
        <StyledTableRowA key={props.ticket?.LasTicketId}>
          <>
            {!props.printableVersion && (
              <StyledTableCellB
                onClick={handlePriorityClick}
                rowSpan={2}
                style={styleStateCell(
                  props.ticket.statuscode.toString(),
                  priorityChangeAllowed
                )}
              >
                {props.ticket.priority}
                <EditPriorityDialog
                  priority={newPrioTicket}
                  lasTicketId={prioTicketId}
                  handleEditPriorityClose={handleEditPriorityClose}
                  dialogOpen={popupEnabled}
                ></EditPriorityDialog>
              </StyledTableCellB>
            )}

            {props.printableVersion && (
              <StyledTableCellB rowSpan={1}>
                {getPrinterStateIcon(props.ticket.statuscode.toString())}
              </StyledTableCellB>
            )}
            <StyledTableCellB
              onClick={(): void => props.onClickItem(props.ticket)}
              style={{ cursor: 'pointer' }}
            >
              {props.ticket.errorcodedescription != null &&
              props.ticket.errorcodedescription.length > 0
                ? props.ticket.errorcodedescription
                : props.ticket.complaintdescription}{' '}
              <br /> {props.ticket.operatorname}
            </StyledTableCellB>
            <StyledTableCellB
              onClick={(): void => props.onClickItem(props.ticket)}
              style={{ cursor: 'pointer' }}
            >
              {props.ticket.activitydescription} <br />
              {props.ticket.submittedtime}
            </StyledTableCellB>
          </>
          <StyledTableCellB
            onClick={(): void => props.onClickOrder(props.ticket)}
            style={{ cursor: 'pointer' }}
          >
            {props.ticket.customerckn} / {props.ticket.customername} <br />{' '}
            <span>
              {props.ticket.orderidreference} +{' '}
              {props.ticket.orderpickidreference}{' '}
            </span>{' '}
            <br />
          </StyledTableCellB>
          <StyledTableCellB>
            {props.ticket.productdescription} <br />
            {props.ticket.lotnum}
          </StyledTableCellB>
          <StyledTableCellB>
            {props.ticket.situationshouldbe}
            <br />
            {props.ticket.productcode}
          </StyledTableCellB>
          <StyledTableCellB>
            {props.ticket.situationwas}
            <br />
            {props.ticket.solvedtypedescription}
          </StyledTableCellB>
          <StyledTableCellB>
            {props.ticket.employee > 0 ? props.ticket.employee : ''}
            <br />
            {props.ticket.causedby}
          </StyledTableCellB>
          <StyledTableCellB>
            {getDamagesString(props.ticket.damages)}
            <br />
            {props.ticket.solutiontypedescription}
          </StyledTableCellB>
        </StyledTableRowA>
        <StyledTableRowB>
          {props.printableVersion && (
            <StyledTableCellB2 rowSpan={1}></StyledTableCellB2>
          )}
          <StyledTableCellB2>{props.ticket.seekername}</StyledTableCellB2>
          <StyledTableCellB2>{props.ticket.lateshipmentTime}</StyledTableCellB2>
          <StyledTableCellB2 colSpan={6}>
            {props.ticket.explanation.length > explanationVisibleMaxLength
              ? getCroppedExplanation(props.ticket.explanation)
              : props.ticket.explanation}
          </StyledTableCellB2>
        </StyledTableRowB>
        {/* empty TableRow below is needed to preserve background color for even-odd rows, in StyledTableRowA */}
        <TableRow></TableRow>
      </>
    )
  }
)

export default TicketReportSearchResultItem
