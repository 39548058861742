import { Box, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container } from '@rfh/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { FormMode } from 'src/common/enums'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IErrorCode } from '../models/IErrorCode'
import {
  getErrorCode,
  removeErrorCode,
  updateErrorCode,
  addErrorCode,
} from '../service/errorCodeService'
import { ThemeConfig } from 'src/common/config'
import { ErrorCodeDetailFields } from '../components/ErrorCodeDetailFields'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'

interface RouteParams {
  id: string
}

type ClassKey = 'root' | 'error'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: theme.spacing(ThemeConfig.spacing.xsmall),
      marginTop: theme.spacing(ThemeConfig.spacing.small),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    error: {
      color: 'red',
    },
  })

const ErrorCodeDetail: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const params = useParams<RouteParams>()
    const confirm = useConfirmation()
    const { id } = params
    const { authState } = useOktaAuth()
    const [detailMode, setMode] = useState(FormMode.edit)
    const formMethods = useForm<IErrorCode>()
    const [errorMessage, setErrorMessage] = useState('')
    const { classes } = props

    useEffect(() => {
      console.log('useEffect detail')
      console.log('detail fetch ' + id)
      if (!isRoleApplicatieBeheer) {
        setMode(FormMode.view)
      } else if (id === '0') {
        setMode(FormMode.add)
      }
      fetchData(parseInt(id, 10))
    }, [authState])

    const onClickRemoveItem = async (): Promise<void> => {
      const removeData = async (errorCode: number): Promise<boolean> => {
        let deleteSucceeded = true
        try {
          const value = await removeErrorCode(
            errorCode,
            authState.accessToken?.accessToken
          )
          console.log(JSON.stringify(value))
          if (value != null) {
            setErrorMessage('Delete is ongeldig: ' + value)
            deleteSucceeded = false
          }
        } catch (error) {
          console.log(error)
          setErrorMessage('Fout opgetreden tijdens delete: ' + error)
          deleteSucceeded = false
        }
        return deleteSucceeded
      }

      if (id) {
        try {
          await confirm({
            variant: 'danger',
            catchOnCancel: true,
            title: 'Weet u zeker dat u dit wilt verwijderen?',
            description: 'Deze actie kan niet ongedaan gemaakt worden!',
            okbuttontext: 'Ok',
            cancelbuttontext: 'Annuleer',
          })
        } catch (error) {
          if (error) {
            console.log(error)
          }
          return
        }

        const deleSucceeded = await removeData(parseInt(id, 10))
        if (deleSucceeded) {
          history.push('/errorcodes')
        }
      }
    }

    const onClickSubmit = (errorCode: IErrorCode): void => {
      console.log({ errorCode })
      if (id === '0') {
        addData(errorCode)
      } else {
        updateData(errorCode)
      }
    }

    const onClickCancel = (): void => {
      history.goBack()
    }

    const updateData = async (ErrorCode: IErrorCode): Promise<void> => {
      try {
        await updateErrorCode(ErrorCode, authState.accessToken?.accessToken)
        fetchData(parseInt(id, 10))
      } catch (error) {
        console.log(error)
      }
      history.push('/errorcodes')
    }

    const addData = async (errorCode: IErrorCode) => {
      try {
        await addErrorCode(errorCode, authState.accessToken?.accessToken).then(
          value => {
            console.log(value)
            if (value === null) {
              history.goBack()
            } else {
              if (value.foutCode1 !== undefined) {
                history.goBack()
              } else {
                setErrorMessage('Insert is ongeldig: ' + value)
              }
            }
          },
          reason => {
            console.error(reason) // Error!
          }
        )
      } catch (error) {
        setErrorMessage('insert ongeldig')
        console.log(error)
      }
    }

    const fetchData = async (ErrorCodeId: number): Promise<void> => {
      try {
        console.log('fetchdata :' + ErrorCodeId)
        const result: IErrorCode = await getErrorCode(
          ErrorCodeId,
          authState.accessToken?.accessToken
        )
        formMethods.reset(result)
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <Container>
        <Typography variant='h2' gutterBottom>
          Foutcode Details
        </Typography>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <FormContext {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onClickSubmit)}>
            <ErrorCodeDetailFields mode={detailMode} />
            <Box mt={3}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                xs={3}
                spacing={1}
                className={classes.root}
              >
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={detailMode === FormMode.view}
                    fullWidth
                    onClick={(): any => formMethods.handleSubmit(onClickSubmit)}
                  >
                    Opslaan
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    disabled={detailMode === FormMode.view}
                    fullWidth
                    onClick={onClickRemoveItem}
                  >
                    Verwijder
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    disabled={detailMode === FormMode.view}
                    fullWidth
                    onClick={onClickCancel}
                  >
                    Annuleer
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormContext>
      </Container>
    )
  }
)

export default withStyles(styles)(ErrorCodeDetail)
