/* eslint-disable */
import { Home } from '@mui/icons-material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { RfhBottomNavigation } from '@rfh/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  bottomNav: {
    display: 'flex',
  },
  [`@media print`]: {
    bottomNav: {
      display: 'none',
    },
  },
}))

export const BottomNavigation: FC = (): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleChange = (_: any, v: { id: string }) => {
    history.push(v.id === 'home' ? '/' : '/' + v.id)
  }

  const classes = useStyles()

  const items = [
    {
      id: 'home',
      label: t('home'),
      icon: <Home />,
    },
  ]

  return (
    <div className={classes.bottomNav}>
      <RfhBottomNavigation
        navigationItems={items.map((v: any) => ({
          ...v,
          label: t(v.id),
        }))}
        onChange={handleChange}
      />
    </div>
  )
}

export default withRouter(BottomNavigation)
