import { Grid } from '@mui/material'
import { Autocomplete, TextField as MuiTextField } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { FormMode } from 'src/common/enums'
import {
  ActivityOrganisationIdentifierEnum,
  ActivityOrganisationIdentifiers,
  ActivityPerformerEnum,
  ActivityPerformers,
} from 'src/common/enums/Enum'

type HandleActivityOrganisationIdentifierChangeFunctionType = (
  newInputValue: ActivityOrganisationIdentifierEnum | null
) => void
type HandleActivityPerformerChangeFunctionType = (
  newInputValue: ActivityPerformerEnum | null
) => void
type HandleStringChangeFunctionType = (
  e: React.ChangeEvent<HTMLInputElement>
) => void

interface IProps {
  mode: FormMode
  activityCode: string
  activityDescription: string
  activityOrganisationIdentifier: ActivityOrganisationIdentifierEnum | null
  activityPerformer: ActivityPerformerEnum | null
  classes: any
  handleActivityCodeChange: HandleStringChangeFunctionType
  handleActivityDescriptionChange: HandleStringChangeFunctionType
  handleActivityOrganisationIdentifierChange: HandleActivityOrganisationIdentifierChangeFunctionType
  handleActivityPerformerChange: HandleActivityPerformerChangeFunctionType
}
type PropsType = IProps

export const ActiviteitDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { mode } = props

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          Code*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Omschrijving*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Organisatiekenmerk*
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          Uitvoerder*
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            type='string'
            inputProps={{ maxLength: 10 }}
            placeholder=''
            value={props.activityCode}
            required={true}
            disabled={mode !== FormMode.add}
            fullWidth
            onChange={props.handleActivityCodeChange}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            color='primary'
            type='string'
            inputProps={{ maxLength: 32 }}
            required={true}
            value={props.activityDescription}
            disabled={mode === FormMode.view}
            fullWidth
            onChange={props.handleActivityDescriptionChange}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={ActivityOrganisationIdentifiers}
            onChange={(_event, newInputValue) =>
              props.handleActivityOrganisationIdentifierChange(newInputValue)
            }
            getOptionLabel={option =>
              ActivityOrganisationIdentifierEnum[option]
                ? ActivityOrganisationIdentifierEnum[option]
                : ''
            }
            fullWidth
            value={props.activityOrganisationIdentifier}
            className={props.classes.autocomplete}
            renderInput={params => <TextField {...params} required />}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Autocomplete
            options={ActivityPerformers}
            onChange={(_event, newInputValue) =>
              props.handleActivityPerformerChange(newInputValue)
            }
            getOptionLabel={option =>
              ActivityPerformerEnum[option] ? ActivityPerformerEnum[option] : ''
            }
            fullWidth
            value={props.activityPerformer}
            className={props.classes.autocomplete}
            renderInput={params => <TextField {...params} required />}
          />
        </Grid>
      </Grid>
    </>
  )
}
