import jwt_decode from 'jwt-decode'

import { useEffect } from 'react'

import { useOktaAuth } from '@okta/okta-react'
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext'

import { useAccountStore } from '../providers/AccountStore'

export const useOktaAuthEffect = (): IOktaContext => {
  const accountStore = useAccountStore()
  const { oktaAuth, authState } = useOktaAuth()

  const storeUserInformation = async () => {
    if (authState?.isAuthenticated) {
      const user = await oktaAuth.getUser()

      const decoded = jwt_decode(
        authState.accessToken?.accessToken as string
      ) as any
      const decodedKeys: string[] = Object.keys(decoded as any)
      const permittedScopes: string[] = decodedKeys.filter(
        item => decoded[item] === 'true'
      )

      accountStore.setUser(user)
      accountStore.setAuthState(authState)
      accountStore.setPermittedScopes(permittedScopes)
    }
  }

  useEffect(() => {
    storeUserInformation()
  }, [oktaAuth, authState])

  return useOktaAuth()
}
