import { Box, Button, Container, Grid, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FC, ReactElement, useEffect, useState } from 'react'
import { ThemeConfig } from 'src/common/config'
import { ISeeker } from '../models/ISeeker'
import {
  createNewSeeker,
  deleteSeeker,
  getSeeker,
  updateSeeker,
} from '../service/seekerService'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory, useParams } from 'react-router'
import { SeekerDetailFields } from '../components/SeekerDetailFields'
import { FormMode } from 'src/common/enums'
import { FormContext, useForm } from 'react-hook-form'
import { useConfirmation } from 'src/common/hooks/useConfirmation'

interface RouteParams {
  seekerId: string
}

type ClassKey = 'cell' | 'root' | 'table' | 'buttonGroup' | 'error'
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      padding: '20px',
    },
    buttonGroup: {
      flexGrow: theme.spacing(ThemeConfig.spacing.xsmall),
      marginTop: theme.spacing(ThemeConfig.spacing.small),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    error: {
      color: 'red',
    },
  })

type PropsType = WithStyles<ClassKey>

const SeekerDetail: FC<PropsType> = (props: PropsType): ReactElement => {
  const { classes } = props
  const { authState } = useOktaAuth()
  const params = useParams<RouteParams>()
  const { seekerId } = params
  const [seeker, setSeeker] = useState<ISeeker>()
  const formMethods = useForm<ISeeker>()
  const confirm = useConfirmation()
  const [errorMessage, setErrorMessage] = useState('')
  const [formMode, setFormMode] = useState(FormMode.edit)
  const history = useHistory()

  const getSeekerAsync = async (seekerIdParam: number) => {
    const seekerResult = await getSeeker(
      authState.accessToken?.accessToken,
      seekerIdParam
    )
    formMethods.reset(seekerResult)
    setSeeker(seekerResult)
  }

  useEffect(() => {
    if (Number(seekerId) === 0) {
      setFormMode(FormMode.add)
    } else {
      getSeekerAsync(Number(seekerId))
      setFormMode(FormMode.edit)
    }
  }, [authState])

  const updateSeekerAsync = async (seekerParam: ISeeker): Promise<void> => {
    await updateSeeker(
      authState.accessToken?.accessToken,
      Number(seekerId),
      seekerParam
    ).then(
      () => {
        history.goBack()
      },
      async err => {
        setErrorMessage(err.message)
        await getSeekerAsync(Number(seekerId))
      }
    )
  }

  const createSeekerAsync = async (seekerParam: ISeeker): Promise<void> => {
    await createNewSeeker(authState.accessToken?.accessToken, seekerParam).then(
      () => {
        history.goBack()
      },
      err => {
        setErrorMessage(err.message)
        formMethods.reset(seekerParam)
      }
    )
  }

  const onSubmit = async (seekerParam: ISeeker): Promise<void> => {
    const tmpSeeker = seekerParam
    tmpSeeker.id = Number(tmpSeeker.id)
    if (tmpSeeker.id <= 0) {
      setErrorMessage('Medewerkernummer moet hoger dan 0 zijn.')
      return
    }
    if (formMode === FormMode.edit) {
      await updateSeekerAsync(tmpSeeker)
    } else if (formMode === FormMode.add) {
      await createSeekerAsync(tmpSeeker)
    }
  }

  const onDelete = async (): Promise<void> => {
    const removeSeeker = async (seekerIdParam: number): Promise<void> => {
      await deleteSeeker(
        authState.accessToken?.accessToken,
        seekerIdParam
      ).then(
        () => {
          history.goBack()
        },
        err => {
          setErrorMessage(err.message)
          formMethods.reset(seeker)
        }
      )
    }

    if (seekerId) {
      try {
        await confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: `Weet u zeker dat u zoeker met id ${seekerId} wilt verwijderen?`,
          description: 'Deze actie kan niet ongedaan gemaakt worden!',
          okbuttontext: 'Verwijderen',
          cancelbuttontext: 'Niet verwijderen',
        })
      } catch (error) {
        if (error) {
          setErrorMessage('verwijderen geannuleerd')
        }
        return
      }
      await removeSeeker(Number(seekerId))
    }
  }

  return (
    <Container>
      <Typography variant='h2' gutterBottom>
        Zoekers Details
      </Typography>
      {errorMessage !== '' && (
        <div className={classes.error}>{errorMessage}</div>
      )}
      <FormContext {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <SeekerDetailFields mode={formMode} />
          <Box mt={3}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              xs={3}
              spacing={1}
              className={classes.buttonGroup}
            >
              <Grid item xs={12}>
                <Button variant='contained' fullWidth type='submit'>
                  Opslaan
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={onDelete}
                  disabled={formMode === FormMode.add}
                >
                  Verwijderen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormContext>
    </Container>
  )
}

export default withStyles(styles)(SeekerDetail)
