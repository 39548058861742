import { useState, ReactElement, FC, useEffect } from 'react'
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { WithStyles } from '@mui/styles'
import { ThemeConfig } from 'src/common/config'
import { IErrorCodeSearchSelection } from '../models/IErrorCodeSearchSelection'
import { IErrorCode } from '../models/IErrorCode'
import { useOktaAuth } from '@okta/okta-react'
import { getErrorCodes } from '../service/errorCodeService'
import { Button } from '@rfh/ui'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField } from '@rfh/ui'
import ErrorCodeListItem from '../components/ErrorCodeListItem'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import { ErrorMeaningEnum, Errormeanings } from 'src/common/enums/Enum'
type ClassKey = 'cell' | 'root' | 'table' | 'error' | 'button'
type PropsType = WithStyles<ClassKey>
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: ThemeConfig.spacing.xsmall,
    },
    cell: {
      textAlign: 'left',
      padding: '6px',
    },
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
    table: {
      marginTop: '30px',
      marginBottom: '50px',
      border: '#A9A9A9',
      borderStyle: 'solid',
      padding: '20px',
    },
    error: {
      color: 'red',
    },
  })

const ErrorCodeList: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const history = useHistory()
    const location = useLocation()
    const { authState } = useOktaAuth()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [selectedNumber, setSelectedNumber] = useState<number>()
    const [selectedDescription, setSelectedDescription] = useState<string>()
    const [selectedMeaning, setSelectedMeaning] = useState<
      ErrorMeaningEnum | string
    >('')
    const [errorCodes, setErrorCodes] = useState<IErrorCode[]>([])

    const { classes } = props

    useEffect(() => {
      if (authState.isAuthenticated) {
        console.log(authState)
      }
      onClickFetchData()
    }, [authState])

    const onClickFetchData = async (): Promise<void> => {
      try {
        console.log('zoek data')
        const searchCode: string =
          selectedNumber !== undefined ? selectedNumber.toString() : ' '
        const searchDescription: string =
          selectedDescription !== undefined ? selectedDescription : ' '
        const searchErrorDefinition: string =
          selectedMeaning === undefined
            ? ''
            : ErrorMeaningEnum[selectedMeaning] ?? ''
        console.log('code: ' + searchCode)
        console.log('oms: ' + searchDescription)
        console.log('def: ' + searchErrorDefinition)

        const selection: IErrorCodeSearchSelection = {
          errorCodeNumber: searchCode,
          description: searchDescription,
          errorDefinition: searchErrorDefinition,
        }

        const result = await getErrorCodes(
          selection,
          authState.accessToken?.accessToken
        )
        console.log('result:')
        console.log(result)
        console.log({ result })
        setErrorCodes(result)
      } catch (error) {
        console.log(error)
      }
    }

    const onClickCreateNewItem = (): void => {
      if (isRoleApplicatieBeheer) {
        history.push(`${location.pathname}/${0}`)
      }
    }

    const onKeyboardButtonPressed = (e: string) => {
      if (e === 'Enter') {
        onClickFetchData()
      }
    }

    const onErrorCodeNumberChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (e.target.value != null && isNaN(Number(e.target.value))) {
        return
      }
      let numberValue: number | undefined = Number(e.target.value)
      if (numberValue < 1) {
        numberValue = undefined
      }
      setSelectedNumber(numberValue)
    }

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDescription(e.target.value)
    }

    const onErrorDefinitionChanged = (
      selectChangedEvent: SelectChangeEvent<ErrorMeaningEnum>
    ) => {
      setSelectedMeaning(selectChangedEvent.target.value as ErrorMeaningEnum)
    }

    const redirectToErrorCode = (errorCode: number): void => {
      history.push(`${location.pathname}/${errorCode}`)
    }

    return (
      <div className={classes.root}>
        {errorMessage !== '' && (
          <div className={classes.error}>{errorMessage}</div>
        )}
        <h2>Onderhouden Foutcodes</h2>
        <Table className={classes.table}>
          <thead></thead>
          <tbody>
            <tr>
              <td className={classes.cell}>Foutcode</td>
              <td className={classes.cell}>Foutomschrijving</td>
              <td className={classes.cell}>Foutbetekenis</td>
            </tr>
            <tr onKeyUp={e => onKeyboardButtonPressed(e.key)}>
              <td className={classes.cell}>
                <TextField
                  sx={{ minWidth: '230px' }}
                  type='tel'
                  inputProps={{ maxLength: 3 }}
                  value={selectedNumber ?? ''}
                  onChange={onErrorCodeNumberChange}
                />
              </td>
              <td className={classes.cell}>
                <TextField
                  sx={{ minWidth: '305px' }}
                  type='text'
                  inputProps={{ maxLength: 128 }}
                  value={selectedDescription}
                  onChange={onDescriptionChange}
                />
              </td>
              <td className={classes.cell}>
                <Select
                  sx={{
                    border: 1,
                    borderColor: '#cccccc',
                    minWidth: '225px',
                  }}
                  labelId='foutbetekenis-label'
                  id='foutbetekenis-select'
                  value={selectedMeaning as ErrorMeaningEnum}
                  label='Betekenis'
                  onChange={onErrorDefinitionChanged}
                  variant='filled'
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value='' sx={{ textTransform: 'none' }}>
                    Alle
                  </MenuItem>
                  {Errormeanings.map(key => (
                    <MenuItem
                      value={ErrorMeaningEnum[key]}
                      sx={{ textTransform: 'none' }}
                    >
                      {ErrorMeaningEnum[key]}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <td className={classes.cell}>
                <Button
                  sx={{ minWidth: '120px' }}
                  variant='contained'
                  type='submit'
                  onClick={onClickFetchData}
                  className={classes.button}
                >
                  Zoeken
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>

        <Table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.cell}>Foutcode</th>
              <th className={classes.cell}>Foutomschrijving</th>
              <th className={classes.cell}>Foutbetekenis</th>
              <th className={classes.cell}></th>
            </tr>
          </thead>
          <tbody>
            {errorCodes !== null &&
              errorCodes.length &&
              Array.isArray(errorCodes) &&
              errorCodes?.map((errorCode: IErrorCode, index: number) => (
                <ErrorCodeListItem
                  key={index}
                  errorCode={errorCode}
                  classType={classes.cell}
                  redirectToErrorCodeData={redirectToErrorCode}
                ></ErrorCodeListItem>
              ))}
            <tr>
              <td>
                {isRoleApplicatieBeheer && (
                  <Button
                    variant='contained'
                    className='btn btn-primary'
                    onClick={onClickCreateNewItem}
                  >
                    Toevoegen
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
)

export default withStyles(styles)(ErrorCodeList)
