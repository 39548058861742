import { Button, createStyles, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { ReactElement, FC, useState, useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { ExcelOptions } from '../ExcelOptions'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'

type ExcelProperties = {
  excelData: any[]
  excelOptions: Partial<ExcelOptions>
  workSheetName: string
  fileName: string
  creatorName: string
  useObjectKeysAsHeader: boolean
  autofitColumns: boolean
  useCSVFormat: boolean
}

type ClassKey = 'button'

const styles = (theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      padding: '6px',
      margin: '10px',
    },
  })
type PropsType = WithStyles<ClassKey>

type ExcelProps = ExcelProperties & PropsType

const ExcelComponent: FC<Partial<ExcelProps>> = (
  props: Partial<ExcelProps>
): ReactElement => {
  const { t } = useTranslation()
  const [hasExcelData, setHasExcelData] = useState<boolean>()
  const [headerData, setHeaderData] = useState<string[]>()
  const [excelData, setExcelData] = useState<any[]>()
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  useEffect(() => {
    const newData: any[] = cleanCSVData(props?.excelData ?? [])
    setHasExcelData(newData.length > 0)
    setExcelData(newData)
    if (props.excelData && props.excelData.length >= 0) {
      const firstRow = props.excelData[0]
      if (firstRow) {
        const headers = Object.keys(firstRow)
        setHeaderData(headers)
      }
    }
  }, [props.excelData])

  const cleanCSVData = (source: any[]): any[] => {
    const regexp = new RegExp('(\\r\\n|\\r|\\n)', 'g')
    return source.map(val => {
      const values = Object.values(val)
      for (const key in values) {
        if (key) {
          let valueKey = values[key]
          if (!valueKey) {
            continue
          }
          if (Array.isArray(valueKey)) {
            values[key] = JSON.stringify(valueKey)
            continue
          }
          if (typeof valueKey === 'string') {
            //Date doesn't contains years, so keep it and let excel resolve that.
            const date = Date.parse(valueKey)
            if (!isNaN(date)) {
              continue
            }
            //Check if value contains newline and if so, replace the newline with an empty space
            if (regexp.test(valueKey)) {
              valueKey = valueKey.replaceAll(regexp, ' ')
            }
            //Clean data, so that numbers like 12345676878123 are shown as-is and not 12E+12
            values[key] = `"="${valueKey}`
          }
        }
      }
      return values
    })
  }

  const generateAndDownloadCSV = () => {
    if (csvLink.current) {
      csvLink.current.link.click()
    }
  }

  return (
    <div>
      {!props.useCSVFormat && (
        <Button variant='contained' type='button' disabled={!hasExcelData}>
          {t('excel')}
        </Button>
      )}
      {props.useCSVFormat && (
        <div>
          <Button
            variant='contained'
            type='button'
            className={props.classes?.button}
            disabled={!hasExcelData}
            onClick={generateAndDownloadCSV}
          >
            {t('excelcsv')}
          </Button>
          <CSVLink
            data={excelData || props.excelData || ''}
            headers={headerData}
            separator={';'}
            className='hidden'
            filename={
              props.fileName ?? props.excelOptions
                ? props.excelOptions?.fileName
                : ''
            }
            ref={csvLink}
          ></CSVLink>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ExcelComponent)
