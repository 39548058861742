import { Box, Grid, Theme, Typography } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container } from '@rfh/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
// import { KlachtCodeDetailFields } from 'src/common/components/KlachtCodeDetailFields'
import { FormMode } from 'src/common/enums'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IKlachtCode } from '../../models/IKlachtCode'
import {
  addKlachtCode,
  getKlachtCode,
  removeKlachtCode,
  updateKlachtCode,
} from '../../service/klachtCodeService'
import { ThemeConfig } from 'src/common/config'
import { KlachtCodeDetailFields } from '../klachtcode-list/components/KlachtCodeDetailFields'
import { observer } from 'mobx-react'
import { useAccountStore } from 'src/common/providers/AccountStore'
import withStyles, { WithStyles } from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'

interface RouteParams {
  id: string
}

type ClassKey = 'root'
type PropsType = WithStyles<ClassKey>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: theme.spacing(ThemeConfig.spacing.xsmall),
      marginTop: theme.spacing(ThemeConfig.spacing.small),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })

const KlachtCodeDetail: FC<PropsType> = observer(
  (props: PropsType): ReactElement => {
    const { isRoleApplicatieBeheer } = useAccountStore()
    const [versionState, setVersionState] = useState(0)
    const history = useHistory()
    const params = useParams<RouteParams>()
    const confirm = useConfirmation()
    const { id } = params
    const { authState } = useOktaAuth()
    const [detailMode, setMode] = useState(FormMode.edit)
    const formMethods = useForm<IKlachtCode>()
    const [actieFout, setFout] = useState('')

    const fetchData = async (KlachtCodeId: number): Promise<void> => {
      try {
        console.log('fetchdata :' + KlachtCodeId)
        const result = await getKlachtCode(
          KlachtCodeId,
          authState.accessToken?.accessToken
        )
        //setVersionState(result.version)
        formMethods.reset(result)
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      console.log('useEffect detail')
      //if (authState.isAuthenticated) {
      console.log('detail fetch ' + id)
      if (!isRoleApplicatieBeheer) {
        setMode(FormMode.view)
      } else if (id === '0') {
        setMode(FormMode.add)
      }
      fetchData(parseInt(id, 10))
      //}
    }, [authState])

    const removeItem = async (): Promise<void> => {
      const removeData = async (KlachtCodeId: number): Promise<void> => {
        try {
          await removeKlachtCode(
            KlachtCodeId,
            authState.accessToken?.accessToken
          ).then(
            value => {
              console.log(value) // Success!/Error. NB deze werkwijze leidt tot verschillen tussen browsers (FF vs chrome)
              if (value === null) {
                history.goBack()
              } else {
                setFout('Verwijderen is mislukt: ' + value)
              }
            },
            reason => {
              console.error(reason) // Error! Dit lijkt niets te doen?
            }
          )
        } catch (error) {
          console.log(error)
        }
      }

      if (id) {
        try {
          await confirm({
            variant: 'danger',
            catchOnCancel: true,
            title: 'Weet u zeker dat u dit wilt verwijderen?',
            description: 'Deze actie kan niet ongedaan gemaakt worden!',
            okbuttontext: 'Ok',
            cancelbuttontext: 'Annuleer',
          })
        } catch (error) {
          if (error) {
            console.log(error)
          }
          return
        }

        removeData(parseInt(id, 10))
      }
    }

    const updateData = async (klachtCode: IKlachtCode): Promise<void> => {
      try {
        await updateKlachtCode(
          klachtCode,
          authState.accessToken?.accessToken
        ).then(
          value => {
            console.log(value) // Success!
            if (value === null) {
              history.goBack()
            } else {
              setFout('Update is ongeldig : ' + value)
            }
          },
          reason => {
            console.error(reason) // Error!
          }
        )
      } catch (error) {
        setFout('update ongeldig')
        console.log(error)
      }
    }

    const addData = async (klachtCode: IKlachtCode) => {
      try {
        const result = await addKlachtCode(
          klachtCode,
          authState.accessToken?.accessToken
        ).then(
          value => {
            console.log(value) // Success!
            if (value === null) {
              history.goBack()
            } else {
              if (value.klachtCode1 !== undefined) {
                history.goBack()
              } else {
                setFout('Insert is ongeldig: ' + value)
              }
            }
          },
          reason => {
            console.error(reason) // Error!
          }
        )
      } catch (error) {
        setFout('insert ongeldig')
        console.log(error)
      }
    }

    const onSubmit = (klachtCode: IKlachtCode): void => {
      klachtCode.klachtCode1 = parseInt(klachtCode.klachtCode1.toString(), 10)
      klachtCode.prioriteit = parseInt(klachtCode.prioriteit.toString(), 10)
      console.log({ klachtCode })
      if (id === '0') {
        addData(klachtCode)
      } else {
        updateData(klachtCode)
      }
    }

    const { classes } = props

    return (
      <Container>
        <Typography variant='h2' gutterBottom>
          Klachtcode Details
        </Typography>
        {actieFout}
        <FormContext {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <KlachtCodeDetailFields mode={detailMode} />
            <Box mt={3}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                xs={3}
                spacing={1}
                className={classes.root}
              >
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    disabled={detailMode === FormMode.view}
                    onClick={(): any => formMethods.handleSubmit(onSubmit)}
                  >
                    Opslaan
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    disabled={detailMode === FormMode.view}
                    fullWidth
                    onClick={removeItem}
                  >
                    Verwijder
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormContext>
      </Container>
    )
  }
)

export default withStyles(styles)(KlachtCodeDetail)
