import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { useOktaAuth } from '@okta/okta-react'
import {
  Grid,
  Theme,
  TextField as MuiTextField,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Container } from '@rfh/ui'
import { observer } from 'mobx-react'
import { updateBatchComment } from 'src/modules/batch/service/BatchService'
import { IUpdateBatchCommentRequest } from 'src/modules/batch/model/IUpdateBatchCommentRequest'
type MyFnType = () => void

type ClassKey =
  | 'bar'
  | 'title'
  | 'container'
  | 'buttonContainer'
  | 'buttonContainerRight'
  | 'buttonleft'
  | 'button'
  | 'grid'
  | 'error'

const styles = (theme: Theme) =>
  createStyles({
    bar: {
      position: 'relative',
      backgroundColor: 'white',
    },
    title: {
      color: '#000000de',
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: {
      width: '100%',
      maxWidth: '1400px',
      margin: '0 auto',
      display: 'flex',
      //marginTop: '-64px',
      alignContent: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    buttonContainer: {
      marginTop: theme.spacing(3),
    },
    buttonContainerRight: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonleft: {
      marginTop: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
    },
    grid: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      alignItems: 'flex-start',
    },
    error: {
      color: 'red',
    },
  })

interface AddCommentToBatchDialogProps {
  handleAddCommentClose: MyFnType
  comment: string
  lotNumber: string
  dialogOpen: boolean
}

type PropsType = AddCommentToBatchDialogProps & WithStyles<ClassKey>

const AddCommentToBatchDialog: FC<PropsType> = observer((props: PropsType) => {
  const { authState } = useOktaAuth()
  const { classes } = props
  const { t } = useTranslation()

  const [comment, setComment] = useState(props.comment)
  const [errorMessage, setErrorMessage] = useState('')
  const [commentHasChanged, setCommentHasChanged] = useState(false)

  const handleCommentChange = event => {
    const newValue = event.target.value
    setComment(newValue)
    setCommentHasChanged(true)
  }

  const handleClose = () => {
    setErrorMessage('')
    setComment('')
    setCommentHasChanged(false)
    props.handleAddCommentClose()
  }

  const getTitleString = (): string => {
    const baseString = t('addBatchComment')
    return baseString + props.lotNumber
  }

  const onClickSave = async (): Promise<void> => {
    const toSaveComment =
      props.comment !== null && props.comment !== '' && !commentHasChanged
        ? props.comment
        : comment
    const request: IUpdateBatchCommentRequest = {
      lotnumber: props.lotNumber,
      batchcomment: toSaveComment,
    }
    console.log(`Starting update with request: ${JSON.stringify(request)}`)
    const resp = await updateBatchComment(
      request,
      authState.accessToken?.accessToken
    )
    if (resp == null) {
      setErrorMessage('')
      setComment('')
      setCommentHasChanged(false)
      props.handleAddCommentClose()
    } else {
      setErrorMessage(JSON.stringify(resp))
    }
  }

  console.log(
    `Opened with props.comment: ${JSON.stringify(
      props.comment
    )} and comment: ${comment}`
  )
  return (
    <div>
      <Dialog open={props.dialogOpen} onClose={handleClose}>
        <DialogTitle>{t('comment')}</DialogTitle>
        <DialogContent>
          <Container>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.grid}>
                <p className='title'>{getTitleString()}</p>
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                {errorMessage !== null && errorMessage !== '' && (
                  <p className={classes.error}>{errorMessage}</p>
                )}
                <MuiTextField
                  id='comment'
                  name='comment'
                  placeholder={t('comment')}
                  inputProps={{ maxLength: 512 }}
                  multiline
                  margin='dense'
                  fullWidth
                  value={
                    props.comment !== null &&
                    props.comment !== '' &&
                    !commentHasChanged
                      ? props.comment
                      : comment
                  }
                  onChange={handleCommentChange}
                />
              </Grid>
              <Grid item sm={12} className={classes.buttonContainerRight}>
                <RfhButton
                  variant='block--contained'
                  className={classes.button}
                  onClick={onClickSave}
                >
                  {t('save')}
                </RfhButton>
                <RfhButton
                  variant='block--outlined'
                  className={classes.button}
                  onClick={handleClose}
                >
                  {t('cancel')}
                </RfhButton>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  )
})

export default withStyles(styles)(AddCommentToBatchDialog)
