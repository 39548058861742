import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FC, ReactElement } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ThemeConfig } from 'src/common/config'
import { WarehouseEnum, warehouseLocations } from '../enums/Enum'

interface IProps {
  selected: WarehouseEnum
  onChange: any
  formWidth: number
}

type PropsType = IProps

const useStyles = makeStyles({
  root: {
    flexGrow: ThemeConfig.spacing.xsmall,
  },
})

export const WarehouseSelectionButton: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { selected, onChange } = props
  const options = warehouseLocations
  const classes = useStyles()

  return (
    <FormControl variant='filled' sx={{ width: `${props.formWidth}%` }}>
      <Select
        value={selected}
        onChange={onChange}
        label={'Locatie'}
        disableUnderline
        style={{
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        {options.map(o => (
          <MenuItem
            key={o}
            value={o}
            style={{
              textTransform: 'capitalize',
              width: '100%',
              marginLeft: '1',
            }}
          >
            {WarehouseEnum[o]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
